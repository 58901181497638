export const prettyPrintJson = (data) => {
    if ((Array.isArray(data) && !data.length) || "" === data) {
        return "";
    }
    return JSON.stringify(data, null, 2);
};

export const flattenPrintJson = (data) => {
    if ((Array.isArray(data) && !data.length) || "" === data) {
        return "";
    }
    return JSON.stringify(data, null, "");
};

export const convertBucketMapToObject = (map: Map<string, number>) => {
    const object = {};

    map.forEach((value, key) => {
        object[key] = value;
    });

    return object;
}

export const convertObjectToBucketMap = (obj: object) => {
    const map: Map<string, number> = new Map<string, number>();

    Object.keys(obj).forEach(key => {
        map.set(key, obj[key]);
    })

    return map;
}