export interface Region {
    readonly code: string,
    readonly marketplaces: Marketplace[],
}

export interface Marketplace {
    readonly serialNumber: number,
    readonly code: string,
    readonly regionCode: string,
    readonly oldFormatFileVersionNumber?: string,
    readonly oldFormatFileUrls?: string[],
    readonly isOldFileForSingleMarketplace?: boolean,
    readonly newFormatFileVersionNumber?: string,
    readonly newFormatFileUrls?: string[],
    readonly isNewFileForSingleMarketplace?: boolean,
}

const formatUrlForOldFormat = (suffix: string) => {
    return `https://drive.corp.amazon.com/view/dewsaket@/JEM/Pre-2022/${suffix}`;
}

const formatUrlForNewFormat = (suffix: string) => {
    return `https://drive.corp.amazon.com/view/dewsaket@/JEM/From-2022/${suffix}`;
}

export const templateFileForNewFormat: string = 'https://drive.corp.amazon.com/view/dewsaket@/JEM/From-2022/JEMTemplate.xlsx';

///////////////

export const MARKETPLACE_US : Marketplace = {
    serialNumber: 1,
    code: 'US',
    regionCode: 'NA',
    oldFormatFileVersionNumber: 'v21',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_NA_JE_Mappingsv21.xlsb'), formatUrlForOldFormat('NA Transactional Booking Digital Use Cases.xlsx')],
    isOldFileForSingleMarketplace: false,
    newFormatFileVersionNumber: 'v3',
    newFormatFileUrls: [formatUrlForNewFormat('v2JEM_US_v3_pharmacy.xlsx')],
    isNewFileForSingleMarketplace: true,
}

export const MARKETPLACE_CA : Marketplace = {
    serialNumber: 2,
    code: 'CA',
    regionCode: 'NA',
    oldFormatFileVersionNumber: 'v21',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_NA_JE_Mappingsv21.xlsb'), formatUrlForOldFormat('NA Transactional Booking Digital Use Cases.xlsx')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_MX : Marketplace = {
    serialNumber: 3,
    code: 'MX',
    regionCode: 'NA',
    oldFormatFileVersionNumber: 'v21',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_NA_JE_Mappingsv21.xlsb'), formatUrlForOldFormat('NA Transactional Booking Digital Use Cases.xlsx')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_BR : Marketplace = {
    serialNumber: 4,
    code: 'BR',
    regionCode: 'NA',
    oldFormatFileVersionNumber: 'v120820',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_BR_JE_Mappings_v120820.xlsb')],
    isOldFileForSingleMarketplace: true,
}


export const REGION_NA: Region = {
    code: 'NA',
    marketplaces: [MARKETPLACE_US, MARKETPLACE_CA, MARKETPLACE_MX, MARKETPLACE_BR],
}



/////////////////////

export const MARKETPLACE_UK : Marketplace = {
    serialNumber: 11,
    code: 'UK',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) v8.xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_DE : Marketplace = {
    serialNumber: 12,
    code: 'DE',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) v8.xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_FR : Marketplace = {
    serialNumber: 13,
    code: 'FR',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) v8.xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_IT : Marketplace = {
    serialNumber: 14,
    code: 'IT',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) v8.xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_ES : Marketplace = {
    serialNumber: 15,
    code: 'ES',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) v8.xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_NL : Marketplace = {
    serialNumber: 16,
    code: 'NL',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) (Chopin).xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_SE : Marketplace = {
    serialNumber: 17,
    code: 'SE',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) (Chopin).xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_PL : Marketplace = {
    serialNumber: 18,
    code: 'PL',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Project PanEU - JE Mappings (Long Term) (Chopin).xlsb')],
    isOldFileForSingleMarketplace: false,
}

export const MARKETPLACE_TR : Marketplace = {
    serialNumber: 19,
    code: 'TR',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'N/A',
    oldFormatFileUrls: [formatUrlForOldFormat('Project TB Turkey - JE Mappings (Long Term).xlsb')],
    isOldFileForSingleMarketplace: true,
}

export const MARKETPLACE_KSA : Marketplace = {
    serialNumber: 20,
    code: 'SA',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'N/A',
    oldFormatFileUrls: [formatUrlForOldFormat('JEM-KSA.xlsx')],
    isOldFileForSingleMarketplace: true,
}

export const MARKETPLACE_AE : Marketplace = {
    serialNumber: 21,
    code: 'AE',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'N/A',
    oldFormatFileUrls: [formatUrlForOldFormat('JEM-UAE.xlsx')],
    isOldFileForSingleMarketplace: true,
}

export const MARKETPLACE_EG : Marketplace = {
    serialNumber: 22,
    code: 'EG',
    regionCode: 'EU',
    oldFormatFileVersionNumber: 'N/A',
    oldFormatFileUrls: [formatUrlForOldFormat('JEM-EG.xlsx')],
    isOldFileForSingleMarketplace: true,
}

export const REGION_EU: Region = {
    code: 'EU',
    marketplaces: [
        MARKETPLACE_UK, MARKETPLACE_DE, MARKETPLACE_FR, MARKETPLACE_IT, MARKETPLACE_ES,
        MARKETPLACE_NL, MARKETPLACE_SE, MARKETPLACE_PL, MARKETPLACE_TR,
        MARKETPLACE_KSA, MARKETPLACE_AE, MARKETPLACE_EG
    ],
}


////////////////////

export const MARKETPLACE_JP : Marketplace = {
    serialNumber: 31,
    code: 'JP',
    regionCode: 'FE',
    oldFormatFileVersionNumber: 'v4',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_JP_JE_Mappingsv4.xlsb')],
    isOldFileForSingleMarketplace: true,
}

export const MARKETPLACE_SG : Marketplace = {
    serialNumber: 32,
    code: 'SG',
    regionCode: 'FE',
    oldFormatFileVersionNumber: 'v8',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_SG_JE_Mapping-APAC V8.xlsb')],
    isOldFileForSingleMarketplace: true,
}

export const MARKETPLACE_AU : Marketplace = {
    serialNumber: 33,
    code: 'AU',
    regionCode: 'FE',
    oldFormatFileVersionNumber: 'v9',
    oldFormatFileUrls: [formatUrlForOldFormat('Transactional_Booking_AU_JE_Mapping-APAC V9.xlsb')],
    isOldFileForSingleMarketplace: true,
}

export const REGION_FE: Region = {
    code: 'FE',
    marketplaces: [MARKETPLACE_JP, MARKETPLACE_SG, MARKETPLACE_AU],
}

/////////////////////

export const REGIONS = [
    REGION_NA,
    REGION_EU,
    REGION_FE,
]

const allMarketplaces = () => {
    const mktList: Marketplace[] = [];
    REGIONS.forEach(region => {
        region.marketplaces.forEach(mkt => mktList.push(mkt));
    })

    return mktList;
}

export const ALL_MARKETPLACES = allMarketplaces();

export const getRegionFromCode = (code: string): Region | null => {
    let returnVal : Region | null = null;
    REGIONS.forEach(region => {
        if (region.code === code) {
            returnVal = region;
            return; // break loop
        }
    })
    return returnVal;
}

export const getMarketplaceByCode = (code: string): Marketplace | null => {
    let returnVal : Marketplace | null = null;
    ALL_MARKETPLACES.forEach(mkt => {
        if (mkt.code === code) {
            returnVal = mkt;
            return; // break loop
        }
    })
    return returnVal;
}