import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from "@amzn/awsui-components-react/polaris/link";
import PermissionsModal from '../../PermissionsModal';
import RadioGroup, {RadioGroupProps} from "@amzn/awsui-components-react/polaris/radio-group";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from "@amzn/awsui-components-react/polaris/table";
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import {
    getRegionFromCode,
    Marketplace,
    REGION_EU,
    REGION_FE,
    REGION_NA,
    templateFileForNewFormat
} from './BusinessCountryCode';

const NA: RadioGroupProps.RadioButtonDefinition = {
    value: REGION_NA.code,
    label: "NA",
}

const EU: RadioGroupProps.RadioButtonDefinition = {
    value: REGION_EU.code,
    label: "EU",
}

const FE: RadioGroupProps.RadioButtonDefinition = {
    value: REGION_FE.code,
    label: "FE",
}

const getUrlOrDefault = (urlList: string[] | undefined, isSharedForMultipleMarketplaces: boolean | undefined) => {
    if (undefined != urlList) {
        return (
            <div>
                {null != urlList[0] && getUrl(urlList, 0)}
                <br/>
                {null != urlList[1] && getUrl(urlList, 1)}
                {isSharedForMultipleMarketplaces && <div> <hr/> (Shared across Marketplaces) </div>}
            </div>
        );
    }
    else {
        return ("Not Available");
    }
}

const getUrl = (url : string[], index: number) => {
    return (
        <Link
            href={url[index]}
            external={true}
            target={'_blank'}   // open in new tab
        >
            Download link [{++index}]
        </Link>
    );
}

const EMPTY_STRING = '';


export default function JEMFiles() {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());
    const [lastUpdatedDate] = React.useState(new Date().toISOString().split('T')[0]);
    const [regionCode, setRegionCode] = React.useState(EMPTY_STRING);
    const [isAlertVisible, setAlertVisible] = React.useState(EMPTY_STRING != regionCode);

    const getMarketplacesFromRegionCode = () => {
        if (null == regionCode) {
            return [];
        }
        else {
            const region = getRegionFromCode(regionCode);
            if (null == region) {
                return [];
            } else {
                return region.marketplaces;
            }
        }
    }

    return (
        <Box>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
                <SpaceBetween direction='vertical' size='xl'>
                    <Alert
                        onDismiss={() => setAlertVisible(false)}
                        visible={isAlertVisible}
                        dismissAriaLabel="Close alert"
                        header="Note"
                        dismissible={true}
                        type='info'
                    >
                        The JE Mapping files present here the latest known versions to ContraCoGS Tech Team. In case
                        you know of a newer available version for the mappings, let us know so that this repository
                        can be updated
                    </Alert>
                    <Container header={
                        <Header
                            variant="h3"
                            description="Choose from one of the regions below "
                        >
                            Region
                        </Header>
                    }>
                        <ColumnLayout columns={2}>
                            <RadioGroup
                                value={regionCode}
                                items={[NA, EU, FE]}
                                onChange={({ detail }) => {
                                    const region = getRegionFromCode(detail.value);
                                    setRegionCode(region?.code || EMPTY_STRING);
                                    setAlertVisible(null != region?.code);
                                }}
                            />
                            <Header variant='h3' description="Files were last indexed on this date">
                                Last Updated Date
                                <DatePicker
                                    value={lastUpdatedDate}
                                    nextMonthAriaLabel="Next month"
                                    disabled={true}
                                    previousMonthAriaLabel="Previous month"
                                    todayAriaLabel="Today"
                                />
                            </Header>
                        </ColumnLayout>
                    </Container>
                    {EMPTY_STRING != regionCode &&
                        <Container>
                            <Table
                                columnDefinitions={[
                                    {
                                        id: "country",
                                        header: "Country Code",
                                        cell: (item: Marketplace) => item.code,
                                    },
                                    {
                                        id: "old-file-location",
                                        header: "JEM File (Pre-2022 Format)",
                                        cell: (item: Marketplace) => getUrlOrDefault(item.oldFormatFileUrls, !item.isOldFileForSingleMarketplace),
                                    },
                                    {
                                        id: "old-file-version",
                                        header: "Pre-2022 File Version",
                                        cell: (item: Marketplace) => item.oldFormatFileVersionNumber,
                                    },
                                    {
                                        id: "new-file-location",
                                        header: "JEM File v2 (2022+) Format",
                                        cell: (item: Marketplace) => getUrlOrDefault(item.newFormatFileUrls, false),
                                    },
                                    {
                                        id: "new-file-version",
                                        header: "v2 (2022+) File Version",
                                        cell: (item: Marketplace) => item.newFormatFileVersionNumber,
                                    },
                                ]}
                                items={getMarketplacesFromRegionCode()}
                            />
                        </Container>
                    }
                    <Container header={<Header variant='h3'> JEM template v2 (2022+) version </Header>}>
                        <Link
                            href={templateFileForNewFormat}
                            external={true}
                            target={'_blank'}   // open in new tab
                        >
                            JEM template file download link
                        </Link>
                    </Container>
                </SpaceBetween>
            }
        </Box>
    );
}