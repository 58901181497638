import {Button, Popover, StatusIndicator} from '@amzn/awsui-components-react';
import React from 'react';


export default function CopyButton(props) {
    const [isCopySuccess, setCopySuccess] = React.useState(false);

    function statusIndicator() {
        if (isCopySuccess) {
            return <StatusIndicator type="success">Content copied</StatusIndicator>;
        }
        else {
            return <StatusIndicator type="error">Content failed to copy</StatusIndicator>
        }
    }

    return (
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={true}
            content={statusIndicator()}
        >
            <Button
                iconName="copy"
                onClick={() => {
                    try {
                        navigator.clipboard.writeText(props.textToCopy);
                        setCopySuccess(true);
                    }
                    catch (e) {
                        setCopySuccess(false);
                    }
                }}
            >
                Copy
            </Button>
        </Popover>
    );
}