import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';
import * as paths from '../../../util/paths';

export default function FlareBusinessActivitiesHome () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
                <SpaceBetween direction='vertical' size='xl'>
                    <Container
                        header={<Header
                            variant='h2'
                            description='Enumeration of all business activities supported by ContraCoGS, along with details
                            about which accounts do they impact and how they are triggered and expected state of ContraCoGS
                            systems at the time of trigger'>
                            List of all business activities
                        </Header>}
                    >
                        <Link href={paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_LIST)}>
                            See list of all business activities
                        </Link>
                    </Container>
                    <Container header={<Header
                        variant='h2'
                        description={<div>
                            How are different business activities related to each other. A relationship may be
                            because of similar trigger events or because of similar expected state of ContraCoGS
                            systems at the time of business event that triggers the business activity. <br/>
                            This also explains the chronological order of business activities for minimal number
                            of events that are needed before a business activity can happen.
                        </div>}
                    >
                        Relationship between business activities
                    </Header>}>
                        <Link href={paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_RELATION)}>
                            See which business activities are similar to each other
                        </Link>
                    </Container>
                    <Container>
                        <div>
                            Additional documentation available&nbsp;
                            <Link href={paths.CCT_USL_BI_MODEL} target='_blank' external> here </Link>
                        </div>
                    </Container>
                </SpaceBetween>
            }
        </SpaceBetween>
    );
}