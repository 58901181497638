import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import React, {ReactNode} from 'react';
import * as BusinessActivity from '../../../flare_usl_bi/business_activities/BusinessActivity';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import {useCollection} from '@amzn/awsui-collection-hooks';
import {DDB_PAGE_SIZE, getFilterCounterText, TableEmptyState, TableNoMatchState} from '../../../../util/TableUtils';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import {JournalEntryMappingUseCase, TableColumnDefinitions, getAllSavedUseCases} from '../JEMHelper';
import {Marketplace} from '../../repo/BusinessCountryCode';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import * as paths from '../../../../util/paths';
import {useHistory} from 'react-router-dom';
import {OperationType} from '../creator/JEMCreatorHelper';

const RenderAllUseCases = (bizCountry: Marketplace, bizActivity: BusinessActivity.BusinessActivity, hasAdminAccess: boolean, accrualBasis: BusinessActivity.AccrualBasis, dropdownItems: BusinessActivity.BusinessActivity[]) => {
    const useCases: JournalEntryMappingUseCase[] = getAllSavedUseCases(bizCountry, bizActivity);
    const renders = [] as ReactNode[];
    for (let i = 0; i < useCases.length; i++) {
        renders.push(RenderSingleUseCase(useCases[i], i, hasAdminAccess, bizCountry, accrualBasis, dropdownItems, bizActivity));
    }
    return renders;
}

function RenderSingleUseCase (useCase, index: number, hasAdminAccess: boolean, bizCountry: Marketplace, accrualBasis: BusinessActivity.AccrualBasis, dropdownItems: BusinessActivity.BusinessActivity[], bizActivity: BusinessActivity.BusinessActivity) {
    const history = useHistory();
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        useCase.lineEntries,
        {
            filtering: {
                empty: <TableEmptyState dataType="JE Mapping" />,
                noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
            },
            pagination: { pageSize: DDB_PAGE_SIZE },
            sorting: { defaultState: { sortingColumn: TableColumnDefinitions[0] } },
            selection: {},
        }
    );
    return (
        <Container key={index}>
            <ColumnLayout columns={2} borders='none'>
                <Header> {`${index+1}. ${useCase.useCaseName}`} </Header>
                <Button variant='primary'
                        iconName='edit'
                        disabled={!hasAdminAccess}
                        onClick={() => {
                            // save the params to history and navigate to create/edit page
                            const pathname = paths.JEM_CREATOR;
                            history.push({
                                pathname,
                                state: {operationType:OperationType.EDIT_EXISTING, businessCountry: bizCountry, accrualBasis: accrualBasis, dropdownItems: dropdownItems, businessActivity: bizActivity, useCaseName: useCase.useCaseName}
                            });
                        }}
                >
                    Edit
                </Button>
            </ColumnLayout>
            <ExpandableSection header="Line Entries">
                <Table
                    {...collectionProps}
                    columnDefinitions={TableColumnDefinitions}
                    items={items}
                    filter={<TextFilter
                        {...filterProps}
                        filteringPlaceholder="Find entries"
                        countText={getFilterCounterText(filteredItemsCount)}
                    />}
                    pagination={<Pagination {...paginationProps} />}
                    footer={Math.ceil(useCase.lineEntries.length/DDB_PAGE_SIZE) &&
                    <Box textAlign="center">
                        Page {paginationProps.currentPageIndex} of {Math.ceil(useCase.lineEntries.length/DDB_PAGE_SIZE)}
                    </Box>
                    }
                    stickyHeader
                />
            </ExpandableSection>
        </Container>
    );
}


export default function JEMViewerHelper (props) {
    return (
        <Container header={
            <Header variant='h3' description={`business_activity: ${props.businessActivity.name}`}>
                {`business_country_code: ${props.businessCountry.code}`}
            </Header>
        }>
            <Container>
                <SpaceBetween direction='vertical' size='xxs'>
                    {RenderAllUseCases(props.businessCountry, props.businessActivity, props.hasAdminAccess, props.accrualBasis, props.dropdownItems)}
                </SpaceBetween>

            </Container>
        </Container>
    );
}