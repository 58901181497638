export enum TransactionType {
    CSI = "CSI",
    CRI = "CRI",
    DSI = "DSI",
    DRI = "DRI",
    PPDSI = "PPDSI",
    CVDSI = "CVDSI",
    DDI = "DDI",
    DDRI = "DDRI",
    VPCC = "VPCC",

    NONE = "NONE",
}