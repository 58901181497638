import {getUserName} from './MidwayJwtToken';

// FUTURE - this should be an ldap call to get the latest members, hard-coding it for now
// list: https://permissions.amazon.com/a/team/ccogs-transactionalbooking-dev
const knownContraCogsTeam: string[] = [
    'harshhk',
    'himanisi',
    'kyunt',
    'sauravp',
    'nathano',
    'dewsaket',
    'badechha',
    'norawai',
    'anikeb',
    'paisp',
    'arurnm',
    'nhd',
    'qinggeli',
    'jialihan',

    // Loaners
    'jorgio',
    'madhuve',

    'tulin',
    'khawandr',
    'aakujat',
    'ppadmana',

    'danglesm',
    'navira',
];

export const isFromTechTeam = (): boolean => {
    const alias: string = getUserName();
    return new Set(knownContraCogsTeam).has(alias);
}


// FUTURE - this should be an ldap call to get the latest members, hard-coding it for now
// list: https://permissions.amazon.com/a/team/source2usl-contracogs-booking
const knownAccountingTeam: string[] = [
    // EU Accounting
    'prndrew',
    'aislaher',
    'garyhall',
    'julhoppe',

    // NA Accounting
    'cmmrcus',
    'mellymel',
    'aarikenb',

    // FE/APAC Accounting
    'wanyw',
    'zhaoyuj',

    // FBI
    'chongg',
    'inkavi',
    'amelstew',
    'gmantova',

    // FinCOps
    'ekpyatt',
    'jonmhli',
    'parimisp',
    'reescott',

    // Test only
    'dewsaket',

]

export const isFromAccountingTeam = () : boolean => {
    const alias: string = getUserName();
    return new Set(knownAccountingTeam).has(alias);
}


// FUTURE - Away team integrations