import React, {ReactNode} from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Select from '@amzn/awsui-components-react/polaris/select';
import {SelectProps} from '@amzn/awsui-components-react/polaris/select/interfaces';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import {
    FlareAttributeCategoriesUsableInJemLookup,
    getFlareAttributesByCategory
} from '../../../flare_usl_bi/translator/FlareAttribute';
import {MultiselectProps} from '@amzn/awsui-components-react/polaris/multiselect/interfaces';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import {flattenPrintJson} from '../../../../util/Utils';
import Header from '@amzn/awsui-components-react/polaris/header';
import {ATTRIBUTES_IN_JEM, getSavedUseCaseByName, JournalEntryMappingUseCase} from '../JEMHelper';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';


export enum OperationType {
    NULL = -1,
    CREATE_NEW = 0,
    CREATE_BY_COPY = 1,
    EDIT_EXISTING = 2,
}

const STATIC_VAL: SelectProps.Option = {
    value: "Constant Val",
    description: "Constant Value",
}

const DYNAMIC_LOOKUP: SelectProps.Option = {
    value: "Attr Lookup",
    description: "Mapping based on attribute(s)",
}

export const JEM_INPUT_OPTIONS = [
    STATIC_VAL,
    DYNAMIC_LOOKUP,
];

const findOptionByValue = (id) => {
    for (let i = 0; i < JEM_INPUT_OPTIONS.length; i++) {
        if (JEM_INPUT_OPTIONS[i].value == id) {
            return JEM_INPUT_OPTIONS[i];
        }
    }
}

const sorted = (options: MultiselectProps.Option[]) => {
    // @ts-ignore
    return options.sort((a, b) => {a.value.localeCompare(b.value)})
}

const allFlareAttributes = () => {
    const attributesUsableInLookup: SelectProps.Option[] = []

    FlareAttributeCategoriesUsableInJemLookup.forEach(cat => {
        getFlareAttributesByCategory(cat.name).forEach(attr => {
            attributesUsableInLookup.push(
                {
                    label: attr.flareName,
                    description: attr.cctMeaning,
                    value: attr.id,
                } as SelectProps.Option
            )
        });
    })

    return attributesUsableInLookup;
}

const getOnlyAttributeNames = (arr: SelectProps.Option[]) => {
    const attributes: string[] = [];

    if (null != arr) {
        arr.forEach(opt => {
            if (null != opt.label) {
                attributes.push(opt.label)
            }
        });
    }

    return attributes.sort();
}


const defaultNumberOfLines = 2;

export default function JEMCreatorHelper (props) {

    const getNumberOfLineEntries = () => {
        const jemUseCase: JournalEntryMappingUseCase | null = getSavedUseCaseByName(props.businessCountry, props.businessActivity, props.useCaseName);
        // @ts-ignore
        return (null != jemUseCase) ? jemUseCase.lineEntries.length : defaultNumberOfLines;
    }

    const [numberOfLineEntries, setNumberOfLineEntries] = React.useState(getNumberOfLineEntries());
    const [useCaseName, setUseCaseName] = React.useState(props.useCaseName);

    const RenderAttributeRows = () => {
        const jemUseCase: JournalEntryMappingUseCase | null = getSavedUseCaseByName(props.businessCountry, props.businessActivity, props.useCaseName);
        const renders = [] as ReactNode[];
        for (let i = 0; i < ATTRIBUTES_IN_JEM.length; i++) {
            renders.push(<JEMSingleRow key={i+1} attributeName={ATTRIBUTES_IN_JEM[i]} numberOfLineEntries={numberOfLineEntries} useCase={jemUseCase}/>);
        }
        return renders;
    }

    const HeaderRow = () => {
        const renders = [] as ReactNode[];
        renders.push(<Input key={0} controlId="attribute" value={"Attribute"} onChange={() => {}} disabled />);
        for (let i = 0; i < numberOfLineEntries; i++) {
            renders.push(<Input key={2*i+1} controlId={`derivation_type_${i}`}  value={`Journal Entry ${i+1}`} onChange={() => {}} disabled />);
            renders.push(<Input key={2*i+2} controlId={`derived_value_${i}`} value={""} onChange={() => {}} disabled />);
        }
        return renders;
    }

    return(
        <Container header={
            <Header variant='h3' description={`business_activity: ${props.businessActivity.name}`}>
                {`business_country_code: ${props.businessCountry.code}`}
            </Header>
        }>
            <SpaceBetween size='xxs' direction='vertical'>
                <FormField label='Use Case Name' description='Explanation for the use case, that helps users understand why does this use case exist '>
                    <Input key={"use_case_name"}
                           controlId={"use_case_name"}
                           value={useCaseName}
                           onChange={(event) => setUseCaseName(event.detail.value)}
                    />
                </FormField>
                <ColumnLayout columns={numberOfLineEntries * 2 + 1} borders='vertical' disableGutters>
                    {HeaderRow()}
                    {RenderAttributeRows()}
                </ColumnLayout>
                <Container>
                    <ColumnLayout columns={2} borders='none'>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" iconName="add-plus" onClick={() => {
                                setNumberOfLineEntries(numberOfLineEntries+2);
                            }}>
                                Add Additional 2 JEs
                            </Button>
                            <Button variant="normal" iconName="undo" disabled={numberOfLineEntries == 2} onClick={() => {
                                if (numberOfLineEntries >=4) {
                                    setNumberOfLineEntries(numberOfLineEntries - 2);
                                }
                            }}>
                                Delete Last 2 JEs
                            </Button>
                        </SpaceBetween>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => {
                                // setDynamicLookupValue([]);
                                // setModalVisible(false);
                            }}>
                                Clear all
                            </Button>
                            <Button variant="primary" onClick={() => {
                                // setModalVisible(false)
                            }}>
                                Save
                            </Button>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </Container>
    );
}

function JEMSingleRow(props) {

    // FIXME: use the values in props.jemUseCase to populate the table

    const RenderForAllLines = (numLines: number) => {
        const renders = [] as ReactNode[];
        for (let i = 0; i < numLines; i++) {
            renders.push(<JEMSingleAttribute key={i} lineNum={i}/>);
        }
        return renders;
    }

    return (
        <ColumnLayout columns={+`${props.numberOfLineEntries}`* 2 + 1} borders='all'>
            <Input controlId="attr_name" value={props.attributeName} readOnly/>
            {RenderForAllLines(+`${props.numberOfLineEntries}`)}
        </ColumnLayout>
    );
}

function JEMSingleAttribute(props) {
    const [lookupType, setLookupType] = React.useState(null);
    const [constLookupValue, setConstLookupValue] = React.useState("");
    const [dynamicLookupValue, setDynamicLookupValue] = React.useState([] as MultiselectProps.Option[]);
    const [unsavedLookupValues, setUnsavedLookupValues] = React.useState([] as MultiselectProps.Option[]);
    const [modalVisible, setModalVisible] = React.useState(false);

    const sanitizeString = (val: string) => {
        return val.replace(/[^\w\s\']|_/g, "")
            .replace(/\s+/g, "")
    }

    const formatLookupString = () => {
        if (dynamicLookupValue.length > 0) {
            return `LKP${flattenPrintJson(getOnlyAttributeNames(dynamicLookupValue))}`;
        }
        else {
            return "";
        }
    }

    const conditionalValue = () => {
        if (null == lookupType) {
            return (
                <Input controlId="i_value_0"
                       placeholder={"Attribute value"}
                       value={""}
                       onChange={() => {}}
                />
            );
        }
        if (STATIC_VAL == lookupType) {
            return (
                <Input controlId="i_value_1"
                       placeholder={"Attribute value"}
                       value={constLookupValue}
                       onChange={event => {
                           const val = sanitizeString(event.detail.value);
                           setConstLookupValue(val);
                       }}
                />
            );
        }
        if (DYNAMIC_LOOKUP == lookupType) {
            if (modalVisible) {
                return (
                    <Modal
                        onDismiss={() => setModalVisible(false)}
                        modalRoot={document.getElementById("jem_form") ?? document.body}
                        visible={modalVisible}
                        closeAriaLabel="Close modal"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={() => {
                                        setModalVisible(false);
                                        setUnsavedLookupValues(dynamicLookupValue);
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={() => {
                                        setModalVisible(false);
                                        setDynamicLookupValue(unsavedLookupValues);
                                    }}>
                                        Ok
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Choose attributes in the lookup"
                    >
                        <Container>
                            <Multiselect controlId="i_value_2"
                                         selectedOptions={unsavedLookupValues}
                                         placeholder={"Attributes to use for lookup"}
                                         deselectAriaLabel={e => `Remove ${e.label}`}
                                         options={allFlareAttributes()}
                                         onChange={({detail}) => {
                                             const selectedOptions = detail.selectedOptions;
                                             // @ts-ignore
                                             setUnsavedLookupValues(sorted(selectedOptions));
                                         }}
                                         i18nStrings={{
                                             tokenLimitShowFewer: "Show fewer options",
                                             tokenLimitShowMore: "Show more options"
                                         }}
                            />
                        </Container>
                    </Modal>
                );
            }
            else {
                return (
                    <Input controlId="i_value_1"
                           placeholder={"Attribute value"}
                           value={formatLookupString()}
                           readOnly
                    />
                );
            }
        }
    }

    return (
        <ColumnLayout id="jem_form" columns={5} disableGutters>
            <Select controlId={`attr_val_${props.lineNum}`}
                    placeholder={"Derivation Type"}
                    options={JEM_INPUT_OPTIONS}
                    selectedOption={lookupType}
                    onChange={event => {
                        const newType = findOptionByValue(event.detail.selectedOption.value);
                        // @ts-ignore
                        setLookupType(newType);
                        if (DYNAMIC_LOOKUP == newType) {
                            setModalVisible(true);
                        }
                    }}
            />
            {conditionalValue()}
        </ColumnLayout>
    );
}

export function JEMCreateNewOrEditExisting(props) {
    return (
        <Container>
            <SpaceBetween size='s' direction='horizontal'>
                <Button variant='normal'
                        iconName='add-plus'
                        onClick={() => {
                            if (OperationType.NULL == props.operationType) {
                                props.setOperationType(OperationType.CREATE_NEW);
                            }
                            if (OperationType.CREATE_NEW == props.operationType) {
                                props.setOperationType(OperationType.NULL);
                            }
                        }}
                        disabled={OperationType.EDIT_EXISTING == props.operationType || OperationType.CREATE_BY_COPY == props.operationType}
                >
                    Create New
                </Button>
                <Button variant='normal'
                        iconName='copy'
                        onClick={() => {
                            if (OperationType.NULL == props.operationType) {
                                props.setOperationType(OperationType.CREATE_BY_COPY);
                            }
                            if (OperationType.CREATE_BY_COPY == props.operationType) {
                                props.setOperationType(OperationType.NULL);
                            }
                        }}
                        disabled={OperationType.CREATE_NEW == props.operationType || OperationType.EDIT_EXISTING == props.operationType}
                >
                    Create By Copying
                </Button>
                <Button variant='normal'
                        iconName='edit'
                        onClick={() => {
                            if (OperationType.NULL == props.operationType) {
                                props.setOperationType(OperationType.EDIT_EXISTING);
                            }
                            if (OperationType.EDIT_EXISTING == props.operationType) {
                                props.setOperationType(OperationType.NULL);
                            }
                        }}
                        disabled={OperationType.CREATE_NEW == props.operationType || OperationType.CREATE_BY_COPY == props.operationType}
                >
                    Edit Existing
                </Button>
            </SpaceBetween>
        </Container>
    );
}


// TODO -
// 2. Map structure to store the current in-memory changes
// 4. Add copy across marketplaces or from business activities in same marketplace
// 5. Add non UI way to copy or insert mapping (serialized data entry)
// 6. Lookups table creation/view/update
// 7. Two step process for all updates. Updates go to a new table, pending approval like CRs
// 9. Derivation of fields like currency/debit credit/financial component