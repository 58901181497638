import useLocalStorage from "./use-local-storage";
import '../styles/styles.scss'

/**
 * Parent component to enable dark-mode.
 */
export const DarkMode = (props:any) => {

    const [isDarkMode] = useLocalStorage('is_dark_mode', true)  // dark-mode is default

    return (
        <div className={`${isDarkMode ? "awsui-polaris-dark-mode" : ""}`}>
            {props.children}
        </div>
    );
}