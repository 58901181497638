import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';

export default function UslBIQueries () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
            <SpaceBetween direction='vertical' size='xl'>
                <Container header={
                    <Header>
                        Collection of USL BI Queries
                    </Header>
                }>
                    IMPROVED VERSION COMING SOON!<br/><br/>

                    Refer to this documentation for the time-being - &nbsp;
                    <Link
                        href="https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/UslBiReporting/#HUsefulQueryPatterns"
                        target="_blank"
                        external
                    >
                        Old Doc
                    </Link>
                </Container>
            </SpaceBetween>
            }
        </SpaceBetween>
    );
}