export interface CalcTerms {
    calcType:CalcType,
    calcRate: number,
}

export enum CalcType {
    PERCENTAGE_CHARGE = "PERCENTAGE_CHARGE",
    PER_UNIT_CHARGE = "PER_UNIT_CHARGE",
    LUMP_SUM = "LUMP_SUM",
}

export const PERCENT_CHARGE: (rateVal: number) => CalcTerms = (rateVal: number) => {
    return {
        calcType: CalcType.PERCENTAGE_CHARGE,
        calcRate: rateVal
    };
}

export const PER_UNIT_CHARGE: (rateVal: number) => CalcTerms = (rateVal: number) => {
    return {
        calcType: CalcType.PER_UNIT_CHARGE,
        calcRate: rateVal
    };
}

export const LUMPSUM_CHARGE: (rateVal: number) => CalcTerms = (rateVal: number) => {
    return {
        calcType: CalcType.LUMP_SUM,
        calcRate: rateVal
    };
}