import {AccrualBasis, SALES_BASED} from './AccrualBasis';
import {ACCRUAL, FundingType} from './FundingType';
import {CalcTerms, PERCENT_CHARGE} from './CalcTerms';

export interface AgreementAttributes {
    agreementId: number | null;
    accrualBasis: AccrualBasis | null;
    fundingType: FundingType | null;
    calcTerm: CalcTerms | null;
    owningMarketplaceId: number | null;
    owningProductGroupId: number | null;
    currency: string | null;
    isDisposed: boolean;
}

export const emptyAgreement: AgreementAttributes = {
    agreementId: null,
    accrualBasis: null,
    fundingType: null,
    calcTerm: null,
    owningMarketplaceId: null,
    owningProductGroupId: null,
    currency: null,
    isDisposed: false,
}


export const createAgreementUsingDefaultWithoutAgreementId: (inputAttrs: AgreementAttributes) => AgreementAttributes = (inputAttrs: AgreementAttributes) => {
    return {
        // juggling check tests both null and undefined values, see https://stackoverflow.com/a/28984306
        agreementId: inputAttrs.agreementId,    // this is not overridden explicitly
        accrualBasis: inputAttrs.accrualBasis == null ? defaultAccrualBasis : inputAttrs.accrualBasis,
        fundingType: inputAttrs.fundingType == null ? defaultFundingType : inputAttrs.fundingType,
        calcTerm: inputAttrs.calcTerm == null ? defaultAccrualTerm : inputAttrs.calcTerm,
        owningMarketplaceId: inputAttrs.owningMarketplaceId == null ? defaultOwningMarketplaceId : inputAttrs.owningMarketplaceId,
        owningProductGroupId: inputAttrs.owningProductGroupId == null ? defaultOwningProductGroupId : inputAttrs.owningProductGroupId,
        currency: inputAttrs.currency == null ? defaultCurrency : inputAttrs.currency,
        isDisposed: inputAttrs.isDisposed == null ? defaultIsDisposed : inputAttrs.isDisposed,
    };
}


const defaultAgreementId: number = 1;
const defaultAccrualBasis: AccrualBasis = SALES_BASED;
const defaultFundingType: FundingType = ACCRUAL;
const defaultAccrualTerm: CalcTerms = PERCENT_CHARGE(1.0);
const defaultOwningMarketplaceId: number = 1;
const defaultOwningProductGroupId: number = 14;
const defaultCurrency: string = 'USD';
const defaultIsDisposed: boolean = false;

export const defaultAgreementTerms: AgreementAttributes = {
    agreementId: defaultAgreementId,
    accrualBasis: defaultAccrualBasis,
    fundingType: defaultFundingType,
    calcTerm: defaultAccrualTerm,
    owningMarketplaceId: defaultOwningMarketplaceId,
    owningProductGroupId: defaultOwningProductGroupId,
    currency: defaultCurrency,
    isDisposed: defaultIsDisposed,
}