import {Money} from '../Money';
import {TransactionIdentifier} from './TransactionIdentifier';

export abstract class Transaction {
    protected _transactionIdentifier?: TransactionIdentifier;
    protected _quantity?: number;
    protected _pcogs?: Money;
    protected _productGroupId?: number;
    protected _asinMarketplaceId?: number;
    protected _isValid: boolean;

    protected constructor(transactionIdentifier: TransactionIdentifier | undefined,
                          quantity: number | undefined,
                          pcogs: Money | undefined,
                          productGroupId: number | undefined,
                          asinMarketplaceId: number | undefined,
                          isValid: boolean) {
        this._transactionIdentifier = transactionIdentifier;
        this._quantity = quantity;
        this._pcogs = pcogs;
        this._productGroupId = productGroupId;
        this._asinMarketplaceId = asinMarketplaceId;
        this._isValid = isValid;
    }

    public abstract getTransactionIdentifier(): TransactionIdentifier | undefined;

    public setTransactionIdentifier(transactionIdentifier: TransactionIdentifier) : void {
        this._transactionIdentifier = transactionIdentifier;
    }

    public abstract getQuantity(): number | undefined;

    public setQuantity(quantity: number) : void {
        this._quantity = quantity;
    }

    public abstract getPCogs(): Money | undefined;

    public setPcogs(pcogs: Money) : void {
        this._pcogs = pcogs;
    }

    public abstract getProductGroupId(): number | undefined;

    public setProductGroupId(productGroupId: number) : void {
        this._productGroupId = productGroupId;
    }

    public abstract getAsinMarketplaceId(): number | undefined;

    public setAsinMarketplaceId(asinMarketplaceId : number) : void {
        this._asinMarketplaceId = asinMarketplaceId;
    }

    public abstract isValid(): boolean;

    public setValid(isValid: boolean): void {
        this._isValid = isValid;
    }

    public abstract copyAttributesFrom(inputTxn: Transaction): void;

    public static empty(): Transaction {
        return new class extends Transaction {

            constructor() {
                super(undefined, undefined, undefined, undefined, undefined, true);
            }

            getAsinMarketplaceId(): number | undefined {
                return undefined;
            }

            getPCogs(): Money | undefined {
                return undefined;
            }

            getProductGroupId(): number | undefined {
                return undefined;
            }

            getQuantity(): number | undefined {
                return undefined;
            }

            getTransactionIdentifier(): TransactionIdentifier | undefined {
                return undefined;
            }

            isValid(): boolean {
                return false;
            }

            copyAttributesFrom(inputTxn: Transaction): void {}
        }
    }

}


