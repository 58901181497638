import React from 'react';
import {isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';


export default function FieFetcher() {
    const [hasAccess] = React.useState(isFromTechTeam());
    return (
        <Box>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess && <SpaceBetween direction='vertical' size='xl'>
                COMING SOON!
            </SpaceBetween>}
        </Box>
    );
}
