import {Transaction} from './Transaction';
import * as TransactionIdentifier from './TransactionIdentifier';
import {Money} from '../Money';
import {defaultPcogs} from './TransactionAttributes';

export class CustomerReturnItem extends Transaction {

    private _warehouseIdOfReturn: string | undefined;

    // TODO - merchant id

    constructor(transactionIdentifier: TransactionIdentifier.TransactionIdentifier,
                quantity: number,
                pcogs: Money,
                productGroupId: number,
                asinMarketplaceId: number,
                isValid: boolean,
                _warehouseIdOfReturn: string) {
        super(transactionIdentifier, quantity, pcogs, productGroupId, asinMarketplaceId, isValid);
        this._warehouseIdOfReturn = _warehouseIdOfReturn;
    }


    public getTransactionIdentifier() {
        return this._transactionIdentifier;
    };

    public getQuantity() {
        return this._quantity;
    };

    public getPCogs() {
        return this._pcogs;
    };

    public getProductGroupId() {
        return this._productGroupId;
    };

    public getAsinMarketplaceId() {
        return this._asinMarketplaceId;
    };

    public isValid() {
        return this._isValid;
    };

    public getWarehouseIdOfReturn() {
        return this._warehouseIdOfReturn;
    }

    public setWarehouseIdOfReturn(warehouseIdOfReturn : string) : void {
        this._warehouseIdOfReturn = warehouseIdOfReturn;
    }

    public copyAttributesFrom(inputTxn: CustomerReturnItem): void {
        this.setTransactionIdentifier(inputTxn.getTransactionIdentifier() as TransactionIdentifier.TransactionIdentifier);
        this.setQuantity(inputTxn.getQuantity() as number);
        this.setPcogs(inputTxn.getPCogs() as Money);
        this.setProductGroupId(inputTxn.getProductGroupId() as number);
        this.setAsinMarketplaceId(inputTxn.getAsinMarketplaceId() as number);
        this.setValid(inputTxn.isValid());
        this.setWarehouseIdOfReturn(inputTxn.getWarehouseIdOfReturn() as string);
    }


    public static dummy(): CustomerReturnItem {
        return new CustomerReturnItem(TransactionIdentifier.CUSTOMER_RETURN_ITEM("1234"), 1, defaultPcogs, 14, 1, true, "SEA1");
    }
}
