import React, {ReactNode} from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';
import {ALL_BIZ_ACTIVITIES, BusinessActivity, getBusinessActivityByName} from './BusinessActivity';
import {SelectProps} from '@amzn/awsui-components-react/polaris/select/interfaces';
import {MultiselectProps} from '@amzn/awsui-components-react/polaris/multiselect/interfaces';
import {FLARE_BIZ_ACTIVITIES_HOME, getRelativePath} from '../../../util/paths';
import {BusinessActivitiesFooter} from './FlareBusinessActivitiesEnumeration';

const getOptions = () => {
    const options: SelectProps.Option[] = [];
    for (let i = 0; i < ALL_BIZ_ACTIVITIES.length ; i++) {
        const activity: BusinessActivity = ALL_BIZ_ACTIVITIES[i];
        const val:SelectProps.Option = {
            label: activity.name,
            value: i.toString(),
            description: activity.triggerEvent,
            tags: [activity.agreementAccrualBasis],
        };
        options.push(val);
    }
    return options;
}

const RenderItems = (options: MultiselectProps.Option[]) => {
    const renders = [] as ReactNode[];
    for (let i = 0; i < options.length; i++) {
        renders.push(RenderSingleItem(options[i], i));
    }
    return renders;
}

const itemsForTable = (option: MultiselectProps.Option) => {
    const returnVal: BusinessActivity[] = [];
    if (undefined != option.label) {
        const optionalBusinessActivity: BusinessActivity | undefined = getBusinessActivityByName(option.label);
        if (undefined != optionalBusinessActivity) {
            returnVal.push(optionalBusinessActivity);
            const relatedActivities = optionalBusinessActivity.similarTo;
            relatedActivities.forEach(act => {
                const bizActivity = getBusinessActivityByName(act);
                if (undefined != bizActivity) {
                    returnVal.push(bizActivity);
                }
            });
        }
    }
    return returnVal;
}

const RenderSingleItem = (option: MultiselectProps.Option, index: number) => {
    return (
        <Box key={index}>
            <Header> {option.label} </Header>
            <ExpandableSection header="Related Business Activities">
                <Table
                    header={
                        <Header counter={"(" + --itemsForTable(option).length + ")"}>
                            Similar Business Activities
                        </Header>
                    }
                    columnDefinitions={[
                        {
                            id: "serial_number",
                            header: "Use Case #",
                            cell: (item: BusinessActivity) => item.serialNumber,
                            sortingField: "serial_number",
                            sortingComparator: (a: BusinessActivity, b:BusinessActivity) => a.serialNumber - b.serialNumber,
                        },
                        {
                            id: "business_activity",
                            header: "Business Activity",
                            cell: (item: BusinessActivity) => item.name,
                        },
                        {
                            id: "accrual_basis",
                            header: "Agreement Accrual Basis",
                            cell: (item: BusinessActivity) => item.agreementAccrualBasis,
                        },
                        {
                            id: "description",
                            header: "Description",
                            cell: (item: BusinessActivity) => item.description,
                        },
                        {
                            id: "debit",
                            header: "Debit Account",
                            cell: (item: BusinessActivity) => item.debitAccount,
                        },
                        {
                            id: "credit",
                            header: "Credit Account",
                            cell: (item: BusinessActivity) => item.creditAccount,
                        },
                    ]}
                    isItemDisabled={(item: BusinessActivity) => item.serialNumber.toString() == option.value}
                    items={itemsForTable(option)}
                    stickyHeader
                    selectionType='multi'
                    selectedItems={itemsForTable(option).slice(1,)}
                />
            </ExpandableSection>
        </Box>
    );
}

export default function FlareBusinessActivitiesRelation () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());
    const [selectedOptions, setSelectedOptions] = React.useState([] as MultiselectProps.Option[]);

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
                <SpaceBetween direction='vertical' size='xl'>
                    <Container header={
                        <ColumnLayout  columns={2} variant='text-grid' borders='vertical'>
                            <Header variant='h2'
                                    description="Select one or more business activities to see others that are related to the choice(s)">
                                Business Activities
                            </Header>
                            <Link href={getRelativePath(FLARE_BIZ_ACTIVITIES_HOME)}>
                                Back to Business Activities Home
                            </Link>
                        </ColumnLayout>}
                    >
                        <Multiselect
                            selectedOptions={selectedOptions}
                            onChange={({detail}) =>
                                // @ts-ignore
                                setSelectedOptions(detail.selectedOptions)
                            }
                            deselectAriaLabel={e => `Remove ${e.label}`}
                            options={getOptions()}
                            tokenLimit={3}
                            placeholder="Choose business activities"
                            selectedAriaLabel="Selected"
                            i18nStrings={{
                                tokenLimitShowFewer: "Show fewer options",
                                tokenLimitShowMore: "Show more options"
                            }}
                        />
                    </Container>
                    {selectedOptions.length > 0 &&
                        <SpaceBetween direction='vertical' size='xl'>
                            <Container>
                                <SpaceBetween direction='vertical' size='xl'>
                                    {RenderItems(selectedOptions)}
                                </SpaceBetween>
                            </Container>
                            <BusinessActivitiesFooter/>
                        </SpaceBetween>
                    }
                </SpaceBetween>
            }
        </SpaceBetween>
    );
}