import {SideNavigation} from '@amzn/awsui-components-react';
import {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation/interfaces';
import * as paths from '../../util/paths';
import {isFromAccountingTeam, isFromTechTeam} from '../../auth/UserPermissions';

export default function Navigation () {
    return (
        <SideNavigation
            activeHref={window.location.pathname}
            header={{ href: paths.getRelativePath(paths.ROOT), text: 'Home' }}
            items={navigationItems()}
        />
    );
}

// @ts-ignore
const navigationItems = (): ReadonlyArray<SideNavigationProps.Item> => {
    // Tech team should have all the access (Write operations should follow 2-person rule),
    // every other permission group should have access to a subset only
    if (isFromTechTeam()) {
        return [
            {
                type: 'section',
                text: 'Learning Tools',
                items: [
                    {
                        type: 'link',
                        text: 'Contra CoGS Simulator',
                        href: paths.getRelativePath(paths.CCOGS_SIMULATOR_PATH),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Dev Tools',
                items: [
                    {
                        type: 'link',
                        text: 'Fetch FIE Details',
                        href: paths.getRelativePath(paths.FETCH_FIE_PATH),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Flare and USL BI',
                items: [
                    {
                        type: 'link',
                        text: 'Flare Business Activities',
                        href: paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_HOME),
                    },
                    {
                        type: 'link',
                        text: 'Flare Attributes Meaning',
                        href: paths.getRelativePath(paths.FLARE_ATTRIBUTES),
                    },
                    {
                        type: 'link',
                        text: 'Sample USL BI Queries',
                        href: paths.getRelativePath(paths.USL_BI_QUERIES),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Accounting Resources',
                items: [
                    {
                        type: 'link',
                        text: 'Latest Known JEM files',
                        href: paths.getRelativePath(paths.JEM_FILES),
                    },
                    // {
                    //     type: 'link',
                    //     text: 'JE Mappings View/Update (Pilot)',
                    //     href: paths.getRelativePath(paths.JEM_V2),
                    // },
                    {
                        type: 'link',
                        text: 'Validate JE Mappings',
                        href: paths.getRelativePath(paths.JEM_VALIDATOR),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Other Tools',
                items: [
                    {
                        type: 'link',
                        text: 'ContraCoGS Tool',
                        href: paths.CCT_TOOL,
                        external: true,
                    },
                    {
                        type: 'link',
                        text: 'Cuckoo Tool',
                        href: paths.CUCKOO_TOOL,
                        external: true,
                    },
                ],
            },
            { type: 'divider' },
        ]
    }
    if (isFromAccountingTeam()) {
        return [
            {
                type: 'section',
                text: 'Flare and USL BI',
                items: [
                    {
                        type: 'link',
                        text: 'Flare Business Activities',
                        href: paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_HOME),
                    },
                    {
                        type: 'link',
                        text: 'Flare Attributes Meaning',
                        href: paths.getRelativePath(paths.FLARE_ATTRIBUTES),
                    },
                    {
                        type: 'link',
                        text: 'Sample USL BI Queries',
                        href: paths.getRelativePath(paths.USL_BI_QUERIES),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Accounting Resources',
                items: [
                    {
                        type: 'link',
                        text: 'Latest JEM files',
                        href: paths.getRelativePath(paths.JEM_FILES),
                    },
                    // {
                    //     type: 'link',
                    //     text: 'JE Mappings View/Update (Pilot)',
                    //     href: paths.getRelativePath(paths.JEM_V2),
                    // },
                    {
                        type: 'link',
                        text: 'Validate JE Mappings',
                        href: paths.getRelativePath(paths.JEM_VALIDATOR),
                    },
                ],
            },
            { type: 'divider' },
            {
                type: 'section',
                text: 'Other Tools',
                items: [
                    {
                        type: 'link',
                        text: 'ContraCoGS Tool',
                        href: paths.CCT_TOOL,
                        external: true,
                    },
                    {
                        type: 'link',
                        text: 'Cuckoo Tool',
                        href: paths.CUCKOO_TOOL,
                        external: true,
                    },
                ],
            },
            { type: 'divider' },
        ]
    }
    else {
        []
    }
}