import {TransactionType} from './TransactionType';

export interface TransactionIdentifier {
    type: TransactionType,
    id: string
}

export const CUSTOMER_SHIPMENT_ITEM: (id: string) => TransactionIdentifier = (id: string) => {
    return {
        type: TransactionType.CSI,
        id: id
    };
}

export const CUSTOMER_RETURN_ITEM: (id: string) => TransactionIdentifier = (id: string) => {
    return {
        type: TransactionType.CRI,
        id: id
    };
}