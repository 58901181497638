import {Money} from '../Money';
import * as TransactionIdentifier from './TransactionIdentifier';
import {TransactionType} from './TransactionType';
import {CustomerShipmentItem} from './CustomerShipmentItem';
import {CustomerReturnItem} from './CustomerReturnItem';
import {Transaction} from './Transaction';

export const updateValues = (transactionType: TransactionType, previousTxn: Transaction) => {
    let newTxn: Transaction;
    switch (transactionType) {
        case TransactionType.CSI:
            newTxn = CustomerShipmentItem.dummy();
            if (previousTxn instanceof CustomerShipmentItem) {
                newTxn.copyAttributesFrom(previousTxn as CustomerShipmentItem);
            }
            break;

        case TransactionType.CRI:
            newTxn = CustomerReturnItem.dummy();
            if (previousTxn instanceof CustomerReturnItem) {
                newTxn.copyAttributesFrom(previousTxn as CustomerReturnItem);
            }
            break;

        default:
            throw new Error("Not implemented!");
    }

    return newTxn;
}

export const createTransactionIdentifier = (transactionType: TransactionType, id: string) => {
    switch (transactionType) {
        case TransactionType.CSI:
            return TransactionIdentifier.CUSTOMER_SHIPMENT_ITEM(id);

        case TransactionType.CRI:
            return TransactionIdentifier.CUSTOMER_RETURN_ITEM(id);

        default:
            throw new Error("Not implemented!");
    }
}

export const doesTransactionRequireOneWarehouseId = (transactionType: TransactionType) => {
    switch (transactionType) {
        case TransactionType.CSI:
        case TransactionType.CRI:
            return true;

        default:
            throw new Error("Not implemented!");
    }
}

export const getFirstWarehouseAttribute: (transaction: Transaction) => string | undefined = (transaction: Transaction) => {
    let warehouseId: string | undefined = "";
    if (transaction == null || transaction.getTransactionIdentifier() == null) {
        return warehouseId;
    }
    switch (transaction.getTransactionIdentifier()?.type) {

        case TransactionType.NONE:
            break;

        case TransactionType.CSI:
            if (transaction instanceof CustomerShipmentItem) {
                warehouseId = transaction.getWarehouseIdOfShipment();
            }
            break;

        case TransactionType.CRI:
            if (transaction instanceof CustomerReturnItem) {
                warehouseId = transaction.getWarehouseIdOfReturn();
            }
            break;

        default:
            throw new Error("Not implemented!");
    }
    return warehouseId;

}

export const setFirstWarehouseAttribute: (warehouseId: string, transaction: Transaction) => void = (warehouseId: string, transaction: Transaction) => {
    switch (transaction.getTransactionIdentifier()?.type) {
        case TransactionType.NONE:
            break;

        case TransactionType.CSI:
            if (transaction instanceof CustomerShipmentItem) {
                transaction.setWarehouseIdOfShipment(warehouseId);
            }
            break;

        case TransactionType.CRI:
            if (transaction instanceof CustomerReturnItem) {
                transaction.setWarehouseIdOfReturn(warehouseId);
            }
            break;

        default:
            throw new Error("Not implemented!");
    }
}

export const areAllAttributesPresent: (transaction: Transaction) => boolean = (transaction: Transaction) => {
    if (transaction == null || transaction.getTransactionIdentifier() == null) {
        return false;
    }

    switch (transaction.getTransactionIdentifier()?.type) {
        case TransactionType.NONE:
            return false;

        case TransactionType.CSI:
            return validateCustomerShipmentItem(transaction);

        case TransactionType.CRI:
            return validateCustomerReturnItem(transaction);

        default:
            throw new Error("Not implemented!");
    }
}

export const validateCustomerShipmentItem = (transaction: Transaction) => {
    if (transaction instanceof CustomerShipmentItem) {
        return (
            validateTxnTypeAndCommonFields(transaction, TransactionType.CSI)
            && !isNull(transaction.getWarehouseIdOfShipment())  && !isEmptyString(transaction.getWarehouseIdOfShipment())
        );
    }
    return false;
}

export const validateCustomerReturnItem = (transaction: Transaction) => {
    if (transaction instanceof CustomerReturnItem) {
        return (
            validateTxnTypeAndCommonFields(transaction, TransactionType.CRI)
            && !isNull(transaction.getWarehouseIdOfReturn())  && !isEmptyString(transaction.getWarehouseIdOfReturn())
        );
    }
    return false;
}

export const validateTxnTypeAndCommonFields = (transaction: Transaction, txnType: TransactionType) => {
    return (
        !isNull(transaction.getTransactionIdentifier()) && txnType === transaction.getTransactionIdentifier()?.type
        && !isNull(transaction.getTransactionIdentifier()?.id) && !isEmptyString(transaction.getTransactionIdentifier()?.id)
        && !isNull(transaction.getQuantity())
        && !isNull(transaction.getPCogs()) && !isNull(transaction.getPCogs()?.amount)  && !isNull(transaction.getPCogs()?.currency) && (3 == transaction.getPCogs()?.currency.length)
        && !isNull(transaction.getProductGroupId())
        && !isNull(transaction.getAsinMarketplaceId())
    );
}

const isNull = (field) => {
    return undefined === field || null == field;
}

const isEmptyString = (field) => {
    return "" == field;
}


export const defaultPcogs: Money = {
    currency: "USD",
    amount: 10.0,
}