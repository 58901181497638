export interface AccrualBasis {
    id: number,
    label: string
}

export const SALES_BASED: AccrualBasis = {
    id: 1,
    label: 'Sales Based'
}

export const RECEIPTS_BASED: AccrualBasis = {
    id: 2,
    label: 'Receipts Based'
}

export const LUMSPUM_BASED: AccrualBasis = {
    id: 3,
    label: 'Lump Sum'
}

const ACCRUAL_BASES: AccrualBasis[] = [
    SALES_BASED,
    RECEIPTS_BASED,
    LUMSPUM_BASED,
];

export const fromLabel = (val: string | undefined | null) => {
    if (!val) {
        return null;
    }
    const index = ACCRUAL_BASES.findIndex((basis) => val === basis.label);
    return -1 === index ? null : ACCRUAL_BASES[index]
}

export const fromId = (val: number | undefined | null) => {
    if (!val) {
        return null;
    }
    const index = ACCRUAL_BASES.findIndex((basis) => val === basis.id);
    return -1 === index ? null : ACCRUAL_BASES[index]
}