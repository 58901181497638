export interface Money {
    currency: string;
    amount: number;
}

export const diff = (one: Money, two: Money) => {
    if (one.currency != two.currency) {
        throw new Error("Cannot diff two monies of different currencies");
    }

    return {
        currency: one.currency,
        amount: roundAmountVal(one.amount - two.amount),
    } as Money;
}

export const add = (one: Money, two: Money) => {
    if (one.currency != two.currency) {
        throw new Error("Cannot add two monies of different currencies");
    }

    return {
        currency: one.currency,
        amount: roundAmountVal(one.amount + two.amount),
    } as Money;
}

export const multiply = (one: Money, two: number) => {
    return {
        currency: one.currency,
        amount: roundAmountVal(one.amount * two),
    } as Money;
}

export const divide = (one: Money, two: number) => {

    return {
        currency: one.currency,
        amount: roundAmountVal(one.amount / two),
    } as Money;
}

export const serialize = (money: Money) => {
    return money.currency + " " + money.amount;
}

export const deserialize = (money: string) => {
    const parts = money.split(" ");
    return {
        currency: parts[0],
        amount: +parts[1],
    } as Money;
}

const roundAmountVal = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
}