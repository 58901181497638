export interface BusinessActivity {
    readonly serialNumber: number,
    readonly name: string,
    readonly financialComponentGroup: string,
    readonly agreementAccrualBasis: string,
    readonly description: string,
    readonly triggerEvent: string,
    readonly ficState: string,
    readonly debitAccount: string,
    readonly creditAccount: string,
    readonly dependsUpon: string[],    // Any other business activities that should have been triggered at the minimal before this activity
    readonly similarTo: string[],      // Any other business activities that this is very similar to (meaning same debit/credit accounts, sign might be different)
}

const receipts_based: string = "Receipts Based";
const lump_sum_based: string = "Lump Sum";
const straight_payment_based: string = "Straight Payment";
const volume_incentive_lumpsum_based: string = "VIR Lumpsum";
const price_protection_based: string = "Price Protection";
const estimate_reconciliation_based: string = "Estimate Reconciliation";
const sales_based: string = "Sales Based";
const rounding_correction_based: string = "Rounding Correction";

const distributor_shipment = 'distributor_shipment';
const distributor_shipment_damage_allowance = 'distributor_shipment_damage_allowance';
const distributor_shipment_recalc = 'distributor_shipment_recalc';
const distributor_shipment_recalc_product_revenue = 'distributor_shipment_recalc_product_revenue';
const intransit_cap_recalc_or_disposal_adjustment = 'intransit_cap_recalc_or_disposal_adjustment';
const distributor_return = 'distributor_return';
const distributor_return_product_revenue = 'distributor_return_product_revenue';
const distributor_return_recalc = 'distributor_return_recalc';
const distributor_return_product_revenue_recalc = 'distributor_return_product_revenue_recalc';
const customer_shipment_receipts_based = 'customer_shipment_receipts_based';
const customer_return_receipts_based = 'customer_return_receipts_based';
const inventory_adjustment = 'inventory_adjustment';
const retroactive_customer_shipment_receipts_based = 'retroactive_customer_shipment_receipts_based';
const retroactive_customer_return_receipts_based = 'retroactive_customer_return_receipts_based';
const warehouse_transfer_shipment = 'warehouse_transfer_shipment';
const warehouse_transfer_receipt = 'warehouse_transfer_receipt';
const inventory_condition_change = 'inventory_condition_change';
const inventory_ownership_change = 'inventory_ownership_change';
const cap_recalc_or_disposal_adjustment = 'cap_recalc_or_disposal_adjustment';
const product_revenue_recalc_or_disposal_adjustment_receipt_based = 'product_revenue_recalc_or_disposal_adjustment_receipt_based';
const on_hand_quantity_correction_add = 'on_hand_quantity_correction_add';
const on_hand_quantity_correction_delete = 'on_hand_quantity_correction_delete';
const straight_payment = 'straight_payment';
const straight_payment_reversal = 'straight_payment_reversal';
const vir_lump_sum = 'vir_lump_sum';
const vir_lump_sum_reversal = 'vir_lump_sum_reversal';
const inventory_on_hand_price_protection = 'inventory_on_hand_price_protection';
const inventory_on_hand_price_protection_reversal = 'inventory_on_hand_price_protection_reversal';
const inventory_on_hand_price_protection_unallocated_amounts = 'inventory_on_hand_price_protection_unallocated_amounts';
const er_unallocated_amounts = 'er_unallocated_amounts';
const customer_shipment_sales_based = 'customer_shipment_sales_based';
const customer_shipment_sales_based_recalc = 'customer_shipment_sales_based_recalc';
const customer_shipment_sales_based_digital = 'customer_shipment_sales_based_digital';
const customer_shipment_sales_based_recalc_digital = 'customer_shipment_sales_based_recalc_digital';
const customer_return_sales_based = 'customer_return_sales_based';
const customer_return_sales_based_recalc = 'customer_return_sales_based_recalc';
const customer_return_sales_based_digital = 'customer_return_sales_based_digital';
const customer_return_sales_based_recalc_digital = 'customer_return_sales_based_recalc_digital';
const digital_download_sales_based = 'digital_download_sales_based';
const digital_download_sales_based_recalc = 'digital_download_sales_based_recalc';
const digital_return_sales_based = 'digital_return_sales_based';
const digital_return_sales_based_recalc = 'digital_return_sales_based_recalc';
const product_revenue_recalc_or_disposal_adjustment = 'product_revenue_recalc_or_disposal_adjustment';
const product_revenue_recalc_or_disposal_adjustment_digital = 'product_revenue_recalc_or_disposal_adjustment_digital';
const digital_recalc_or_disposal_adjustment = 'digital_recalc_or_disposal_adjustment';
const digital_return_recalc_or_disposal_adjustment = 'digital_return_recalc_or_disposal_adjustment';
const vpc_clip = 'vpc_clip';


/////////////////////

export const DISTRIBUTOR_SHIPMENT: BusinessActivity = {
    serialNumber: 1.1,
    name: distributor_shipment,
    financialComponentGroup: distributor_shipment,
    agreementAccrualBasis: receipts_based,
    description: 'New DSI accrual',
    triggerEvent: 'DSI/Agreement Creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'CAP (WH_D)',
    dependsUpon: [],
    similarTo: [distributor_shipment_recalc, distributor_return, distributor_return_recalc, cap_recalc_or_disposal_adjustment]
}

const DISTRIBUTOR_SHIPMENT_DAMAGE_ALLOWANCE: BusinessActivity = {
    serialNumber: 1.2,
    name: distributor_shipment_damage_allowance,
    financialComponentGroup: distributor_shipment_damage_allowance,
    agreementAccrualBasis: 'Receipts Based (Damage Allowance)',
    description: 'New DSI accrual',
    triggerEvent: 'DSI/Agreement Creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [distributor_shipment_recalc_product_revenue, distributor_return_product_revenue, distributor_return_product_revenue_recalc, product_revenue_recalc_or_disposal_adjustment_receipt_based]
}

const DISTRIBUTOR_SHIPMENT_RECALC: BusinessActivity = {
    serialNumber: 2.1,
    name: distributor_shipment_recalc,
    financialComponentGroup: distributor_shipment_recalc,
    agreementAccrualBasis: receipts_based,
    description: 'Updated DSI accrual, inventory in warehouse bucket',
    triggerEvent: 'DSI update/Agreement update',
    ficState: 'DSI units in WH_B',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'CAP (WH_B) *',
    dependsUpon: [distributor_shipment],
    similarTo: [distributor_shipment, distributor_return, distributor_return_recalc, cap_recalc_or_disposal_adjustment]
}

const DISTRIBUTOR_SHIPMENT_RECALC_PRODUCT_REVENUE: BusinessActivity = {
    serialNumber: 2.2,
    name: distributor_shipment_recalc_product_revenue,
    financialComponentGroup: distributor_shipment_recalc_product_revenue,
    agreementAccrualBasis: receipts_based,
    description: 'Updated DSI accrual, inventory in marketplace bucket',
    triggerEvent: 'DSI update/Agreement update',
    ficState: 'DSI units in MKT_S',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'P&L (MKT_S) *',
    dependsUpon: [customer_shipment_receipts_based, customer_return_receipts_based],
    similarTo: [distributor_shipment_damage_allowance, distributor_return_product_revenue, distributor_return_product_revenue_recalc, product_revenue_recalc_or_disposal_adjustment_receipt_based]
}

const DISTRIBUTOR_SHIPMENT_RECALC_INTRANSIT: BusinessActivity = {
    serialNumber: 2.3,
    name: intransit_cap_recalc_or_disposal_adjustment,
    financialComponentGroup: intransit_cap_recalc_or_disposal_adjustment,
    agreementAccrualBasis: receipts_based,
    description: 'Updated DSI accrual, inventory in in-transit bucket',
    triggerEvent: 'DSI update/Agreement update or disposal',
    ficState: 'DSI units in WH_B[IT]',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'CAP[IT] (WH_B) *',
    dependsUpon: [distributor_shipment, warehouse_transfer_shipment, ],
    similarTo: []
}

const DISTRIBUTOR_RETURN: BusinessActivity = {
    serialNumber: 3.1,
    name: distributor_return,
    financialComponentGroup: distributor_return,
    agreementAccrualBasis: receipts_based,
    description: 'New DRI accrual',
    triggerEvent: 'DRI/Agreement Creation',
    ficState: '(Empty)',
    debitAccount: 'CAP (WH_2)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [distributor_shipment],
    similarTo: [distributor_shipment, distributor_shipment_recalc, distributor_return_recalc, cap_recalc_or_disposal_adjustment]
}

const DISTRIBUTOR_RETURN_PRODUCT_REVENUE: BusinessActivity = {
    serialNumber: 3.2,
    name: distributor_return_product_revenue,
    financialComponentGroup: distributor_return_product_revenue,
    agreementAccrualBasis: receipts_based,
    description: 'New "Dangling" DRI accrual',
    triggerEvent: 'DRI (Dangling) /Agreement Creation',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_R)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [distributor_shipment_damage_allowance, distributor_shipment_recalc_product_revenue, distributor_return_product_revenue_recalc, product_revenue_recalc_or_disposal_adjustment_receipt_based]
}

const DISTRIBUTOR_RETURN_RECALC: BusinessActivity = {
    serialNumber: 4.1,
    name: distributor_return_recalc,
    financialComponentGroup: distributor_return_recalc,
    agreementAccrualBasis: receipts_based,
    description: 'Updated DRI accrual',
    triggerEvent: 'DRI update/Agreement update',
    ficState: 'DSI units in WH_2',
    debitAccount: 'CAP (WH_2)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [distributor_return],
    similarTo: [distributor_shipment, distributor_shipment_recalc, distributor_return, cap_recalc_or_disposal_adjustment]
}

const DISTRIBUTOR_RETURN_PRODUCT_REVENUE_RECALC: BusinessActivity = {
    serialNumber: 4.2,
    name: distributor_return_product_revenue_recalc,
    financialComponentGroup: distributor_return_product_revenue_recalc,
    agreementAccrualBasis: receipts_based,
    description: 'Updated "Dangling" DRI accrual',
    triggerEvent: 'DRI (Dangling) update/Agreement update',
    ficState: 'DSI units in MKT_B',
    debitAccount: 'P&L (MKT_B)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [distributor_return_product_revenue],
    similarTo: [distributor_shipment_damage_allowance, distributor_shipment_recalc_product_revenue, distributor_return_product_revenue, product_revenue_recalc_or_disposal_adjustment_receipt_based]
}

const CUSTOMER_SHIPMENT_RECEIPTS_BASED: BusinessActivity = {
    serialNumber: 5.1,
    name: customer_shipment_receipts_based,
    financialComponentGroup: customer_shipment_receipts_based,
    agreementAccrualBasis: receipts_based,
    description: 'Customer Shipment Tracking DSI funding',
    triggerEvent: 'CSI',
    ficState: 'DSI units in WH_1',
    debitAccount: 'CAP (WH_1)',
    creditAccount: 'P&L (MKT_S)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [customer_return_receipts_based, inventory_adjustment, retroactive_customer_shipment_receipts_based, retroactive_customer_return_receipts_based, on_hand_quantity_correction_add, on_hand_quantity_correction_delete]
}

const CUSTOMER_RETURN_RECEIPTS_BASED: BusinessActivity = {
    serialNumber: 5.2,
    name: customer_return_receipts_based,
    financialComponentGroup: customer_return_receipts_based,
    agreementAccrualBasis: receipts_based,
    description: 'Customer Return tracking DSI funding',
    triggerEvent: 'CRI',
    ficState: 'DSI units in MKT_R',
    debitAccount: 'P&L (MKT_R)',
    creditAccount: 'CAP (WH_2)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [customer_shipment_receipts_based, inventory_adjustment, retroactive_customer_shipment_receipts_based, retroactive_customer_return_receipts_based, on_hand_quantity_correction_add, on_hand_quantity_correction_delete]
}

const INVENTORY_ADJUSTMENT: BusinessActivity = {
    serialNumber: 5.3,
    name: inventory_adjustment,
    financialComponentGroup: inventory_adjustment,
    agreementAccrualBasis: receipts_based + " (Total Receipts)",
    description: 'Vendor Return (current or retroactive) tracking DSI funding',
    triggerEvent: 'DRI/Scheduled Job (day of agreement creation) for retroactive',
    ficState: 'DSI units in WH_2',
    debitAccount: 'CAP (WH_2)',
    creditAccount: 'P&L (WH_2)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [customer_shipment_receipts_based, customer_return_receipts_based, retroactive_customer_shipment_receipts_based, retroactive_customer_return_receipts_based, on_hand_quantity_correction_add, on_hand_quantity_correction_delete]
}

const RETRO_CUSTOMER_SHIPMENT_RECEIPTS_BASED: BusinessActivity = {
    serialNumber: 6.1,
    name: retroactive_customer_shipment_receipts_based,
    financialComponentGroup: retroactive_customer_shipment_receipts_based,
    agreementAccrualBasis: receipts_based,
    description: 'Retroactive CSI tracking DSI funding',
    triggerEvent: 'Scheduled Job (day of agreement creation)',
    ficState: 'DSI units in WH_1',
    debitAccount: 'CAP (WH_1)',
    creditAccount: 'P&L (MKT_S)',
    dependsUpon: [customer_shipment_receipts_based, ],
    similarTo: [customer_shipment_receipts_based, customer_return_receipts_based, inventory_adjustment, retroactive_customer_return_receipts_based, on_hand_quantity_correction_add, on_hand_quantity_correction_delete]
}

const RETRO_CUSTOMER_RETURN_RECEIPTS_BASED: BusinessActivity = {
    serialNumber: 6.2,
    name: retroactive_customer_return_receipts_based,
    financialComponentGroup: retroactive_customer_return_receipts_based,
    agreementAccrualBasis: receipts_based,
    description: 'Retroactive CRI tracking DSI funding',
    triggerEvent: 'Scheduled Job (day of agreement creation)',
    ficState: 'DSI units in MKT_B',
    debitAccount: 'P&L (MKT_B)',
    creditAccount: 'CAP (WH_2)',
    dependsUpon: [customer_return_receipts_based, ],
    similarTo: [customer_shipment_receipts_based, customer_return_receipts_based, inventory_adjustment, retroactive_customer_shipment_receipts_based, on_hand_quantity_correction_add, on_hand_quantity_correction_delete]
}

const WAREHOUSE_TRANSFER_SHIPMENT: BusinessActivity = {
    serialNumber: 7.1,
    name: warehouse_transfer_shipment,
    financialComponentGroup: warehouse_transfer_shipment,
    agreementAccrualBasis: receipts_based,
    description: 'Shipped Inventory transfer from one FC to other',
    triggerEvent: 'SHIP.TM (AFT/AFTLite)',
    ficState: 'DSI units in WH_1',
    debitAccount: 'CAP (WH_1)',
    creditAccount: 'CAP[IT] (WH_2)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [warehouse_transfer_receipt, inventory_condition_change, inventory_ownership_change],
}

const WAREHOUSE_TRANSFER_RECEIPT: BusinessActivity = {
    serialNumber: 7.2,
    name: warehouse_transfer_receipt,
    financialComponentGroup: warehouse_transfer_receipt,
    agreementAccrualBasis: receipts_based,
    description: 'Received Inventory transfer from one FC to other',
    triggerEvent: 'RECEIVE.TDI (AFT/AFTLite)',
    ficState: 'DSI units in WH_2[IT] bucket',
    debitAccount: 'CAP[IT] (WH_2)',
    creditAccount: 'CAP (WH_2)',
    dependsUpon: [warehouse_transfer_shipment, ],
    similarTo: [warehouse_transfer_shipment, inventory_condition_change, inventory_ownership_change],
}

const INVENTORY_CONDITION_CHANGE: BusinessActivity = {
    serialNumber: 8.1,
    name: inventory_condition_change,
    financialComponentGroup: inventory_condition_change,
    agreementAccrualBasis: receipts_based,
    description: 'Inventory condition change due to "disposition" like lost/found/destroyed etc',
    triggerEvent: 'Inventory Event (AFT/AFTLite)',
    ficState: 'DSI units in WH_B',
    debitAccount: 'CAP (WH_B)',
    creditAccount: 'CAP (WH_B)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [warehouse_transfer_shipment, warehouse_transfer_receipt, inventory_ownership_change],
}

const INVENTORY_OWNERSHIP_CHANGE: BusinessActivity = {
    serialNumber: 8.2,
    name: inventory_ownership_change,
    financialComponentGroup: inventory_ownership_change,
    agreementAccrualBasis: receipts_based,
    description: 'Inventory owner transfer to a different entity, like PG or IOG flips',
    triggerEvent: 'Inventory Event (AFT/AFTLite)',
    ficState: 'DSI units in WH_B',
    debitAccount: 'CAP (WH_B)',
    creditAccount: 'CAP (WH_B)',
    dependsUpon: [inventory_condition_change, ],
    similarTo: [warehouse_transfer_shipment, warehouse_transfer_receipt, inventory_condition_change],
}

const CAP_RECALC_DISPOSAL: BusinessActivity = {
    serialNumber: 9.1,
    name: cap_recalc_or_disposal_adjustment,
    financialComponentGroup: cap_recalc_or_disposal_adjustment,
    agreementAccrualBasis: receipts_based,
    description: 'Reversed DSI accrual, inventory in warehouse bucket',
    triggerEvent: 'DSI Deletion/Agreement Edit or Disposal',
    ficState: 'DSI units in WH_B',
    debitAccount: 'CAP (WH_B)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [distributor_shipment, ],
    similarTo: [distributor_shipment, distributor_shipment_recalc, distributor_return, distributor_return_recalc]
}

const PRODUCT_REVENUE_RECALC_DISPOSAL: BusinessActivity = {
    serialNumber: 9.2,
    name: product_revenue_recalc_or_disposal_adjustment_receipt_based,
    financialComponentGroup: product_revenue_recalc_or_disposal_adjustment_receipt_based,
    agreementAccrualBasis: receipts_based,
    description: 'Reversed DSI accrual, inventory in marketplace bucket',
    triggerEvent: 'DSI Deletion/Agreement Edit or Disposal',
    ficState: 'DSI units in MKT_B',
    debitAccount: 'P&L (MKT_B)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [customer_shipment_receipts_based, ],
    similarTo: [distributor_shipment_damage_allowance, distributor_shipment_recalc_product_revenue, distributor_return_product_revenue, distributor_return_product_revenue_recalc]
}

const ON_HAND_CORRECTION_ADD: BusinessActivity = {
    serialNumber: 10.1,
    name: on_hand_quantity_correction_add,
    financialComponentGroup: on_hand_quantity_correction_add,
    agreementAccrualBasis: receipts_based,
    description: 'On-hand quantity correction, CCT quantity < on-hand quantity',
    triggerEvent: 'Scheduled job',
    ficState: '# DSI units tracked by CCT in WH_B < # on-hand DSI units in WH_B',
    debitAccount: 'P&L (MKT_D)',
    creditAccount: 'CAP (WH_B)',
    dependsUpon: [distributor_shipment, customer_shipment_receipts_based, customer_return_receipts_based],
    similarTo: [customer_shipment_receipts_based, customer_return_receipts_based, inventory_adjustment, retroactive_customer_shipment_receipts_based, retroactive_customer_return_receipts_based, on_hand_quantity_correction_delete]
}

const ON_HAND_CORRECTION_DELETE: BusinessActivity = {
    serialNumber: 10.2,
    name: on_hand_quantity_correction_delete,
    financialComponentGroup: on_hand_quantity_correction_delete,
    agreementAccrualBasis: receipts_based,
    description: 'On-hand quantity correction, CCT quantity > on-hand quantity',
    triggerEvent: 'Scheduled job',
    ficState: '# DSI units tracked by CCT in WH_B > # on-hand DSI units in WH_B',
    debitAccount: 'CAP (WH_B)',
    creditAccount: 'P&L (MKT_D)',
    dependsUpon: [distributor_shipment, customer_shipment_receipts_based, customer_return_receipts_based],
    similarTo: [customer_shipment_receipts_based, customer_return_receipts_based, inventory_adjustment, retroactive_customer_shipment_receipts_based, retroactive_customer_return_receipts_based, on_hand_quantity_correction_add]
}

///////////

export const STRAIGHT_PAYMENT: BusinessActivity = {
    serialNumber: 11.1,
    name: straight_payment,
    financialComponentGroup: straight_payment,
    agreementAccrualBasis: lump_sum_based + ' - ' + straight_payment_based,
    description: 'New +ve amount accrual',
    triggerEvent: 'Period Amount Created',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment_reversal, vir_lump_sum, vir_lump_sum_reversal, inventory_on_hand_price_protection_unallocated_amounts, er_unallocated_amounts, vpc_clip]
}

export const STRAIGHT_PAYMENT_REVERSAL: BusinessActivity = {
    serialNumber: 11.2,
    name: straight_payment_reversal,
    financialComponentGroup: straight_payment_reversal,
    agreementAccrualBasis: lump_sum_based + ' - ' + straight_payment_based,
    description: '-ve amount accrual',
    triggerEvent: 'Period Amount Reversed',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_A)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, vir_lump_sum, vir_lump_sum_reversal, inventory_on_hand_price_protection_unallocated_amounts, er_unallocated_amounts, vpc_clip]
}

const VIR_LUMPSUM: BusinessActivity = {
    serialNumber: 11.3,
    name: vir_lump_sum,
    financialComponentGroup: vir_lump_sum,
    agreementAccrualBasis: lump_sum_based + ' - ' + volume_incentive_lumpsum_based,
    description: 'New +ve amount accrual',
    triggerEvent: 'Period Amount Created',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, straight_payment_reversal, vir_lump_sum_reversal, inventory_on_hand_price_protection_unallocated_amounts, er_unallocated_amounts, vpc_clip]
}

const VIR_LUMPSUM_REVERSAL: BusinessActivity = {
    serialNumber: 11.4,
    name: vir_lump_sum_reversal,
    financialComponentGroup: vir_lump_sum_reversal,
    agreementAccrualBasis: lump_sum_based + ' - ' + volume_incentive_lumpsum_based,
    description: '-ve amount accrual',
    triggerEvent: 'Period Amount Reversed',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_A)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, straight_payment_reversal, vir_lump_sum, inventory_on_hand_price_protection_unallocated_amounts, er_unallocated_amounts, vpc_clip]
}

/////////////

const PRICE_PROTECTION: BusinessActivity = {
    serialNumber: 21.1,
    name: inventory_on_hand_price_protection,
    financialComponentGroup: inventory_on_hand_price_protection,
    agreementAccrualBasis: price_protection_based,
    description: 'Cost drop for inventory on-hand in Amazon FCs',
    triggerEvent: 'Agreement signing',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'CAP (WH_D)',
    dependsUpon: [],
    similarTo: [inventory_on_hand_price_protection_reversal]
}

const PRICE_PROTECTION_REVERSAL: BusinessActivity = {
    serialNumber: 21.2,
    name: inventory_on_hand_price_protection_reversal,
    financialComponentGroup: inventory_on_hand_price_protection_reversal,
    agreementAccrualBasis: price_protection_based,
    description: 'Agreement disposal',
    triggerEvent: 'Agreement disposal',
    ficState: '(Empty)',
    debitAccount: 'CAP (WH_D)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [inventory_on_hand_price_protection]
}

const PRICE_PROTECTION_UNALLOCATED: BusinessActivity = {
    serialNumber: 22.1,
    name: inventory_on_hand_price_protection_unallocated_amounts,
    financialComponentGroup: inventory_on_hand_price_protection_unallocated_amounts,
    agreementAccrualBasis: price_protection_based,
    description: 'Cost drop for inventory not on-hand in Amazon FCs',
    triggerEvent: 'Agreement signing',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, straight_payment_reversal, vir_lump_sum, vir_lump_sum_reversal, er_unallocated_amounts, vpc_clip]
}

/////////////

const ER_UNALLOCATED: BusinessActivity = {
    serialNumber: 31.1,
    name: er_unallocated_amounts,
    financialComponentGroup: er_unallocated_amounts,
    agreementAccrualBasis: estimate_reconciliation_based,
    description: 'ER actual for a period that did not accrue anything',
    triggerEvent: 'ER Actuals approved',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, straight_payment_reversal, vir_lump_sum, vir_lump_sum_reversal, inventory_on_hand_price_protection_unallocated_amounts, vpc_clip]
}

/////////////

const CUSTOMER_SHIPMENT_SALES_BASED: BusinessActivity = {
    serialNumber: 41.1,
    name: customer_shipment_sales_based,
    financialComponentGroup: customer_shipment_sales_based,
    agreementAccrualBasis: sales_based,
    description: 'New CSI accrual',
    triggerEvent: 'CSI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_S)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_SHIPMENT_SALES_BASED_RECALC: BusinessActivity = {
    serialNumber: 41.2,
    name: customer_shipment_sales_based_recalc,
    financialComponentGroup: customer_shipment_sales_based_recalc,
    agreementAccrualBasis: sales_based,
    description: 'Updated CSI accrual',
    triggerEvent: 'CSI update/Agreement update',
    ficState: 'CSI units in MKT_B',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'P&L (MKT_B) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_SHIPMENT_SALES_BASED_DIGITAL: BusinessActivity = {
    serialNumber: 42.1,
    name: customer_shipment_sales_based_digital,
    financialComponentGroup: customer_shipment_sales_based_digital,
    agreementAccrualBasis: sales_based,
    description: 'New CSI accrual (digital product)',
    triggerEvent: 'CSI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_B)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_SHIPMENT_SALES_BASED_RECALC_DIGITAL: BusinessActivity = {
    serialNumber: 42.2,
    name: customer_shipment_sales_based_recalc_digital,
    financialComponentGroup: customer_shipment_sales_based_recalc_digital,
    agreementAccrualBasis: sales_based,
    description: 'Updated CSI accrual (digital product)',
    triggerEvent: 'CSI update/Agreement update',
    ficState: 'CSI units in MKT_B',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'P&L (MKT_B) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_RETURN_SALES_BASED: BusinessActivity = {
    serialNumber: 43.1,
    name: customer_return_sales_based,
    financialComponentGroup: customer_return_sales_based,
    agreementAccrualBasis: sales_based,
    description: 'New CRI accrual',
    triggerEvent: 'CRI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_R)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_RETURN_SALES_BASED_RECALC: BusinessActivity = {
    serialNumber: 43.2,
    name: customer_return_sales_based_recalc,
    financialComponentGroup: customer_return_sales_based_recalc,
    agreementAccrualBasis: sales_based,
    description: 'Updated CRI accrual',
    triggerEvent: 'CRI update/Agreement update',
    ficState: 'CRI units in MKT_R',
    debitAccount: 'P&L (MKT_R) *',
    creditAccount: 'ABNB (MKT_A) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_RETURN_SALES_BASED_DIGITAL: BusinessActivity = {
    serialNumber: 44.1,
    name: customer_return_sales_based_digital,
    financialComponentGroup: customer_return_sales_based_digital,
    agreementAccrualBasis: sales_based,
    description: 'New CRI accrual (digital product)',
    triggerEvent: 'CRI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_R)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const CUSTOMER_RETURN_SALES_BASED_RECALC_DIGITAL: BusinessActivity = {
    serialNumber: 44.2,
    name: customer_return_sales_based_recalc_digital,
    financialComponentGroup: customer_return_sales_based_recalc_digital,
    agreementAccrualBasis: sales_based,
    description: 'Updated CRI accrual (digital product)',
    triggerEvent: 'CRI update/Agreement update',
    ficState: 'CRI units in MKT_R',
    debitAccount: 'P&L (MKT_R) *',
    creditAccount: 'ABNB (MKT_A) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_DOWNLOAD_SALES_BASED: BusinessActivity = {
    serialNumber: 45.1,
    name: digital_download_sales_based,
    financialComponentGroup: digital_download_sales_based,
    agreementAccrualBasis: sales_based,
    description: 'New DDI accrual',
    triggerEvent: 'DDI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_S)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_DOWNLOAD_SALES_BASED_RECALC: BusinessActivity = {
    serialNumber: 45.2,
    name: digital_download_sales_based_recalc,
    financialComponentGroup: digital_download_sales_based_recalc,
    agreementAccrualBasis: sales_based,
    description: 'Updated DDI accrual',
    triggerEvent: 'DDI update/Agreement update',
    ficState: 'DDI units in MKT_S',
    debitAccount: 'ABNB (MKT_A) *',
    creditAccount: 'P&L (MKT_S) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_RETURN_SALES_BASED: BusinessActivity = {
    serialNumber: 46.1,
    name: digital_return_sales_based,
    financialComponentGroup: digital_return_sales_based,
    agreementAccrualBasis: sales_based,
    description: 'New DDRI accrual',
    triggerEvent: 'DDRI/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'P&L (MKT_S)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_RETURN_SALES_BASED_RECALC: BusinessActivity = {
    serialNumber: 46.2,
    name: digital_return_sales_based_recalc,
    financialComponentGroup: digital_return_sales_based_recalc,
    agreementAccrualBasis: sales_based,
    description: 'Updated DDRI accrual',
    triggerEvent: 'DDRI update/Agreement update',
    ficState: 'DDRI units in MKT_S',
    debitAccount: 'P&L (MKT_S) *',
    creditAccount: 'ABNB (MKT_A) *',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const PRODUCT_REVENUE_DISPOSAL_SALES_BASED: BusinessActivity = {
    serialNumber: 47.1,
    name: product_revenue_recalc_or_disposal_adjustment,
    financialComponentGroup: product_revenue_recalc_or_disposal_adjustment,
    agreementAccrualBasis: sales_based,
    description: 'Reversed CSI/CRI accrual',
    triggerEvent: 'CSI/CRI Deletion/Agreement Edit or Disposal',
    ficState: 'CSI/CRI units in MKT_B',
    debitAccount: 'P&L (MKT_B) +',
    creditAccount: 'ABNB (MKT_A) +',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const PRODUCT_REVENUE_DISPOSAL_SALES_BASED_DIGITAL: BusinessActivity = {
    serialNumber: 47.2,
    name: product_revenue_recalc_or_disposal_adjustment_digital,
    financialComponentGroup: product_revenue_recalc_or_disposal_adjustment_digital,
    agreementAccrualBasis: sales_based,
    description: 'Reversed CSI/CRI accrual (digital product)',
    triggerEvent: 'CSI/CRI Deletion/Agreement Edit or Disposal',
    ficState: 'CSI/CRI units in MKT_B',
    debitAccount: 'P&L (MKT_B) +',
    creditAccount: 'ABNB (MKT_A) +',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_DISPOSAL: BusinessActivity = {
    serialNumber: 47.3,
    name: digital_recalc_or_disposal_adjustment,
    financialComponentGroup: digital_recalc_or_disposal_adjustment,
    agreementAccrualBasis: sales_based,
    description: 'Reversed DDI accrual',
    triggerEvent: 'DDI Deletion/Agreement Edit or Disposal',
    ficState: 'DDI units in MKT_B',
    debitAccount: 'P&L (MKT_B)',
    creditAccount: 'ABNB (MKT_A)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const DIGITAL_RETURN_DISPOSAL: BusinessActivity = {
    serialNumber: 47.4,
    name: digital_return_recalc_or_disposal_adjustment,
    financialComponentGroup: digital_return_recalc_or_disposal_adjustment,
    agreementAccrualBasis: sales_based,
    description: 'Reversed DDRI accrual',
    triggerEvent: 'DDRI Deletion/Agreement Edit or Disposal',
    ficState: 'DDRI units in MKT_B',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_B)',
    dependsUpon: [],
    similarTo: [],  // FIXME
}

const VPC_CLIPS: BusinessActivity = {
    serialNumber: 48.1,
    name: vpc_clip,
    financialComponentGroup: vpc_clip,
    agreementAccrualBasis: sales_based + ' (Vendor Powered Coupons)',
    description: 'New clipped coupon accrual',
    triggerEvent: 'VPC/Agreement creation',
    ficState: '(Empty)',
    debitAccount: 'ABNB (MKT_A)',
    creditAccount: 'P&L (MKT_A)',
    dependsUpon: [],
    similarTo: [straight_payment, straight_payment_reversal, vir_lump_sum, vir_lump_sum_reversal, inventory_on_hand_price_protection_unallocated_amounts, er_unallocated_amounts]
}

// TODO: Brutus related business activities

/////////////


/////////////

const LIST_OF_RECEIPT_BASED_BIZ_ACTIVITIES: BusinessActivity[] = [
    DISTRIBUTOR_SHIPMENT,
    DISTRIBUTOR_SHIPMENT_DAMAGE_ALLOWANCE,
    DISTRIBUTOR_SHIPMENT_RECALC,
    DISTRIBUTOR_SHIPMENT_RECALC_PRODUCT_REVENUE,
    DISTRIBUTOR_SHIPMENT_RECALC_INTRANSIT,
    DISTRIBUTOR_RETURN,
    DISTRIBUTOR_RETURN_PRODUCT_REVENUE,
    DISTRIBUTOR_RETURN_RECALC,
    DISTRIBUTOR_RETURN_PRODUCT_REVENUE_RECALC,
    CUSTOMER_SHIPMENT_RECEIPTS_BASED,
    CUSTOMER_RETURN_RECEIPTS_BASED,
    INVENTORY_ADJUSTMENT,
    RETRO_CUSTOMER_SHIPMENT_RECEIPTS_BASED,
    RETRO_CUSTOMER_RETURN_RECEIPTS_BASED,
    WAREHOUSE_TRANSFER_SHIPMENT,
    WAREHOUSE_TRANSFER_RECEIPT,
    INVENTORY_CONDITION_CHANGE,
    INVENTORY_OWNERSHIP_CHANGE,
    CAP_RECALC_DISPOSAL,
    PRODUCT_REVENUE_RECALC_DISPOSAL,
    ON_HAND_CORRECTION_ADD,
    ON_HAND_CORRECTION_DELETE,
];

const LIST_OF_LUMPSUM_BIZ_ACTIVITIES: BusinessActivity[] = [
    STRAIGHT_PAYMENT,
    STRAIGHT_PAYMENT_REVERSAL,
    VIR_LUMPSUM,
    VIR_LUMPSUM_REVERSAL,
];

const LIST_OF_PRICE_PROTECTION_BIZ_ACTIVITIES: BusinessActivity[] = [
    PRICE_PROTECTION,
    PRICE_PROTECTION_REVERSAL,
    PRICE_PROTECTION_UNALLOCATED,
];

const LIST_OF_ER_BIZ_ACTIVITIES: BusinessActivity[] = [
    ER_UNALLOCATED,
];

const LIST_OF_SALES_BASED_BIZ_ACTIVITIES: BusinessActivity[] = [
    CUSTOMER_SHIPMENT_SALES_BASED,
    CUSTOMER_SHIPMENT_SALES_BASED_RECALC,
    CUSTOMER_SHIPMENT_SALES_BASED_DIGITAL,
    CUSTOMER_SHIPMENT_SALES_BASED_RECALC_DIGITAL,
    CUSTOMER_RETURN_SALES_BASED,
    CUSTOMER_RETURN_SALES_BASED_RECALC,
    CUSTOMER_RETURN_SALES_BASED_DIGITAL,
    CUSTOMER_RETURN_SALES_BASED_RECALC_DIGITAL,
    DIGITAL_DOWNLOAD_SALES_BASED,
    DIGITAL_DOWNLOAD_SALES_BASED_RECALC,
    DIGITAL_RETURN_SALES_BASED,
    DIGITAL_RETURN_SALES_BASED_RECALC,
    PRODUCT_REVENUE_DISPOSAL_SALES_BASED,
    PRODUCT_REVENUE_DISPOSAL_SALES_BASED_DIGITAL,
    DIGITAL_DISPOSAL,
    DIGITAL_RETURN_DISPOSAL,
    VPC_CLIPS
];

const LIST_OF_ROUNDING_CORRECTION_BIZ_ACTIVITIES: BusinessActivity[] = [];


export interface AccrualBasis {
    readonly id: string,
    readonly name: string,
    readonly businessActivities: BusinessActivity[],
}

const RECEIPTS_BASED: AccrualBasis = {
    id: "0",
    name: receipts_based,
    businessActivities: LIST_OF_RECEIPT_BASED_BIZ_ACTIVITIES,
}

export const LUMPSUM_AGREEMENT: AccrualBasis = {
    id: "1",
    name: lump_sum_based,
    businessActivities: LIST_OF_LUMPSUM_BIZ_ACTIVITIES,
}

const PP_AGREEMENT: AccrualBasis = {
    id: "2",
    name: price_protection_based,
    businessActivities: LIST_OF_PRICE_PROTECTION_BIZ_ACTIVITIES,
}

const ER_AGREEMENT: AccrualBasis = {
    id: "3",
    name: estimate_reconciliation_based,
    businessActivities: LIST_OF_ER_BIZ_ACTIVITIES,
}

const SALES_BASED: AccrualBasis = {
    id: "4",
    name: sales_based,
    businessActivities: LIST_OF_SALES_BASED_BIZ_ACTIVITIES,
}

const MISCELLANEOUS: AccrualBasis = {
    id: "5",
    name: rounding_correction_based,
    businessActivities: LIST_OF_ROUNDING_CORRECTION_BIZ_ACTIVITIES,
}

export const ACCRUAL_BASES: AccrualBasis[] = [
    RECEIPTS_BASED,
    LUMPSUM_AGREEMENT,
    PP_AGREEMENT,
    ER_AGREEMENT,
    SALES_BASED,
    MISCELLANEOUS,
];

export const ALL_BIZ_ACTIVITIES: BusinessActivity[] = []
ACCRUAL_BASES.forEach(basis => {
    basis.businessActivities.forEach(bizActivity => ALL_BIZ_ACTIVITIES.push(bizActivity));
});

export const getAccrualBasisFromId = (id: string) => {
    let returnVal: AccrualBasis | null = null;
    ACCRUAL_BASES.forEach(basis => {
        if (basis.id == id) {
            returnVal = basis;
            return; // break loop
        }
    })
    return returnVal;
}

export const getAccrualBasisFromName = (name: string) => {
    let returnVal: AccrualBasis | null = null;
    ACCRUAL_BASES.forEach(basis => {
        if (name.includes(basis.name)) {
            returnVal = basis;
            return; // break loop
        }
    })
    return returnVal;
}

export const getBusinessActivityByName = (name: string | undefined | null) => {
    let returnVal: BusinessActivity | undefined;
    if (null != name) {
        ALL_BIZ_ACTIVITIES.forEach(activity => {
            if (name == activity.name) {
                returnVal = activity;
                return; // break loop
            }
        })
    }
    return returnVal;
}

export const dependentBusinessActivities = (bizActivity: BusinessActivity) => {
    const returnVal: BusinessActivity[] = [];
    const accrualBasis: AccrualBasis | null = getAccrualBasisFromName(bizActivity.agreementAccrualBasis);

    if (null != accrualBasis) {
        let listOfActivities;
        if (accrualBasis == RECEIPTS_BASED) {
            listOfActivities = LIST_OF_RECEIPT_BASED_BIZ_ACTIVITIES;
        }
        else if (accrualBasis == LUMPSUM_AGREEMENT) {
            listOfActivities = LIST_OF_LUMPSUM_BIZ_ACTIVITIES;
        }
        else if (accrualBasis == PP_AGREEMENT) {
            listOfActivities = LIST_OF_PRICE_PROTECTION_BIZ_ACTIVITIES;
        }
        else if (accrualBasis == ER_AGREEMENT) {
            listOfActivities = LIST_OF_ER_BIZ_ACTIVITIES;
        }
        else if (accrualBasis == SALES_BASED) {
            listOfActivities = LIST_OF_SALES_BASED_BIZ_ACTIVITIES;
        }
        else {
            listOfActivities = [];
        }
        listOfActivities.forEach(activity => {
            if (new Set(activity.dependsUpon).has(bizActivity.name)) {
                returnVal.push(activity);
            }
        });
    }

    return returnVal;
}