import {Transaction} from './Transaction';
import {Money} from '../Money';
import {defaultPcogs} from './TransactionAttributes';
import * as TransactionIdentifier from './TransactionIdentifier';


export class CustomerShipmentItem extends Transaction {

    private _warehouseIdOfShipment: string;

    // TODO - merchant id

    constructor(transactionIdentifier: TransactionIdentifier.TransactionIdentifier,
                quantity: number,
                pcogs: Money,
                productGroupId: number,
                asinMarketplaceId: number,
                isValid: boolean,
                warehouseIdOfShipment : string) {
        super(transactionIdentifier, quantity, pcogs, productGroupId, asinMarketplaceId, isValid);
        this._warehouseIdOfShipment = warehouseIdOfShipment;
    }


    public getTransactionIdentifier() {
        return this._transactionIdentifier;
    };

    public getQuantity() {
        return this._quantity;
    };

    public getPCogs() {
        return this._pcogs;
    };

    public getProductGroupId() {
        return this._productGroupId;
    };

    public getAsinMarketplaceId() {
        return this._asinMarketplaceId;
    };

    public isValid() {
        return this._isValid;
    };

    public getWarehouseIdOfShipment() {
        return this._warehouseIdOfShipment;
    }

    public setWarehouseIdOfShipment(warehouseIdOfShipment : string) : void {
        this._warehouseIdOfShipment = warehouseIdOfShipment;
    }

    public copyAttributesFrom(inputTxn: CustomerShipmentItem): void {
        this.setTransactionIdentifier(inputTxn.getTransactionIdentifier() ?? this.getTransactionIdentifier() as TransactionIdentifier.TransactionIdentifier);
        this.setQuantity(inputTxn.getQuantity() ?? this.getQuantity() as number);
        this.setPcogs(inputTxn.getPCogs() ?? this.getPCogs() as Money);
        this.setProductGroupId(inputTxn.getProductGroupId() ?? this.getProductGroupId() as number);
        this.setAsinMarketplaceId(inputTxn.getAsinMarketplaceId() ?? this.getAsinMarketplaceId() as number);
        this.setValid(inputTxn.isValid());
        this.setWarehouseIdOfShipment(inputTxn.getWarehouseIdOfShipment() as string);
    }

    public static dummy(): CustomerShipmentItem {
        return new CustomerShipmentItem(TransactionIdentifier.CUSTOMER_SHIPMENT_ITEM("1234"), 1, defaultPcogs, 14, 1, true, "SEA1");
    }


}