import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {isFromAccountingTeam, isFromTechTeam} from '../../../../auth/UserPermissions';
import PermissionsModal from '../../../PermissionsModal';
import {BusinessActivity} from '../../../flare_usl_bi/business_activities/BusinessActivity';
import JEMViewerHelper from './JEMViewerHelper';
import * as jemHelper from '../JEMHelper';
import {AlertPilot, JEMBusinessActivityDropdown, JEMAccrualBasis, JEMBusinessCountryCode} from '../JEMHelper';

export default function JEMappingViewer () {
    const [hasReadOnlyAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());
    const [hasAdminAccess] = React.useState(isFromAccountingTeam());

    const [businessCountry, setBusinessCountry] = React.useState(null);
    const [accrualBasis, setAccrualBasis] = React.useState(jemHelper.nullAccrualBasis);
    const [dropdownItems, setDropdownItems] = React.useState([] as BusinessActivity[]);
    const [businessActivity, setBusinessActivity] = React.useState(jemHelper.nullBusinessActivity);

    const Render = () => {
        if (hasReadOnlyAccess) {
            return (
                <SpaceBetween direction='vertical' size='xl'>
                    <AlertPilot />
                    <JEMBusinessCountryCode businessCountry={businessCountry} setBusinessCountry={setBusinessCountry}/>
                    {null != businessCountry && <JEMAccrualBasis accrualBasis={accrualBasis} setAccrualBasis={setAccrualBasis} setDropdownItems={setDropdownItems} setBusinessActivity={setBusinessActivity}/>}
                    {null != accrualBasis && <JEMBusinessActivityDropdown dropdownItems={dropdownItems} businessActivity={businessActivity} setBusinessActivity={setBusinessActivity}/>}
                    {null != businessActivity && <JEMViewerHelper hasAdminAccess={hasAdminAccess} businessCountry={businessCountry} accrualBasis={accrualBasis} dropdownItems={dropdownItems} businessActivity={businessActivity}/>}
                </SpaceBetween>
            );
        }
    }

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasReadOnlyAccess && <PermissionsModal hasPageAccess={hasReadOnlyAccess}/>}
            {Render()}
        </SpaceBetween>
    );
}