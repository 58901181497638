import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import * as DropDownOptions from './common/DropDownOptions';
import Select from '@amzn/awsui-components-react/polaris/select';
import * as TransactionAttributes from './model/transaction/TransactionAttributes';
import {TransactionType} from './model/transaction/TransactionType';
import {Container} from '@amzn/awsui-components-react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Form from '@amzn/awsui-components-react/polaris/form';
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import * as RadioButtonOptions from './common/RadioButtonOptions';
import {Transaction} from './model/transaction/Transaction';
import Input from '@amzn/awsui-components-react/polaris/input';
import {TransactionIdentifier} from './model/transaction/TransactionIdentifier';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';

const emptyTransaction: Transaction =  Transaction.empty();

export default function TransactionForm(props) {

    const [formTransactionType, setFormTransactionType] = React.useState(TransactionType.NONE);
    const [formTransactionAttrs, setFormTransactionAttrs] = React.useState(emptyTransaction);
    const [showEditForm, setShowEditForm] = React.useState(true);

    const onTransactionTypeChoice = (event) => {
        event.preventDefault();

        const chosenTxnType: TransactionType = TransactionType[event.detail.selectedOption.label];
        setFormTransactionType(chosenTxnType);

        const transaction = TransactionAttributes.updateValues(chosenTxnType, formTransactionAttrs);
        setFormTransactionAttrs(transaction);
    }

    const onTransactionTypeRefresh = (event) => {
        event.preventDefault();

        setFormTransactionType(TransactionType.NONE);
    }

    function onFormSubmitForEdit(props, event) {
        event.preventDefault();

        // set updated values for fields
        let newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
        setFormTransactionAttrs(newState);

        props.onTransactionInput(newState);
    }

    function onFormSubmitForDelete(props, event) {
        event.preventDefault();

        const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
        newState.setValid(false);
        setFormTransactionAttrs(newState);

        props.onTransactionInput(newState);
    }

    function onCancelButtonClick(props, event) {
        event.preventDefault();

        // no update to any agreement snapshot
        const newState = emptyTransaction;
        setFormTransactionAttrs(newState);

        props.onTransactionInput(newState);
    }

    return (
        <SpaceBetween direction='vertical' size='xl'>
            <Container>
                <RadioGroup
                    onChange={({ detail }) => {
                        setShowEditForm(RadioButtonOptions.ADD_EDIT_OPERATION.value == detail.value);
                        setFormTransactionAttrs(emptyTransaction);
                        setFormTransactionType(TransactionType.NONE);
                    }}
                    value={showEditForm ? RadioButtonOptions.ADD_EDIT_OPERATION.value: RadioButtonOptions.DELETE_OPERATION.value}
                    items={RadioButtonOptions.EDIT_OR_DELETE_OPERATIONS}
                />
            </Container>
            <Container>
                {showEditForm && <form onSubmit={event => onFormSubmitForEdit(props, event)}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={event => onCancelButtonClick(props, event)}> Cancel </Button>
                                <Button variant="primary" disabled={!TransactionAttributes.areAllAttributesPresent(formTransactionAttrs)}>Submit</Button>
                            </SpaceBetween>
                        }
                        header={
                            <Header
                                variant="h1"
                                description="Add specific attributes of the transactions that should be used for simulation. You can find
                        more documentation in User Guide (see Resources panel to the right)"
                            >
                                Transaction Details
                            </Header>
                        }
                    >
                        <Container>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField
                                    label="Transaction Type"
                                    description={"Type of business transaction depending upon source and destination of inventory movement"}
                                    constraintText={"Only Customer sale related Transactions CSI and CRI are supported for now"}
                                    secondaryControl={<Button iconName="refresh" onClick={onTransactionTypeRefresh} />}
                                >
                                    <Select
                                        options={DropDownOptions.TRANSACTION_TYPES}
                                        selectedOption={DropDownOptions.toOption(DropDownOptions.TRANSACTION_TYPES, formTransactionType ?? null)}
                                        onChange={onTransactionTypeChoice}
                                    />
                                </FormField>
                                <FormField
                                    label="Transaction Id"
                                    description={"A unique identifier for a particular transaction. e.g. Customer Shipment Item id for CSI"}
                                    constraintText={"Numeric values are usually expected here, but any alphanumeric value is allowed"}
                                >
                                    <Input
                                        value={formTransactionAttrs?.getTransactionIdentifier()?.id?.toString() ?? ''}
                                        placeholder={"(Required) Alpha-Numeric."}
                                        inputMode='text'
                                        disabled={TransactionType.NONE === formTransactionType}
                                        onChange={event => {
                                            const newTransactionId: TransactionIdentifier = TransactionAttributes.createTransactionIdentifier(formTransactionType, event.detail.value);
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            newState.setTransactionIdentifier(newTransactionId);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label="Inventory Quantity"
                                    description={"Total number of units of inventory"}
                                    constraintText={"Positive integer values only"}
                                >
                                    <Input
                                        value={formTransactionAttrs.getQuantity()?.toString() ?? ''}
                                        placeholder={"(Required) Positive integer values."}
                                        inputMode='numeric'
                                        disabled={TransactionType.NONE === formTransactionType}
                                        onChange={event => {
                                            const newQuantity: number = +event.detail.value;
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            newState.setQuantity(newQuantity);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label="PCOGS"
                                    description={"The Purchase Cost of Goods for the inventory. This money field would be used to apply the calc rate terms of transaction to calculate VFCC"}
                                    constraintText={"Money value"}
                                >
                                    <ColumnLayout columns={2}>
                                        <Input
                                            controlId="currency"
                                            value={formTransactionAttrs?.getPCogs()?.currency.toString() ?? ''}
                                            placeholder={"ISO4217 currency value, e.g. USD for US Dollars"}
                                            inputMode='text'
                                            disabled={TransactionType.NONE === formTransactionType}
                                            onChange={event => {
                                                const newCurrency: string = event.detail.value;
                                                const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                                let pcogs = newState.getPCogs() ?? TransactionAttributes.defaultPcogs;
                                                pcogs.currency = newCurrency;
                                                newState.setPcogs(pcogs);
                                                setFormTransactionAttrs(newState);
                                            }}
                                        />
                                        <Input
                                            controlId="amount"
                                            value={formTransactionAttrs?.getPCogs()?.amount.toString() ?? ''}
                                            placeholder={"Decimal monetary value, e.g. 10.05"}
                                            type='number'
                                            disabled={TransactionType.NONE === formTransactionType}
                                            onChange={event => {
                                                const newAmount: number = +event.detail.value;
                                                const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                                let pcogs = newState.getPCogs() ?? TransactionAttributes.defaultPcogs;
                                                pcogs.amount = newAmount;
                                                newState.setPcogs(pcogs);
                                                setFormTransactionAttrs(newState);
                                            }}
                                        />
                                    </ColumnLayout>
                                </FormField>
                                <FormField
                                    label="Inventory Product Group Id"
                                    description={"The product group id of the inventory movement"}
                                    constraintText={"Positive integer values only"}
                                >
                                    <Input
                                        value={formTransactionAttrs.getProductGroupId()?.toString() ?? ''}
                                        placeholder={"(Required) Positive integer values."}
                                        inputMode='numeric'
                                        disabled={TransactionType.NONE === formTransactionType}
                                        onChange={event => {
                                            const newPg: number = +event.detail.value;
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            newState.setProductGroupId(newPg);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label="ASIN Marketplace Id"
                                    description={"The marketplace id of the ASIN, e.g. customer sale marketplace for shipment"}
                                    constraintText={"Positive integer values only"}
                                >
                                    <Input
                                        value={formTransactionAttrs.getAsinMarketplaceId()?.toString() ?? ''}
                                        placeholder={"(Required) Positive integer values."}
                                        inputMode='numeric'
                                        disabled={TransactionType.NONE === formTransactionType}
                                        onChange={event => {
                                            const newMkt: number = +event.detail.value;
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            newState.setAsinMarketplaceId(newMkt);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label="Warehouse Id"
                                    description={"The warehouse id for the inventory event, e.g. warehouse from where item was shipped"}
                                    constraintText={"4 digit warehouse code, which is alphanumeric"}
                                >
                                    <Input
                                        value={TransactionAttributes.getFirstWarehouseAttribute(formTransactionAttrs)?.toString() ?? ''}
                                        placeholder={"(Required) alphanumeric."}
                                        inputMode='text'
                                        disabled={TransactionType.NONE === formTransactionType || !TransactionAttributes.doesTransactionRequireOneWarehouseId(formTransactionType)}
                                        ariaRequired={true}
                                        onChange={event => {
                                            const warehouseId: string = event.detail.value;
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            TransactionAttributes.setFirstWarehouseAttribute(warehouseId, newState);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>

                    </Form>
                </form>
                }
                {!showEditForm && <form onSubmit={event => onFormSubmitForDelete(props, event)}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={event => onCancelButtonClick(props, event)}> Cancel </Button>
                                <Button variant="primary" disabled={TransactionType.NONE === formTransactionType}>Submit</Button>
                            </SpaceBetween>
                        }
                        header={
                            <Header
                                variant="h1"
                                description="Transaction which should be invalidated for simulation. You can find
                        more documentation in User Guide (see Resources panel to the right)"
                            >
                                Transaction Details
                            </Header>
                        }
                    >
                        <Container>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField
                                    label="Transaction Type"
                                    description={"Type of business transaction depending upon source and destination of inventory movement"}
                                    constraintText={"Only Customer sale related Transactions CSI and CRI are supported for now"}
                                    secondaryControl={<Button iconName="refresh" onClick={onTransactionTypeRefresh} />}
                                >
                                    <Select
                                        options={DropDownOptions.TRANSACTION_TYPES}
                                        selectedOption={DropDownOptions.toOption(DropDownOptions.TRANSACTION_TYPES, formTransactionType ?? null)}
                                        onChange={onTransactionTypeChoice}
                                    />
                                </FormField>
                                <FormField
                                    label="Transaction Id"
                                    description={"A unique identifier for a particular transaction. e.g. Customer Shipment Item id for CSI"}
                                    constraintText={"Numeric values are usually expected here, but any alphanumeric value is allowed"}
                                >
                                    <Input
                                        value={formTransactionAttrs.getTransactionIdentifier()?.id?.toString() ?? ''}
                                        placeholder={"(Required) Alpha-Numeric."}
                                        inputMode='text'
                                        disabled={TransactionType.NONE === formTransactionType}
                                        onChange={event => {
                                            const newTransactionId: TransactionIdentifier = TransactionAttributes.createTransactionIdentifier(formTransactionType, event.detail.value);
                                            const newState = TransactionAttributes.updateValues(formTransactionType, formTransactionAttrs);
                                            newState.setTransactionIdentifier(newTransactionId);
                                            setFormTransactionAttrs(newState);
                                        }}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                    </Form>
                </form>
                }
            </Container>
        </SpaceBetween>
    );
}

