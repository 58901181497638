import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import * as paths from '../../util/paths';
import {isFromAccountingTeam, isFromTechTeam} from '../../auth/UserPermissions';

// The main content area of the App layout
export default function HomepageContent() {
    return (
        <Box>
            <SpaceBetween direction='vertical' size='xl'>
                <Container
                    header={
                        <Header variant="h1" headingTagOverride="h2">
                            Features
                        </Header>
                    }
                >
                    <SpaceBetween direction='vertical' size='l'>
                        {(isFromTechTeam() || isFromAccountingTeam()) &&
                            <SpaceBetween direction='vertical' size='l'>
                                <ColumnLayout columns={2}>
                                    <Container header={<Header> Flare and USL BI Resources </Header>}>
                                        <div>
                                            <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_HOME)}> Flare Business Activities </Link>
                                        </div>
                                        <div>
                                            <p> Documentation of all business activities supported by ContraCoGS system in Flare/USL,
                                                with details about trigger events and expected accounts to be impacted by the events,
                                                and how are different events related to each other
                                            </p>
                                        </div>

                                        <div>
                                            <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.FLARE_ATTRIBUTES)}> Flare Attributes </Link>
                                        </div>
                                        <div>
                                            <p> Documentation of attributes published to Flare and USL BI, with their business meanings in ContraCoGS system
                                            </p>
                                        </div>

                                        <div>
                                            <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.USL_BI_QUERIES)}> Sample USL BI Queries </Link>
                                        </div>
                                        <div>
                                            <p> A collection of frequently used USL BI queries for analysis of data posted by ContraCoGS system
                                            </p>
                                        </div>
                                    </Container>
                                    <Container header={<Header> Accounting Resources </Header>}>
                                        <div>
                                            <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.JEM_FILES)}> JEM File Repository </Link>
                                        </div>
                                        <div>
                                            <p> Collection of latest known versions of Journal Entry Mapping (JEM) files </p>
                                        </div>

                                        {/*<div>*/}
                                        {/*    <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.JEM_V2)}> JE Mapping Viewer/Creator (PILOT) </Link>*/}
                                        {/*</div>*/}
                                        {/*<div>*/}
                                        {/*    <p> Tool to help with looking up or creating/updating the JE Mappings for Accounting requirements.*/}
                                        {/*        Currently in pilot mode only, not ready for complete production usage.*/}
                                        {/*    </p>*/}
                                        {/*</div>*/}

                                        <div>
                                            <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.JEM_VALIDATOR)}> JE Mapping Validator </Link>
                                        </div>
                                        <div>
                                            <p> Tool to help perform high level validation for JE mappings. It is meant to ensure
                                                that a finite collection of business events are compatible, and can balance each other
                                                over complete lifecycle of an agreement/transaction.
                                            </p>
                                        </div>
                                    </Container>
                                </ColumnLayout>
                            </SpaceBetween>
                        }
                        {isFromTechTeam() &&
                            <SpaceBetween direction='vertical' size='l'>
                                <Container header={<Header> Learning Tools </Header>}>
                                    <div>
                                        <Link variant='primary' fontSize='heading-m' href={paths.getRelativePath(paths.CCOGS_SIMULATOR_PATH)}> Vendor Funding Accrual
                                            Simulator </Link>
                                    </div>
                                    <div>
                                        <p> Tool to help visualize how ContraCoGS system computes the earned vendor funding
                                            based on
                                            agreement terms and received input events. It is meant to be used as a learning tool
                                            for
                                            someone who is new to vendor funding space, and it is little bit technical in nature
                                            (so may take some getting used to)
                                        </p>
                                    </div>
                                </Container>
                            </SpaceBetween>
                        }
                    </SpaceBetween>
                </Container>

                <Container
                    header={
                        <Header variant="h2" headingTagOverride="h2" description="These features require a service backend,
                            which would be IMR intensive as well as take SDE bandwidth to develop and maintain. So they would
                            likely only be added if this website is funded for head-count">
                            Feature TODO list
                        </Header>
                    }
                >
                    <SpaceBetween direction='vertical' size='l'>
                        <Box color="text-body-secondary"> Lookup Transaction/Inventory Details </Box>
                        <Box color="text-body-secondary"> Agreement Booking Details </Box>
                        <Box color="text-body-secondary"> Agreement ABNB Balances </Box>
                        <Box color="text-body-secondary"> Marketplace/Region Totals </Box>
                        <Box color="text-body-secondary"> Daily Processing Status </Box>
                        <Box color="text-body-secondary"> Stuck Transactions View </Box>
                        <Box color="text-body-secondary"> Read Flare Config and present in simple terms </Box>
                        <Box color="text-body-secondary"> Flare Config Update Requests streamlining </Box>
                        <Box color="text-body-secondary"> MJE automation </Box>
                        <Box color="text-body-secondary"> BART + TRAP integration? </Box>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Box>
    );
}
