import React from 'react';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Container from '@amzn/awsui-components-react/polaris/container';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Button from '@amzn/awsui-components-react/polaris/button';
import AgreementForm from './AgreementDetailsForm';
import TransactionForm from './TransactionDetailsForm';
import {AgreementAttributes} from './model/agreement/AgreementAttributes';
import {Transaction} from './model/transaction/Transaction';
import FundingItemEventTable from './FundingItemEventTable';
import * as FundingItemState from './model/fundingItem/FundingItemState';
import * as FundingItemCalculator from './model/fundingItem/FundingItemCalculator';
import * as Utils from '../../util/Utils';
import CopyButton from './common/CopyButton';
import AuditLog from './AuditLog';
import {LogAttributes} from './model/audit/LogAttributes';
import {isFromTechTeam} from '../../auth/UserPermissions';
import PermissionsModal from '../PermissionsModal';

const emptyAgreementAttrsList: AgreementAttributes[] = [];
const emptyTransactionAttrsList: Transaction[] = [];
const emptyFicState: FundingItemState.FundingItemState = FundingItemState.empty;
const emptyAuditLog: LogAttributes[] = [];

export default function SimulatorPage() {
    const [hasAccess] = React.useState(isFromTechTeam());   // FUTURE - this should be updated to allow most people

    const [toolAlertVisible, setToolAlertVisible] = React.useState(true);
    const [clientAlertVisible, setClientAlertVisible] = React.useState(true);

    const [agreementState, setAgreementState] = React.useState(emptyAgreementAttrsList);
    const [transactionState, setTransactionState] = React.useState(emptyTransactionAttrsList);
    const [ficState, setFicState] = React.useState(emptyFicState);
    const [auditLog, setAuditLog] = React.useState(emptyAuditLog);

    const [showAgreementForm, setShowAgreementForm] = React.useState(false);
    const [showTransactionForm, setShowTransactionForm] = React.useState(false);

    const onShowAgreementFormButtonClick = (event) => {
        event.preventDefault();
        setShowAgreementForm(!showAgreementForm);
    }

    const onAgreementFormSubmit = (formAgreementAttrs: AgreementAttributes) => {
        const newFicState = ficState;
        const numFiesBeforeUpdate = ficState.fieList.length;

        const newAuditLogState = auditLog;
        let agreementOperation: string = "Agreement ";

        if (formAgreementAttrs.agreementId) {
            let newAgreementState = agreementState;
            let anyPreviousAgreementIndex = newAgreementState.findIndex((agr: AgreementAttributes) => formAgreementAttrs.agreementId === agr.agreementId);

            if (-1 === anyPreviousAgreementIndex) { // there is no entry for the agreementId, new agreement is created
                if (!formAgreementAttrs.isDisposed) {
                    agreementOperation += "Created";
                    newAgreementState.push(formAgreementAttrs);
                    FundingItemCalculator.newAgreementCreated(formAgreementAttrs, transactionState, newFicState);
                }
                else {
                    agreementOperation += "Disposed Non Existing";
                }
            } else {
                if (!newAgreementState[anyPreviousAgreementIndex].isDisposed) { // if old agreement was not already disposed
                    if (!formAgreementAttrs.isDisposed) {
                        agreementOperation += "Updated";
                        newAgreementState[anyPreviousAgreementIndex] = formAgreementAttrs;
                    } else {
                        agreementOperation += "Disposed";
                        newAgreementState[anyPreviousAgreementIndex].isDisposed = true;
                    }
                    FundingItemCalculator.agreementUpdated(newAgreementState[anyPreviousAgreementIndex], transactionState, newFicState);
                }
                else {
                    agreementOperation += "Disposed Already Disposed";
                }
            }
            setAgreementState(newAgreementState);
            setFicState(newFicState);
        }

        const newLog: LogAttributes = {
            operation: agreementOperation,
            input: Utils.flattenPrintJson(formAgreementAttrs),
            outputFIECount: newFicState.fieList.length - numFiesBeforeUpdate,
        }
        newAuditLogState.push(newLog);

        setAuditLog(newAuditLogState);
        setShowAgreementForm(false);
    }

    const onShowTransactionFormButtonClick = (event) => {
        event.preventDefault();
        setShowTransactionForm(!showTransactionForm);
    }

    const onTransactionFormSubmit = (formTransactionAttrs: Transaction) => {
        const newFicState = ficState;
        const numFiesBeforeUpdate = ficState.fieList.length;

        const newAuditLogState = auditLog;
        let txnOperation: string = "Transaction ";

        if (formTransactionAttrs.getTransactionIdentifier() != null) {
            let newState = transactionState;

            let anyPreviousIndex = newState.findIndex((txn: Transaction) => {
                return (formTransactionAttrs.getTransactionIdentifier()?.id === txn.getTransactionIdentifier()?.id
                    && formTransactionAttrs.getTransactionIdentifier()?.type === txn.getTransactionIdentifier()?.type
                )
            });

            if (-1 === anyPreviousIndex) {
                if (formTransactionAttrs.isValid()) {
                    txnOperation += "Created";
                    newState.push(formTransactionAttrs);
                    FundingItemCalculator.newTransactionCreated(agreementState, formTransactionAttrs, newFicState);
                }
                else {
                    txnOperation += "Invalidated Non Existing";
                }
            } else {
                if (newState[anyPreviousIndex].isValid()) {
                    if (formTransactionAttrs.isValid()) {
                        txnOperation += "Updated";
                        newState[anyPreviousIndex] = formTransactionAttrs;
                    } else {
                        txnOperation += "Invalidated";
                        newState[anyPreviousIndex].setValid(false);
                    }
                    FundingItemCalculator.transactionUpdated(agreementState, newState[anyPreviousIndex], newFicState);
                }
                else {
                    txnOperation += "Invalidated Already Invalidated";
                }
            }
            setTransactionState(newState);
            setFicState(newFicState);
        }

        const newLog: LogAttributes = {
            operation: txnOperation,
            input: Utils.flattenPrintJson(formTransactionAttrs),
            outputFIECount: newFicState.fieList.length - numFiesBeforeUpdate,
        }
        newAuditLogState.push(newLog);

        setAuditLog(newAuditLogState);
        setShowTransactionForm(false);
    }

    return (
        <Box>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess && <SpaceBetween direction='vertical' size='xl'>
                <Alert
                    onDismiss={() => setToolAlertVisible(false)}
                    visible={toolAlertVisible}
                    dismissAriaLabel="Close alert"
                    header="Note"
                    dismissible={true}
                    type='info'
                >
                    This page provides a visualization to how ContraCoGS system computes and books vendor funding based
                    on agreement terms and business transactions. This tool is meant to help the user to understand the
                    basics of ContraCoGS from a tech perspective, and is meant to be a supplement for various documents
                    available already. <br/><br/>

                    Also worth noting here that this tool is not complete -  ContraCoGS is a fairly complicated system,
                    with a lot of advanced scenarios that are difficult to visualize in this tool.
                </Alert>
                <Alert
                    onDismiss={() => setClientAlertVisible(false)}
                    visible={clientAlertVisible}
                    dismissAriaLabel="Close alert"
                    header="Warning"
                    dismissible={true}
                    type='warning'
                >
                    This tool performs all calculations using just React, so all results are stored locally in
                    your browser memory (there are no service calls made to any backend system). As such,
                    <ol>
                        <li>
                            any time the page is "hard" refreshed (using browser refresh option or Ctrl/Cmd + R), all
                            progress would be lost, However navigating to any page within this website using one of the
                            links from menu on the left should retain all input and should be safe to do so.
                        </li>
                        <li>
                            browser may consume higher memory than usual, if there is a lot of data being loaded for
                            visualization
                        </li>
                    </ol>

                </Alert>
                {!showAgreementForm && !showTransactionForm && ficState.fieList.length > 0 && <FundingItemEventTable fieList={ficState.fieList}/>}
                <ColumnLayout columns={2}>
                    <SpaceBetween direction='vertical' size='xl'>
                        <Container
                            header={
                                <Header
                                    variant="h1"
                                    headingTagOverride="h2"
                                    description={`This simulator does not employ any "filtering" for agreements and transactions yet.
                                    Any transactions/agreements being created would match to all existing agreements/transactions 
                                    respectively`}
                                >
                                    Set Up Input to Vendor Funding Earning
                                </Header>
                            }
                        >
                            <SpaceBetween direction='horizontal' size='l'>
                                <Button iconName="edit" onClick={onShowAgreementFormButtonClick} disabled={showTransactionForm}>
                                    Add/Update Agreement Terms
                                </Button>
                                <Button iconName="add-plus" onClick={onShowTransactionFormButtonClick} disabled={showAgreementForm}>
                                    Inject Retail Transaction(s)
                                </Button>
                            </SpaceBetween>
                        </Container>
                        {showAgreementForm && <AgreementForm onAgreementInput={onAgreementFormSubmit} state={agreementState}/>}
                        {showTransactionForm && <TransactionForm onTransactionInput={onTransactionFormSubmit} state={transactionState} />}
                        {!showAgreementForm && !showAgreementForm && auditLog.length > 0 && <AuditLog log={auditLog}/>}
                    </SpaceBetween>
                    <SpaceBetween direction='vertical' size='xl'>
                        {agreementState.length > 0 && <Container
                            header={
                                <Header
                                    variant="h3"
                                    description="Shows the input agreement terms"
                                    counter={`Agreements in current snapshot = ${agreementState.length}`}
                                >
                                    <CopyButton textToCopy={Utils.prettyPrintJson(agreementState)}/> Agreement Snapshot
                                </Header>
                            }
                        >
                            <Textarea
                                value={Utils.prettyPrintJson(agreementState)}
                                placeholder="Shows the input agreement terms"
                                readOnly={true}
                                rows={8}
                            />
                        </Container>
                        }
                        {transactionState.length > 0 && <Container
                            header={
                                <Header
                                    variant="h3"
                                    description="Shows the input events (business transactions)"
                                    counter={`Transactions in current snapshot = ${transactionState.length}`}
                                >
                                    <CopyButton textToCopy={Utils.prettyPrintJson(transactionState)}/> Transaction Snapshot
                                </Header>
                            }
                        >
                            <Textarea
                                value={Utils.prettyPrintJson(transactionState)}
                                placeholder="Shows the input events (business transactions)"
                                readOnly={true}
                                rows={8}
                            />
                        </Container>
                        }
                        {ficState.summaryList.length > 0 && <Container
                            header={
                                <Header
                                    variant="h3"
                                    description="Shows the 'state' for any funding earned, after having processed all events"
                                    counter={`Funding Items in current snapshot = ${transactionState.length}`}
                                >
                                    <CopyButton textToCopy={Utils.prettyPrintJson(ficState.summaryList)}/> Funding Item Snapshot
                                </Header>
                            }
                        >
                            <Textarea
                                value={Utils.prettyPrintJson(ficState.summaryList)}
                                placeholder="Shows the 'state' for any funding earned, after having processed all events"
                                readOnly={true}
                                rows={8}
                            />
                        </Container>
                        }
                    </SpaceBetween>
                </ColumnLayout>
            </SpaceBetween>}
        </Box>
    );
}
