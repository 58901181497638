import * as paths from '../../../util/paths';

export interface FlareAttribute {
    readonly id: string,
    readonly flareName: string,
    readonly uslBiName: string,
    readonly cctMeaning: string,
    readonly category: FlareAttributeCategory,
    readonly docLink?: string,
}

export interface FlareAttributeCategory {
    readonly id: string,
    readonly name: string,
}

const FLASH_ATTRIBUTES: FlareAttributeCategory = {
    id: "1",
    name: "Flash Attributes",
}

const CCT_IDENTIFIERS: FlareAttributeCategory = {
    id: "2",
    name: "CCT Identifiers",
}

const CCT_AGREEMENT_ATTRIBUTES: FlareAttributeCategory = {
    id: "3",
    name: "Agreement Attributes",
}

const TIME_ATTRIBUTES: FlareAttributeCategory = {
    id: "4",
    name: "Time Attributes",
}

const MONEY_ATTRIBUTES: FlareAttributeCategory = {
    id: "5",
    name: "Money Attributes",
}

const MARKETPLACE_ATTRIBUTES: FlareAttributeCategory = {
    id: "6",
    name: "MKT P&L Attributes",
}

const WAREHOUSE_ATTRIBUTES: FlareAttributeCategory = {
    id: "7",
    name: "WH Cap Attributes",
}

const CUSTOMER_SALE_IDENTIFIERS: FlareAttributeCategory = {
    id: "8",
    name: "Customer Sale Identifiers",
}

const CUSTOMER_RETURN_IDENTIFIERS: FlareAttributeCategory = {
    id: "9",
    name: "Customer Return Identifiers",
}

const DISTRIBUTOR_SHIPMENT_ATTRIBUTES: FlareAttributeCategory = {
    id: "10",
    name: "Vendor Receipt Attributes",
}

const DISTRIBUTOR_SHIPMENT_IDENTIFIERS: FlareAttributeCategory = {
    id: "11",
    name: "Vendor Receipt Identifiers",
}

const DISTRIBUTOR_RETURN_IDENTIFIERS: FlareAttributeCategory = {
    id: "12",
    name: "Vendor Return Identifiers",
}

const MISC_ATTRIBUTES: FlareAttributeCategory = {
    id: "13",
    name: "Misc. Attributes",
}

export const ALL_FLARE_ATTRIBUTE_CATEGORIES: FlareAttributeCategory[] = [
    FLASH_ATTRIBUTES,
    CCT_IDENTIFIERS,
    CCT_AGREEMENT_ATTRIBUTES,
    TIME_ATTRIBUTES,
    MONEY_ATTRIBUTES,
    MARKETPLACE_ATTRIBUTES,
    WAREHOUSE_ATTRIBUTES,
    CUSTOMER_SALE_IDENTIFIERS,
    CUSTOMER_RETURN_IDENTIFIERS,
    DISTRIBUTOR_SHIPMENT_ATTRIBUTES,
    DISTRIBUTOR_SHIPMENT_IDENTIFIERS,
    DISTRIBUTOR_RETURN_IDENTIFIERS,
    MISC_ATTRIBUTES,
];

export const FlareAttributeCategoriesUsableInJemLookup: FlareAttributeCategory[] = [
    MARKETPLACE_ATTRIBUTES,
    WAREHOUSE_ATTRIBUTES,
    DISTRIBUTOR_SHIPMENT_ATTRIBUTES,
    MISC_ATTRIBUTES,
];


// TODO - 0.x 7-segment attributes
// TODO - brutus attributes
// TODO - any other attributes like accounting_use_case_name

const BUSINESS_NAMESPACE: FlareAttribute = {
    id: "1.01",
    flareName: "business_namespace",
    uslBiName: "N/A",
    cctMeaning: "The Flare namespace/USL source representing ContraCoGS system.",
    category: FLASH_ATTRIBUTES,
}

const BUSINESS_COUNTRY_CODE: FlareAttribute = {
    id: "1.02",
    flareName: "business_country_code",
    uslBiName: "N/A",
    cctMeaning: "2-letter code for the agreement marketplace",
    category: FLASH_ATTRIBUTES,
}

const BUSINESS_ACTIVITY: FlareAttribute = {
    id: "1.03",
    flareName: "business_activity",
    uslBiName: "business_activity",
    cctMeaning: "Broad business use-case for posting",
    category: FLASH_ATTRIBUTES,
    docLink: paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_LIST),
}

const FIN_COMP_GROUP: FlareAttribute = {
    id: "1.04",
    flareName: "financial_component_group",
    uslBiName: "financial_component_group",
    cctMeaning: "A sub-classification under the business activity, typically used to separate businesses/programs",
    category: FLASH_ATTRIBUTES,
}

const FIN_COMP: FlareAttribute = {
    id: "1.05",
    flareName: "financial_component",
    uslBiName: "?",
    cctMeaning: "The string value representing each journal entry. Flare config written with v2 format have a standardized usage, v1 does not",
    category: FLASH_ATTRIBUTES,
}

const IDEMPOTENCE_ID: FlareAttribute = {
    id: "1.06",
    flareName: "idempotence_id",
    uslBiName: "idempotence_id",
    cctMeaning: "Unique identifier for a single event posted by CCT, common for all JEs for one event. Combination of funding_item_event_id and record_id",
    category: FLASH_ATTRIBUTES,
}

const IDEMPOTENCE_VERSION: FlareAttribute = {
    id: "1.07",
    flareName: "idempotence_version",
    uslBiName: "idempotence_version",
    cctMeaning: "Unique number for each successful POST by CCT for a given idempotence id. Re-publishing same event (idempotence id) increases the version number to count as distinct entry",
    category: FLASH_ATTRIBUTES,
}

const RECORD_ID: FlareAttribute = {
    id: "1.08",
    flareName: "record_id",
    uslBiName: "?",
    cctMeaning: "Numeric id of each JE for the event. CCT sends record_id = 0, and each successive derived record in flare is assigned 0-1, 0-2 etc.",
    category: FLASH_ATTRIBUTES,
}

const FIE_ID: FlareAttribute = {
    id: "2.01",
    flareName: "funding_item_event_id",
    uslBiName: "journal_entry_ext_id",
    cctMeaning: "Unique Id for each event generated in CCT",
    category: CCT_IDENTIFIERS,
}

const FUNDING_ITEM_ID: FlareAttribute = {
    id: "2.02",
    flareName: "funding_item_id",
    uslBiName: "external_ref_item_id_type",
    cctMeaning: "Id for a funding item, which is the smallest grain at which CCT can earn and track inventory",
    category: CCT_IDENTIFIERS,
}

const AGING_ID: FlareAttribute = {
    id: "2.03",
    flareName: "aging_id",
    uslBiName: "external_ref_item_id",
    cctMeaning: "Aging Id for an earning event ties to the billed value, to tracking aging date of balances",
    category: CCT_IDENTIFIERS,
}

const AGREEMENT_ID: FlareAttribute = {
    id: "3.01",
    flareName: "agreement_id",
    uslBiName: "cash_transfer_id",
    cctMeaning: "Unique Id for each CCT agreement or contract with the vendor",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const AGREEMENT_MKT_ID: FlareAttribute = {
    id: "3.02",
    flareName: "agreement_marketplace_id",
    uslBiName: "bgc_merchant_id",
    cctMeaning: "Id for the owning marketplace for the agreement, sub-marketplaces have different marketplace ids within a country (e.g. US Retail vs US PrimeNow)",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const AGREEMENT_TYPE: FlareAttribute = {
    id: "3.03",
    flareName: "booking_agreement_type",
    uslBiName: "carrier_code",
    cctMeaning: "A pass-through value within CCT Booking and Billing systems, computed based on (activity_type, funding_type, agreement_marketplace_id)",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const FUNDING_TYPE: FlareAttribute = {
    id: "3.04",
    flareName: "funding_type",
    uslBiName: "card_number",
    cctMeaning: "Funding Type determines the details of calculation for the agreement",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Agreement_Funding_Types",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const ACTIVITY_TYPE: FlareAttribute = {
    id: "3.05",
    flareName: "activity_type",
    uslBiName: "transaction_ref_type",
    cctMeaning: "Business Intent behind the agreement, pass-through value",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Intent",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const IS_DISCRETIONARY: FlareAttribute = {
    id: "3.06",
    flareName: "is_discretionary",
    uslBiName: "customer_currency_code",
    cctMeaning: "Non-discretionary co-op covers true hard costs",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Q._What.27s_the_difference_between_standard_.28Non-Discretionary.29_Co-op_and_Discretionary_Co-op.3F",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const ACCRUAL_BASE: FlareAttribute = {
    id: "3.07",
    flareName: "accrual_base",
    uslBiName: "payment_auth_signature",
    cctMeaning: "Determines what and when transactions would match to the agreement",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Calculation_Based_On",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const AGREEMENT_PG: FlareAttribute = {
    id: "3.08",
    flareName: "agreement_owning_gl_product_group_id",
    uslBiName: "?",
    cctMeaning: "The product group (PG/product line/GL) that owns the agreement",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const AGREEMENT_SERVICE_CLASSIFICATION: FlareAttribute = {
    id: "3.09",
    flareName: "agreement_service_classification",
    uslBiName: "?",
    cctMeaning: "Service/Non-service classification, based on activity_type",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Intent",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const BILLING_VENDOR_CODE: FlareAttribute = {
    id: "3.10",
    flareName: "billing_vendor_code",
    uslBiName: "?",
    cctMeaning: "Vendor code that will be billed for the agreement",
    docLink: "https://w.amazon.com/index.php/ContraCOGS/ContraCogsTool#Billing_Vendor",
    category: CCT_AGREEMENT_ATTRIBUTES,
}

const TXN_DATE: FlareAttribute = {
    id: "4.01",
    flareName: "transaction_date",
    uslBiName: "transaction_date",
    cctMeaning: "The moment when the financially relevant event should be recorded, taking into consideration late signed and retroactive agreements",
    category: TIME_ATTRIBUTES,
}

const EARNING_DATE: FlareAttribute = {
    id: "4.02",
    flareName: "earning_date",
    uslBiName: "?",
    cctMeaning: "The moment of retail transaction which earns funding, regardless if agreement existed/was signed or not",
    category: TIME_ATTRIBUTES,
}

const ACCT_DATE_PROCESS_NAME: FlareAttribute = {
    id: "4.03",
    flareName: "accounting_date_compute_process_name",
    uslBiName: "?",
    cctMeaning: "Enables CCT to post entries in USL 'Override' period, along with accounting_date_compute_process_rigor. Fixed value = 'Override'",
    docLink: "https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/UslBiReporting/#HTiming:DelaysfromBusinessandSystem",
    category: TIME_ATTRIBUTES,
}

const ACCT_DATE_PROCESS_RIGOR: FlareAttribute = {
    id: "4.04",
    flareName: "accounting_date_compute_process_rigor",
    uslBiName: "?",
    cctMeaning: "Enables CCT to post entries in USL 'Override' period, along with accounting_date_compute_process_name. Fixed value = 'BestEffort'",
    docLink: "https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/UslBiReporting/#HTiming:DelaysfromBusinessandSystem",
    category: TIME_ATTRIBUTES,
}

const OVERRIDE_ACCOUNTING_DATE: FlareAttribute = {
    id: "4.05",
    flareName: "override_accounting_date",
    uslBiName: "?",
    cctMeaning: "The accounting-date CCT expects for event to be entered on, same as transaction_date",
    docLink: "https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/UslBiReporting/#HTiming:DelaysfromBusinessandSystem",
    category: TIME_ATTRIBUTES,
}

const BOOKING_DATE: FlareAttribute = {
    id: "4.06",
    flareName: "x",
    uslBiName: "booking_date_local",
    cctMeaning: "The system moment when the Contra-CoGS system successfully recorded the funding message to FLASH/USL.  Due to system processing delays, this may be later than 'Recognition Date'. Computed by FLASH",
    category: TIME_ATTRIBUTES,
}

const ACCOUNTING_DATE: FlareAttribute = {
    id: "4.07",
    flareName: "x",
    uslBiName: "accounting_date_local",
    cctMeaning: "Official Accounting period of recording. Computed by FLASH",
    category: TIME_ATTRIBUTES,
}

const ORIGINAL_TXN_DATE: FlareAttribute = {
    id: "4.08",
    flareName: "original_transaction_date",
    uslBiName: "?",
    cctMeaning: "The moment when the source-event (whether it earned new funding or not) occurred, regardless of when the agreement was created or signed.",
    category: TIME_ATTRIBUTES,
}

const FUNCTIONAL_AMOUNT: FlareAttribute = {
    id: "5.01",
    flareName: "functional_amount",
    uslBiName: "functional_amount",
    cctMeaning: "Amount value in currency local to the ledger, this value is recorded as the monetary impact to the ledger",
    category: MONEY_ATTRIBUTES,
}

const UNROUNDED_FUNCTIONAL_AMOUNT: FlareAttribute = {
    id: "5.02",
    flareName: "unrounded_functional_value",
    uslBiName: "unrounded_functional_value",
    cctMeaning: "The numerical unrounded amount value in ledger's local currency",
    category: MONEY_ATTRIBUTES,
}

const LOCAL_AMOUNT: FlareAttribute = {
    id: "5.03",
    flareName: "local_amount",
    uslBiName: "local_amount",
    cctMeaning: "Amount value in agreement's billing currency",
    category: MONEY_ATTRIBUTES,
}

const UNROUNDED_LOCAL_AMOUNT: FlareAttribute = {
    id: "5.04",
    flareName: "unrounded_local_value",
    uslBiName: "unrounded_local_value",
    cctMeaning: "The numerical unrounded amount value in agreement billing currency",
    category: MONEY_ATTRIBUTES,
}

const AGREEMENT_ORG_AMOUNT: FlareAttribute = {
    id: "5.05",
    flareName: "agreement_owning_org_amount",
    uslBiName: "?",
    cctMeaning: "Amount value in agreement owning marketplace's local currency",
    category: MONEY_ATTRIBUTES,
}

const UNROUNDED_AGREEMENT_ORG_AMOUNT: FlareAttribute = {
    id: "5.06",
    flareName: "unrounded_agreement_owning_org_amount",
    uslBiName: "?",
    cctMeaning: "The numerical unrounded amount value in agreement owning marketplace's local currency",
    category: MONEY_ATTRIBUTES,
}

const TXN_DESTINATION_AMOUNT: FlareAttribute = {
    id: "5.07",
    flareName: "transaction_destination_amount",
    uslBiName: "?",
    cctMeaning: "Amount value in a transaction's destination entity's local currency. Useful for handling cross-border events",
    category: MONEY_ATTRIBUTES,
}

const UNROUNDED_TXN_DESTINATION_AMOUNT: FlareAttribute = {
    id: "5.08",
    flareName: "unrounded_transaction_destination_amount",
    uslBiName: "?",
    cctMeaning: "The numerical unrounded amount value in transaction destination currency",
    category: MONEY_ATTRIBUTES,
}

const HOLC_AMOUNT: FlareAttribute = {
    id: "5.09",
    flareName: "head_office_ledger_amount",
    uslBiName: "?",
    cctMeaning: "Amount value in Head Office ledger currency. Particularly useful in EU where the concept of head-office and branch is more prevalent",
    category: MONEY_ATTRIBUTES,
}

const UNROUNDED_HOLC_AMOUNT: FlareAttribute = {
    id: "5.10",
    flareName: "unrounded_head_office_ledger_amount",
    uslBiName: "?",
    cctMeaning: "The numerical unrounded amount value in Head Office ledger currency",
    category: MONEY_ATTRIBUTES,
}

const GAIN_OR_LOSS: FlareAttribute = {
    id: "5.11",
    flareName: "gain_or_loss",
    uslBiName: "debit_or_credit",
    cctMeaning: "Used to derive the sign of JE (debit/credit). Zero value is recorded as gain by design convention",
    category: MONEY_ATTRIBUTES,
}

const CSI_PG_ID: FlareAttribute = {
    id: "6.01",
    flareName: "customer_sale_gl_product_group_id",
    uslBiName: "?",
    cctMeaning: "Product group id of the customer sale or marketplace bucket",
    category: MARKETPLACE_ATTRIBUTES,
}

const CSI_MERCHANT_ID: FlareAttribute = {
    id: "6.02",
    flareName: "customer_sale_merchant_customer_id",
    uslBiName: "original_country_cde",
    cctMeaning: "Merchant id of the customer sale or marketplace bucket",
    category: MARKETPLACE_ATTRIBUTES,
}

const CSI_MKT_ID: FlareAttribute = {
    id: "6.03",
    flareName: "customer_sale_marketplace_id",
    uslBiName: "decrypted_marketplace_id",
    cctMeaning: "Marketplace id of the customer sale or marketplace bucket",
    category: MARKETPLACE_ATTRIBUTES,
}

const CSI_MKT_CODE: FlareAttribute = {
    id: "6.04",
    flareName: "customer_sale_marketplace_country_code",
    uslBiName: "marketplace_id",
    cctMeaning: "2-letter code of the marketplace of customer sale or marketplace bucket",
    category: MARKETPLACE_ATTRIBUTES,
}

const CSI_IOG_ID: FlareAttribute = {
    id: "6.05",
    flareName: "inventory_owner_group_id",
    uslBiName: "?",
    cctMeaning: "Inventory owner group id of the customer sale or marketplace bucket",
    category: MARKETPLACE_ATTRIBUTES,
}

const SRC_WAREHOUSE_ID: FlareAttribute = {
    id: "7.01",
    flareName: "source_warehouse_id",
    uslBiName: "?",
    cctMeaning: "Warehouse id of the FC from where the transaction started or warehouse bucket",
    category: WAREHOUSE_ATTRIBUTES,
}

const SRC_WAREHOUSE_COUNTRY_CODE: FlareAttribute = {
    id: "7.02",
    flareName: "source_warehouse_country_code",
    uslBiName: "?",
    cctMeaning: "2-letter code of the warehouse from where the transaction started or warehouse bucket",
    category: WAREHOUSE_ATTRIBUTES,
}

const SRC_WAREHOUSE_PG: FlareAttribute = {
    id: "7.03",
    flareName: "source_gl_product_group_id",
    uslBiName: "?",
    cctMeaning: "Product group id of inventory within the source WH or warehouse bucket",
    category: WAREHOUSE_ATTRIBUTES,
}

const CSI_ID: FlareAttribute = {
    id: "8.01",
    flareName: "ordering_customer_shipment_item_id",
    uslBiName: "gc_shipment_item_id",
    cctMeaning: "Identifier of a customer shipment line item",
    category: CUSTOMER_SALE_IDENTIFIERS,
}

const CSI_ORDER_ID: FlareAttribute = {
    id: "8.02",
    flareName: "ordering_customer_order_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a customer shipment order",
    category: CUSTOMER_SALE_IDENTIFIERS,
}

const CS_ID: FlareAttribute = {
    id: "8.03",
    flareName: "ordering_customer_shipment_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a customer shipment",
    category: CUSTOMER_SALE_IDENTIFIERS,
}

const CRI_ID: FlareAttribute = {
    id: "9.01",
    flareName: "customer_return_item_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a customer return line item",
    category: CUSTOMER_RETURN_IDENTIFIERS,
}

const CR_ID: FlareAttribute = {
    id: "9.02",
    flareName: "customer_return_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a customer return item",
    category: CUSTOMER_RETURN_IDENTIFIERS,
}

const ORIGINAL_WAREHOUSE_ID: FlareAttribute = {
    id: "10.01",
    flareName: "original_warehouse_id",
    uslBiName: "?",
    cctMeaning: "Warehouse Id of the FC where the receipt was originally fulfilled",
    category: DISTRIBUTOR_SHIPMENT_ATTRIBUTES,
}

const ORIGINAL_WAREHOUSE_COUNTRY_CODE: FlareAttribute = {
    id: "10.02",
    flareName: "original_warehouse_country_code",
    uslBiName: "?",
    cctMeaning: "2-letter country code of the warehouse where the receipt was originally fulfilled",
    category: DISTRIBUTOR_SHIPMENT_ATTRIBUTES,
}

const ORIGINAL_WAREHOUSE_IOG: FlareAttribute = {
    id: "10.03",
    flareName: "original_inventory_owner_group_id",
    uslBiName: "?",
    cctMeaning: "Inventory owner group id at the time of original receipt",
    category: DISTRIBUTOR_SHIPMENT_ATTRIBUTES,
}

const DSI_ID: FlareAttribute = {
    id: "11.01",
    flareName: "distributor_shipment_item_id",
    uslBiName: "distributor_shipment_item_id",
    cctMeaning: "Identifier of a distributor shipment line item",
    category: DISTRIBUTOR_SHIPMENT_IDENTIFIERS,
}

const DISTRIBUTOR_ID: FlareAttribute = {
    id: "11.02",
    flareName: "distributor_id",
    uslBiName: "?",
    cctMeaning: "Identifier of the distributor fulfilling receipt",
    category: DISTRIBUTOR_SHIPMENT_IDENTIFIERS,
}

const DSI_ORDER_ID: FlareAttribute = {
    id: "11.03",
    flareName: "ordering_customer_shipment_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a distributor shipment order",
    category: DISTRIBUTOR_SHIPMENT_IDENTIFIERS,
}

const DS_ID: FlareAttribute = {
    id: "11.04",
    flareName: "distributor_shipment_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a distributor shipment",
    category: DISTRIBUTOR_SHIPMENT_IDENTIFIERS,
}

const DRI_ID: FlareAttribute = {
    id: "12.01",
    flareName: "distributor_return_item_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a distributor return line item",
    category: DISTRIBUTOR_RETURN_IDENTIFIERS,
}

const DR_ID: FlareAttribute = {
    id: "12.02",
    flareName: "distributor_return_id",
    uslBiName: "?",
    cctMeaning: "Identifier of a distributor return",
    category: DISTRIBUTOR_RETURN_IDENTIFIERS,
}

const ASIN: FlareAttribute = {
    id: "13.01",
    flareName: "asin",
    uslBiName: "asin",
    cctMeaning: "ASIN identifier for the item involved in the transaction",
    category: MISC_ATTRIBUTES,
}

const IS_DIGITAL: FlareAttribute = {
    id: "13.02",
    flareName: 'digital_product_group',
    uslBiName: "?",
    cctMeaning: "Boolean attribute (Y/N) if the transaction is for a digital inventory or not",
    category: MISC_ATTRIBUTES,
}

const INVENTORY_COST_AUDIT_TYPE: FlareAttribute = {
    id: "13.03",
    flareName: 'inventory_cost_audit_type',
    uslBiName: "inventory_cost_audit_type",
    cctMeaning: "Cost Audit Type of the inventory event received through AFT/AFT-Lite",
    category: MISC_ATTRIBUTES,
}

export const ALL_FLARE_ATTRIBUTES: FlareAttribute[] = [
    BUSINESS_NAMESPACE,
    BUSINESS_COUNTRY_CODE,
    BUSINESS_ACTIVITY,
    FIN_COMP_GROUP,
    FIN_COMP,
    IDEMPOTENCE_ID,
    IDEMPOTENCE_VERSION,
    RECORD_ID,
    FIE_ID,
    FUNDING_ITEM_ID,
    AGING_ID,
    AGREEMENT_ID,
    AGREEMENT_MKT_ID,
    AGREEMENT_TYPE,
    FUNDING_TYPE,
    ACTIVITY_TYPE,
    IS_DISCRETIONARY,
    ACCRUAL_BASE,
    AGREEMENT_PG,
    AGREEMENT_SERVICE_CLASSIFICATION,
    BILLING_VENDOR_CODE,
    TXN_DATE,
    EARNING_DATE,
    ACCT_DATE_PROCESS_NAME,
    ACCT_DATE_PROCESS_RIGOR,
    OVERRIDE_ACCOUNTING_DATE,
    BOOKING_DATE,
    ACCOUNTING_DATE,
    ORIGINAL_TXN_DATE,
    FUNCTIONAL_AMOUNT,
    UNROUNDED_FUNCTIONAL_AMOUNT,
    LOCAL_AMOUNT,
    UNROUNDED_LOCAL_AMOUNT,
    AGREEMENT_ORG_AMOUNT,
    UNROUNDED_AGREEMENT_ORG_AMOUNT,
    TXN_DESTINATION_AMOUNT,
    UNROUNDED_TXN_DESTINATION_AMOUNT,
    HOLC_AMOUNT,
    UNROUNDED_HOLC_AMOUNT,
    GAIN_OR_LOSS,
    CSI_PG_ID,
    CSI_MERCHANT_ID,
    CSI_MKT_ID,
    CSI_MKT_CODE,
    CSI_IOG_ID,
    SRC_WAREHOUSE_ID,
    SRC_WAREHOUSE_COUNTRY_CODE,
    SRC_WAREHOUSE_PG,
    CSI_ID,
    CSI_ORDER_ID,
    CS_ID,
    CRI_ID,
    CR_ID,
    ORIGINAL_WAREHOUSE_ID,
    ORIGINAL_WAREHOUSE_COUNTRY_CODE,
    ORIGINAL_WAREHOUSE_IOG,
    DSI_ID,
    DISTRIBUTOR_ID,
    DSI_ORDER_ID,
    DS_ID,
    DRI_ID,
    DR_ID,
    ASIN,
    IS_DIGITAL,
    INVENTORY_COST_AUDIT_TYPE,
];


export const getFlareAttributeCategoryById = (id: string | undefined) => {
    let returnVal: FlareAttributeCategory | null = null;
    if (undefined != id) {
        ALL_FLARE_ATTRIBUTE_CATEGORIES.forEach(cat => {
            if (cat.id == id) {
                returnVal = cat;
                return; // break loop
            }
        })
    }
    return returnVal;
}

export const getFlareAttributesByCategory = (categoryName: string) => {
    let returnVal: FlareAttribute[] = [];

    ALL_FLARE_ATTRIBUTES.forEach(attribute => {
        if (categoryName == attribute.category.name) {
            returnVal.push(attribute);
        }
    })

    return returnVal;
}