import {Route, Switch} from 'react-router-dom';
import HomepageContent from './HomePageContent';
import * as paths from '../../util/paths';
import SimulatorPage from '../simulator/SimulatorPage';
import JEMFiles from '../je_mapping/repo/JEMFiles';
import JEMValidator from '../je_mapping/v2/validator/JEMValidator';
import FlareBusinessActivitiesEnumeration from '../flare_usl_bi/business_activities/FlareBusinessActivitiesEnumeration';
import FlareBusinessActivitiesRelation from '../flare_usl_bi/business_activities/FlareBusinessActivitiesRelation';
import FlareBusinessActivitiesHome from '../flare_usl_bi/business_activities/FlareBusinessActivitiesHome';
import FlareAttributesEnumeration from '../flare_usl_bi/translator/FlareAttributesEnumeration';
import UslBIQueries from '../flare_usl_bi/queries/UslBIQueries';
import JournalEntryMappingHome from '../je_mapping/v2/JournalEntryMappingHome';
import JEMappingCreator from '../je_mapping/v2/creator/JEMappingCreator';
import JEMappingViewer from '../je_mapping/v2/viewer/JEMappingViewer';
import FieFetcher from '../dev_tools/fie_fetcher/FieFetcher';

export default function Routing () {

    return (
        <Switch>
            <Route exact path={paths.ROOT} component={HomepageContent} />

            <Route exact path={paths.CCOGS_SIMULATOR_PATH} component={SimulatorPage} />
            <Route exact path={paths.FETCH_FIE_PATH} component={FieFetcher} />

            <Route exact path={paths.JEM_FILES} component={JEMFiles} />
            <Route exact path={paths.JEM_VALIDATOR} component={JEMValidator} />
            {/*<Route exact path={paths.JEM_V2} component={JournalEntryMappingHome} />*/}
            <Route exact path={paths.JEM_CREATOR} component={JEMappingCreator} />
            <Route exact path={paths.JEM_VIEWER} component={JEMappingViewer} />

            <Route exact path={paths.FLARE_BIZ_ACTIVITIES_HOME} component={FlareBusinessActivitiesHome} />
            <Route exact path={paths.FLARE_BIZ_ACTIVITIES_LIST} component={FlareBusinessActivitiesEnumeration} />
            <Route exact path={paths.FLARE_BIZ_ACTIVITIES_RELATION} component={FlareBusinessActivitiesRelation} />
            <Route exact path={paths.FLARE_ATTRIBUTES} component={FlareAttributesEnumeration} />
            <Route exact path={paths.USL_BI_QUERIES} component={UslBIQueries} />
        </Switch>
    );
}

