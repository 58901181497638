import React from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {isFromAccountingTeam} from '../../../../auth/UserPermissions';
import PermissionsModal from '../../../PermissionsModal';
import {BusinessActivity} from '../../../flare_usl_bi/business_activities/BusinessActivity';
import JEMCreatorHelper, {JEMCreateNewOrEditExisting, OperationType} from './JEMCreatorHelper';
import * as jemHelper from '../JEMHelper';
import {AlertPilot, JEMAccrualBasis, JEMBusinessActivityDropdown, JEMBusinessCountryCode} from '../JEMHelper';
import Container from '@amzn/awsui-components-react/polaris/container';
import JEMViewerHelper from '../viewer/JEMViewerHelper';

export default function JEMappingCreator (props) {
    const [hasAdminAccess] = React.useState(isFromAccountingTeam());    // FIXME - SDEs should not have admin access, may want an override type thing here in case of emergencies

    const [operationType, setOperationType] = React.useState(props.location?.state?.operationType ?? OperationType.NULL);
    const [businessCountry, setBusinessCountry] = React.useState(props.location?.state?.businessCountry ?? jemHelper.nullBusinessCountry);
    const [accrualBasis, setAccrualBasis] = React.useState(props.location?.state?.accrualBasis ?? jemHelper.nullAccrualBasis);
    const [dropdownItems, setDropdownItems] = React.useState(props.location?.state?.dropdownItems ?? [] as BusinessActivity[]);
    const [businessActivity, setBusinessActivity] = React.useState(props.location?.state?.businessActivity ?? jemHelper.nullBusinessActivity);
    const [useCaseName] = React.useState(props.location?.state?.useCaseName ?? "");

    const RenderForCreateOrEdit = () => {
        switch (operationType) {
            case (OperationType.CREATE_NEW): {
                return (
                    <SpaceBetween direction='vertical' size='xl'>
                        <JEMBusinessCountryCode businessCountry={businessCountry} setBusinessCountry={setBusinessCountry}/>
                        {null != businessCountry && <JEMAccrualBasis accrualBasis={accrualBasis} setAccrualBasis={setAccrualBasis} setDropdownItems={setDropdownItems} setBusinessActivity={setBusinessActivity}/>}
                        {null != accrualBasis && <JEMBusinessActivityDropdown dropdownItems={dropdownItems} businessActivity={businessActivity} setBusinessActivity={setBusinessActivity}/>}
                        {null != businessActivity && <JEMCreatorHelper operationType={operationType} businessCountry={businessCountry} businessActivity={businessActivity}/>}
                    </SpaceBetween>
                );
            }
            case (OperationType.CREATE_BY_COPY): {
                return (
                    <SpaceBetween direction='vertical' size='xl'>
                        <Container>
                            COMING SOON!
                        </Container>
                    </SpaceBetween>
                );
            }
            case (OperationType.EDIT_EXISTING): {
                return (
                    <SpaceBetween direction='vertical' size='xl'>
                        <JEMBusinessCountryCode businessCountry={businessCountry} setBusinessCountry={setBusinessCountry}/>
                        {null != businessCountry && <JEMAccrualBasis accrualBasis={accrualBasis} setAccrualBasis={setAccrualBasis} setDropdownItems={setDropdownItems} setBusinessActivity={setBusinessActivity}/>}
                        {null != accrualBasis && <JEMBusinessActivityDropdown dropdownItems={dropdownItems} businessActivity={businessActivity} setBusinessActivity={setBusinessActivity}/>}
                        {null != businessActivity && <JEMViewerHelper hasAdminAccess={hasAdminAccess} businessCountry={businessCountry} accrualBasis={accrualBasis} dropdownItems={dropdownItems} businessActivity={businessActivity}/>}
                        {null != businessActivity && <JEMCreatorHelper operationType={operationType} businessCountry={businessCountry} businessActivity={businessActivity} useCaseName={useCaseName}/>}
                    </SpaceBetween>
                );
            }
        }

    }

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAdminAccess && <PermissionsModal hasPageAccess={hasAdminAccess}/>}
            {hasAdminAccess && <AlertPilot />}
            {hasAdminAccess && <JEMCreateNewOrEditExisting operationType={operationType} setOperationType={setOperationType} />}
            {hasAdminAccess && (OperationType.NULL != operationType) && RenderForCreateOrEdit()}
        </SpaceBetween>
    );
}