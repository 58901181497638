import Box from '@amzn/awsui-components-react/polaris/box';
import {DarkMode} from "../util/dark-mode";
import cct_booking_logo from "./../assets/header-logo.png"
import Link from '@amzn/awsui-components-react/polaris/link';
import React from "react";
import {getUserName} from "../auth/MidwayJwtToken";
import useLocalStorage from "../util/use-local-storage";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import './../styles/AppHeader.scss'
import './../styles/styles.scss'
import * as paths from '../util/paths';

function DarkModeToggle() {
    const [isDarkMode, setDarkMode] = useLocalStorage('is_dark_mode', true);

    const toggleTheme = () => {
        setDarkMode(prevValue => !prevValue)
    }

    return (
        <div>
            <Box>
                <Toggle
                    checked={isDarkMode}
                    onChange={toggleTheme}
                >
                    <span style={{color: "white", "whiteSpace": "nowrap"}}> Dark Mode </span>
                </Toggle>
            </Box>
        </div>
    );
}

export default function AppHeader() {
    return (
        <DarkMode>
            <header id='app-header'>
                <ul className='menu-list'>
                    <li className='logo'>
                        <Link href={paths.ROOT}>
                            <img src={cct_booking_logo} alt="ContraCoGS Booking Logo" />
                        </Link>
                    </li>
                    <li className='banner'>
                        <div className="custom-home-text-inverted">
                            <Box variant="h5" fontWeight="bold" color="inherit">
                                ContraCoGS Booking Website (Alpha Release)
                            </Box>
                            <Box variant="p" fontWeight="light" color="inherit">
                                Website in alpha release, report any bugs &nbsp;
                                <Link className="custom-home-text-secondary" href={paths.BUG_REPORT_SIM_FOLDER} external>
                                     here
                                </Link>
                            </Box>
                        </div>
                    </li>
                    <li className='dark-mode-toggle'>
                        <DarkModeToggle />
                    </li>
                    <li className='user-photo'>
                        <Link href='#'>
                            <img src={`${paths.BADGE_PHOTO_PATH}?uid=${getUserName()}`} alt={`${getUserName()}`} />
                        </Link>
                    </li>
                </ul>
            </header>
        </DarkMode>
    );
}
