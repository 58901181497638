import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import AppHeader from "./AppHeader";
import Navigation from "./home/Navigation";
import Tools from "./home/Tools";
import {DarkMode} from "../util/dark-mode";
import "./../styles/App.scss";
import Routing from './home/Routing';

export default function App() {
    return (
        <DarkMode>
            <div className='awsui'>
                <AppHeader />
                <AppLayout
                    content={<Routing />}
                    headerSelector='#app-header'
                    disableBodyScroll={true}
                    navigation={<Navigation />}
                    navigationWidth={350}
                    tools={<Tools />}
                    toolsWidth={200}
                />
            </div>
        </DarkMode>
    );
}

