import React from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import Input from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Container from '@amzn/awsui-components-react/polaris/container';
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import * as AgreementAttributes from './model/agreement/AgreementAttributes';
import * as AccrualBasis from './model/agreement/AccrualBasis';
import * as FundingType from './model/agreement/FundingType';
import * as DropDownOptions from './common/DropDownOptions';
import {CalcType} from './model/agreement/CalcTerms';
import * as RadioButtonOptions from './common/RadioButtonOptions';

export default function AgreementForm(props) {

    const [formAgreementAttrs, setFormAgreementAttrs] = React.useState(AgreementAttributes.emptyAgreement);
    const [showEditForm, setShowEditForm] = React.useState(true);

    function onFormSubmitForEdit(props, event) {
        event.preventDefault();

        let lastAgreementId: number = Math.max(0, Math.max.apply(Math, props.state.map(agr => agr.agreementId)));

        // set default values for fields
        let newState = { ... formAgreementAttrs };
        newState = AgreementAttributes.createAgreementUsingDefaultWithoutAgreementId(newState);
        newState.agreementId = null == formAgreementAttrs.agreementId ? lastAgreementId+1 : formAgreementAttrs.agreementId;
        setFormAgreementAttrs(newState);

        props.onAgreementInput(newState);
    }

    function onFormSubmitForDelete(props, event) {
        event.preventDefault();

        const newState = { ... formAgreementAttrs };
        newState.agreementId = formAgreementAttrs.agreementId ?? null;
        newState.isDisposed = true;
        setFormAgreementAttrs(newState);

        props.onAgreementInput(newState);
    }

    function onCancelButtonClick(props, event) {
        event.preventDefault();

        // no update to any agreement snapshot
        const newState = {} as AgreementAttributes.AgreementAttributes;
        setFormAgreementAttrs(newState);

        props.onAgreementInput(newState);
    }

    return (
        <SpaceBetween direction='vertical' size='xl'>
            <Container>
                <RadioGroup
                    onChange={({ detail }) => {
                        setShowEditForm(RadioButtonOptions.ADD_EDIT_OPERATION.value == detail.value);
                        setFormAgreementAttrs(AgreementAttributes.emptyAgreement);
                    }}
                    value={showEditForm ? RadioButtonOptions.ADD_EDIT_OPERATION.value: RadioButtonOptions.DELETE_OPERATION.value}
                    items={RadioButtonOptions.EDIT_OR_DELETE_OPERATIONS}
                />
            </Container>
            <Container>
                {showEditForm && <form onSubmit={event => onFormSubmitForEdit(props, event)}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={event => onCancelButtonClick(props, event)}> Cancel </Button>
                                <Button variant="primary">Submit</Button>
                            </SpaceBetween>
                        }
                        header={
                            <Header
                                variant="h1"
                                description="Add specific terms of the agreement that should be used for simulation. You can find
                        more documentation in User Guide (see Resources panel to the right)"
                            >
                                Agreement Terms
                            </Header>
                        }
                    >
                        <Container>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField
                                    label="Agreement Id"
                                    description={"Only needed when updating terms of existing agreement data"}
                                    constraintText={"Numeric values only"}
                                >
                                    <Input
                                        value={formAgreementAttrs?.agreementId?.toString() ?? ''}
                                        placeholder={"(Optional) Numeric. If no value is provided, a new agreement id would be generated."}
                                        type='number'
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.agreementId = +event.detail.value;
                                            setFormAgreementAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label={"Accrual Type"}
                                    description={"A broad classification of what type of transactions can match to the agreement."}
                                    constraintText={"Only Sales Based is supported for now"}
                                >
                                    <Select
                                        options={DropDownOptions.ACCRUAL_BASIS}
                                        // selectedOption={toOption(ACCRUAL_BASIS, formAgreementAttrs.accrualBasis ?? null)}
                                        selectedOption={DropDownOptions.SALES_BASED}
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.accrualBasis = AccrualBasis.fromLabel(event.detail.selectedOption.label);
                                            setFormAgreementAttrs(newState);
                                        }}
                                        disabled={true}     // disabled because there is support for only one option
                                    />
                                </FormField>
                                <FormField
                                    label={"Funding Type"}
                                    description={"Funding Type defines how the agreement should accrue. "}
                                    constraintText={"Only Accrual is supported for now"}
                                >
                                    <Select
                                        options={DropDownOptions.FUNDING_TYPES}
                                        // selectedOption={toOption(FUNDING_TYPES, formAgreementAttrs.fundingType ?? null)}
                                        selectedOption={DropDownOptions.ACCRUAL}
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.fundingType = FundingType.fromLabel(event.detail.selectedOption.label);
                                            setFormAgreementAttrs(newState);
                                        }}
                                        disabled={true}     // disabled because there is support for only one option
                                    />
                                </FormField>
                                <FormField
                                    label="Accrual Rate"
                                    description={"The accrual rate which would be applied to transaction quantity/PCOGS to calculate VFCC"}
                                    constraintText={"For % or per_unit metrics, rate values should be numeric. For lumpsum metric, currency should be 3 digit ISO code"}
                                >
                                    <ColumnLayout columns={2}>
                                        <Select
                                            controlId="calc-type"
                                            options={DropDownOptions.CALC_TERMS}
                                            selectedOption={DropDownOptions.toOption(DropDownOptions.CALC_TERMS, formAgreementAttrs.calcTerm?.calcType ?? null)}
                                            onChange={event => {
                                                const newState = {...formAgreementAttrs};
                                                // @ts-ignore
                                                const chosenCalcType = CalcType[event.detail.selectedOption.label];
                                                let newTerms = newState.calcTerm;
                                                if (null == newTerms) {
                                                    newTerms = AgreementAttributes.defaultAgreementTerms.calcTerm;
                                                }
                                                // @ts-ignore - default terms has non null values
                                                newTerms.calcType = chosenCalcType;
                                                newState.calcTerm = newTerms;
                                                setFormAgreementAttrs(newState);
                                            }}
                                        />
                                        {/*FUTURE - annotations here would be useful to show the details for each field */}
                                        {formAgreementAttrs.calcTerm?.calcType && formAgreementAttrs.calcTerm?.calcType != CalcType.LUMP_SUM && <Input
                                            controlId="calc-rate"
                                            value={formAgreementAttrs?.calcTerm?.calcRate.toString() ?? ''}
                                            placeholder={"Numerical value only, e.g. 1 for 1% rate"}
                                            type='number'
                                            onChange={event => {
                                                const newState = {...formAgreementAttrs};
                                                const chosenRate = +event.detail.value;
                                                let newTerms = newState.calcTerm;
                                                if (null == newTerms) {
                                                    newTerms = AgreementAttributes.defaultAgreementTerms.calcTerm;
                                                }
                                                // @ts-ignore - default terms has non null values
                                                newTerms.calcRate = chosenRate;
                                                setFormAgreementAttrs(newState);
                                            }}
                                        />
                                        }
                                        {formAgreementAttrs.calcTerm?.calcType == CalcType.LUMP_SUM && <Input
                                            controlId="lumpsum-currency"
                                            value={formAgreementAttrs?.currency ?? ''}
                                            placeholder={"3 letter currency code for lumpsum amount e.g. USD"}
                                            type='text'
                                            onChange={event => {
                                                const newState = {...formAgreementAttrs};
                                                newState.currency = event.detail.value;
                                                setFormAgreementAttrs(newState);
                                            }}
                                        />}
                                    </ColumnLayout>
                                </FormField>
                                <FormField
                                    label="Owning Marketplace Id"
                                    description={"Marketplace id of the owning marketplace"}
                                    constraintText={"Numeric values only"}
                                >
                                    <Input
                                        value={formAgreementAttrs?.owningMarketplaceId?.toString() ?? ''}
                                        placeholder={"(Optional) Numeric. If missing, id=1 would be chosen which is US Retail mkt"}
                                        type='number'
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.owningMarketplaceId = +event.detail.value;
                                            setFormAgreementAttrs(newState);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    label="Owning Product Group Id"
                                    description={"The GL product group id of the owning product line of the agreement"}
                                    constraintText={"Numeric values only"}
                                >
                                    <Input
                                        value={formAgreementAttrs?.owningProductGroupId?.toString() ?? ''}
                                        placeholder={"(Optional) Numeric. If missing, id=14 would be chosen which is GL Books"}
                                        type='number'
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.owningProductGroupId = +event.detail.value;
                                            setFormAgreementAttrs(newState);
                                        }}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                    </Form>
                </form>
                }
                {!showEditForm && <form onSubmit={event => onFormSubmitForDelete(props, event)}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={event => onCancelButtonClick(props, event)}> Cancel </Button>
                                <Button variant="primary">Submit</Button>
                            </SpaceBetween>
                        }
                        header={
                            <Header
                                variant="h1"
                                description="Agreement which should be disposed for simulation. You can find
                        more documentation in User Guide (see Resources panel to the right)"
                            >
                                Agreement Terms
                            </Header>
                        }
                    >
                        <Container>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField
                                    label="Agreement Id"
                                    description={"AgreementId that should be disposed"}
                                    constraintText={"Numeric values only, should exist in snapshot and not already be disposed for disposal to happen"}
                                >
                                    <Input
                                        value={formAgreementAttrs?.agreementId?.toString() ?? ''}
                                        placeholder={"Numeric. If no value is provided, there would be no update"}
                                        type='number'
                                        onChange={event => {
                                            const newState = {...formAgreementAttrs};
                                            newState.agreementId = +event.detail.value;
                                            setFormAgreementAttrs(newState);
                                        }}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                    </Form>
                </form>
                }
            </Container>
        </SpaceBetween>
    );
}