import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {isFromAccountingTeam, isFromTechTeam} from '../../../../auth/UserPermissions';
import PermissionsModal from '../../../PermissionsModal';

export default function JEMValidator () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
                <SpaceBetween direction='vertical' size='xl'>
                    <Container>
                        COMING SOON!
                    </Container>
                </SpaceBetween>
            }
        </SpaceBetween>
    );
}