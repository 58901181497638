import {OptionDefinition} from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as AccrualBasis from '../model/agreement/AccrualBasis';
import * as FundingType from '../model/agreement/FundingType';
import {CalcType} from '../model/agreement/CalcTerms';
import {TransactionType} from '../model/transaction/TransactionType';

export function toOption(options: OptionDefinition[], labelValue) {
    if (null == labelValue) {
        return null;
    }

    const index = options.findIndex((option) => {
        return labelValue === option.label;
    });

    if (-1 === index) {
        return null;
    }

    return options[index];
}

export const SALES_BASED: OptionDefinition = {
    label: AccrualBasis.SALES_BASED.label,
    labelTag: AccrualBasis.SALES_BASED.id.toString(),
}

export const RECEIPTS_BASED: OptionDefinition = {
    label: AccrualBasis.RECEIPTS_BASED.label,
    labelTag: AccrualBasis.RECEIPTS_BASED.id.toString(),
}

export const LUMP_SUM: OptionDefinition = {
    label: AccrualBasis.LUMSPUM_BASED.label,
    labelTag: AccrualBasis.LUMSPUM_BASED.id.toString(),
}

export const ACCRUAL_BASIS: OptionDefinition[] = [
    SALES_BASED,
    RECEIPTS_BASED,
    LUMP_SUM,
]

export const ACCRUAL: OptionDefinition = {
    label: FundingType.ACCRUAL.label,
    labelTag: FundingType.ACCRUAL.id.toString(),
}

export const FUNDING_TYPES: OptionDefinition[] = [
    ACCRUAL,
]


export const PERCENTAGE_CHARGE: OptionDefinition = {
    label: CalcType.PERCENTAGE_CHARGE,
    labelTag: '1'
}

export const PER_UNIT_CHARGE: OptionDefinition = {
    label: CalcType.PER_UNIT_CHARGE,
    labelTag: '2'
}

export const LUSMPUM_CHARGE: OptionDefinition = {
    label: CalcType.LUMP_SUM,
    labelTag: '3'
}

export const CALC_TERMS: OptionDefinition[] = [
    PERCENTAGE_CHARGE,
    PER_UNIT_CHARGE,
    LUSMPUM_CHARGE,
]

export const CSI_TXN_TYPE: OptionDefinition = {
    label: TransactionType.CSI,
    labelTag: '1',
}

export const CRI_TXN_TYPE: OptionDefinition = {
    label: TransactionType.CRI,
    labelTag: '2',
}

export const TRANSACTION_TYPES: OptionDefinition[] = [
    CSI_TXN_TYPE,
    CRI_TXN_TYPE,
]


