import {Header, HelpPanel, SideNavigation} from '@amzn/awsui-components-react';
import './../../styles/App.scss'
import Link from '@amzn/awsui-components-react/polaris/link';
import * as paths from '../../util/paths';


const HelpSection = () => {
    return (
        <SideNavigation
            className='awsui-util-help-panel'
            items={[
                {
                    type: 'section',
                    text: 'Docs/Videos',
                    items: [
                        {
                            type: 'link',
                            text: 'User Guide',
                            href: paths.USER_GUIDE,
                            external: true,
                        },
                        {
                            type: 'link',
                            text: 'TxBooking',
                            href: paths.TX_BOOKING,
                            external: true,
                        },
                        {
                            type: 'link',
                            text: 'Videos',
                            href: paths.BROADCAST_CHANNEL,
                            external: true,
                        },
                    ],
                },
                { type: 'divider' },
                {
                    type: 'section',
                    text: 'Office Hours',
                    items: [
                        {
                            type: 'link',
                            text: 'General',
                            href: paths.CCT_OFFICE_HOURS,
                            external: true,
                        },
                        {
                            type: 'link',
                            text: 'Accounting/Finance',
                            href: paths.ACCOUNTING_OFFICE_HOURS,
                            external: true,
                        },
                    ],
                },
            ]}
        />
    );
}

const Footer = () => {
    return (
        <div className='awsui-util-help-panel-footer'>
            <Link
                href={paths.REQUEST_A_FEATURE_SIM}
                target='_blank'
                external
            >
                Feature Request
            </Link>
            <br/> <br/>
            <Link
                href={paths.BUG_REPORT_SIM_FOLDER}
                target='_blank'
                external
            >
                Bug Report
            </Link>
        </div>
    );
}

export default function Tools() {
    return (
        <HelpPanel
            header={<Header variant='h2'> Resources </Header>}
            children={<HelpSection/>}
            footer={<Footer/>}
        />
    );
}