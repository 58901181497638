import {RadioGroupProps} from '@amzn/awsui-components-react/polaris/radio-group';

export const ADD_EDIT_OPERATION: RadioGroupProps.RadioButtonDefinition = {
    value: "Add a new agreement/Edit an existing agreement",
    label: "Add/Update",
    description: "Add a new agreement or transaction / Edit an existing agreement or transaction, which would result in earning of new or updated funding",
}

export const DELETE_OPERATION: RadioGroupProps.RadioButtonDefinition = {
    value: "Dispose an existing agreement",
    label: "Dispose/Invalidate",
    description: "Dispose/Invalidate an existing agreement or transaction, which would result in reversal of any funding earned till date for the invalidated item.",
}

export const EDIT_OR_DELETE_OPERATIONS: RadioGroupProps.RadioButtonDefinition[] = [
    ADD_EDIT_OPERATION,
    DELETE_OPERATION,
]