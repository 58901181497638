import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import React from 'react';
import * as paths from '../util/paths';

export default function PermissionsModal(props) {
    return (
        <Modal
            visible={!props.hasPageAccess}
            onDismiss={ redirectToHome }
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={ redirectToHome }> Ok </Button>
                </Box>
            }
            header="Access Denied"
        >
            Only certain people have access to this tool. If you believe this is in error,
            see support options in Resources (to the right)
        </Modal>
    );
}

const redirectToHome = () => {
    // redirect back to root if no permission
    window.location.replace(paths.getRelativePath(paths.ROOT))
}
