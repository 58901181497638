export const ROOT = '/';

export const BADGE_PHOTO_PATH = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/'

// All sub-pages in the website

// Learning Tools
export const CCOGS_SIMULATOR_PATH = '/ccogs-simulator';

// Dev Tools
export const FETCH_FIE_PATH = '/fetch-transaction';

// Accounting tools
export const JEM_FILES = '/journal-entry-mappings';
export const JEM_VALIDATOR = '/jem-validator';
export const JEM_V2 = '/jem-pilot';
export const JEM_CREATOR = '/jem-creator';
export const JEM_VIEWER = '/jem-viewer';

// Flare and USL BI
export const FLARE_BIZ_ACTIVITIES_HOME = '/flare-business-activities';
export const FLARE_BIZ_ACTIVITIES_LIST = '/flare-business-activities/list';
export const FLARE_BIZ_ACTIVITIES_RELATION = '/flare-business-activities/relation';
export const FLARE_ATTRIBUTES = '/flare-attributes';
export const USL_BI_QUERIES = '/usl-bi-queries';

// External links
export const CCT_TOOL = 'https://contra-cogs.aka.amazon.com/';
export const CUCKOO_TOOL = 'https://contra-cogs-cuckoo.intech.amazon.dev/';

export const BUG_REPORT_SIM_FOLDER = 'https://issues.amazon.com/issues/create?assignedFolder=c3ea7ba4-742c-4324-8cdb-fedcbef6adbc';
export const REQUEST_A_FEATURE_SIM = 'https://issues.amazon.com/issues/create?assignedFolder=c6c42c2c-e1a0-430c-86bf-45ce7f0fd763';

export const CCT_OFFICE_HOURS = 'https://w.amazon.com/bin/view/ContraCOGS/OfficeHours/2/';
export const ACCOUNTING_OFFICE_HOURS = 'https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/AccountingAndFinanceOfficeHours/';

export const USER_GUIDE = 'https://w.amazon.com/bin/view/ContraCOGS/Booking/Projects/ContraCogsBookingWebsite/UserGuide/';
export const TX_BOOKING = 'https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/';

export const USL_BI_DATA_LINEAGE = 'https://w.amazon.com/bin/view/FinanceTechnology/KnowledgeServices/ADA/Internal/SOPs/USLBI/Archived/USLBITransactionsDataLineage/';
export const CCT_USL_BI_MODEL = 'https://w.amazon.com/bin/view/ContraCOGS/Bling/Projects/TransactionalBooking/UslModelBusinessActivities';

export const BROADCAST_CHANNEL = 'https://broadcast.amazon.com/channels/29430/playlists/26166';

export const getRelativePath: (absolutePath: string) => string = (absolutePath: string) => {
    return '#' + absolutePath;
}