export interface FundingType {
    id: number,
    label: string
}


export const ACCRUAL: FundingType = {
    id: 1,
    label: 'Accrual'
}

export const STRAIGHT_PAY: FundingType = {
    id: 3,
    label: 'Straight Payment'
}

export const VOLUME_INCENTIVE: FundingType = {
    id: 4,
    label: 'Volume Incentive Rebate'
}

export const PRICE_PROTECTION: FundingType = {
    id: 5,
    label: 'Price Protection'
}

export const FLEX: FundingType = {
    id: 1,
    label: 'Flexible'
}

export const GMM: FundingType = {
    id: 1,
    label: 'Guaranteed Minimum Margin'
}


const FUNDING_TYPES: FundingType[] = [
    ACCRUAL,
    STRAIGHT_PAY,
    VOLUME_INCENTIVE,
    PRICE_PROTECTION,
    FLEX,
    GMM,
];

export const fromLabel = (val: string | undefined | null) => {
    if (!val) {
        return null;
    }
    const index = FUNDING_TYPES.findIndex((basis) => val === basis.label);
    return -1 === index ? null : FUNDING_TYPES[index]
}

export const fromId = (val: number | undefined | null) => {
    if (!val) {
        return null;
    }
    const index = FUNDING_TYPES.findIndex((basis) => val === basis.id);
    return -1 === index ? null : FUNDING_TYPES[index]
}