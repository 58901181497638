import React from 'react';
import {useCollection} from '@amzn/awsui-collection-hooks';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from "@amzn/awsui-components-react/polaris/table";
import {TableProps} from '@amzn/awsui-components-react/polaris/table/interfaces';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {ACCRUAL_BASES, AccrualBasis, BusinessActivity, getAccrualBasisFromId,} from './BusinessActivity';
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';
import {DDB_PAGE_SIZE, getFilterCounterText, TableEmptyState, TableNoMatchState} from '../../../util/TableUtils';
import * as paths from '../../../util/paths';


const nullAccrualBasis: AccrualBasis | null = null;

const TableColumnDefinitions: TableProps.ColumnDefinition<BusinessActivity>[] = [
    {
        id: "serial_number",
        header: "Use Case #",
        cell: (item: BusinessActivity) => item.serialNumber,
        sortingField: "serial_number",
        sortingComparator: (a: BusinessActivity, b:BusinessActivity) => a.serialNumber - b.serialNumber,
    },
    {
        id: "business_activity",
        header: "Business Activity",
        cell: (item: BusinessActivity) => item.name,
    },
    // {
    //     id: "fin_comp_group",
    //     header: "Financial Component Group",
    //     cell: (item: BusinessActivity) => item.financialComponentGroup,
    // },
    {
        id: "accrual_basis",
        header: "Agreement Accrual Basis",
        cell: (item: BusinessActivity) => item.agreementAccrualBasis,
    },
    {
        id: "description",
        header: "Description",
        cell: (item: BusinessActivity) => item.description,
    },
    {
        id: "trigger_event",
        header: "Trigger Event",
        cell: (item: BusinessActivity) => item.triggerEvent,
    },
    {
        id: "fic_state",
        header: "CCT Inventory State before trigger",
        cell: (item: BusinessActivity) => item.ficState,
    },
    {
        id: "debit",
        header: "Debit Account",
        cell: (item: BusinessActivity) => item.debitAccount,
    },
    {
        id: "credit",
        header: "Credit Account",
        cell: (item: BusinessActivity) => item.creditAccount,
    },
];

export const BusinessActivitiesFooter = () => {
    return (
        <Container header={<Header variant='h3'> Legend </Header>}>
            <TextContent>
                <h4> Notations </h4>
                <ul>
                    <li> MKT_A = Agreement marketplace </li>
                    <li> MKT_S = Marketplace of sale </li>
                    <li> MKT_B = Marketplace "bucket", where TxB believes the inventory is currently based on all previous events </li>
                    <li> MKT_D = Marketplace of the warehouse of receipt </li>
                    <li> MKT_R = Marketplace of return / Marketplace of the warehouse of return </li>
                    <li> WH_D = Warehouse of Receipt </li>
                    <li> WH_1 = Warehouse from where the inventory was sold/transferred/returned from </li>
                    <li> WH_2 = Warehouse to where the inventory was received </li>
                    <li> WH_B = Warehouse "bucket", where TxB believes the inventory is currently </li>
                    <li> [IT] = In-Transit "bucket"  </li>
                </ul>

                <h4> Account Numbers </h4>
                The account numbers in table above are based on the general requirements that work for most marketplaces.
                It is possible some of the marketplaces/use cases do not follow the same accounts, either due to
                different requirements or due to incorrect requirements/implementation (unfortunately)
                <ul>
                    <li> ABNB = 13117 </li>
                    <li> Cap = 12907 </li>
                    <li> P&L = derived from agreement type, one of [48550, 50110, 52300, 52500] </li>
                </ul>

                <h4> Assumptions </h4>
                * Debit and credit based on assumption that the "true-up" is positive, i.e. Amazon earns more money. <br/>
                + Debit and credit for CSI reversals. For CRI reversals, they would be opposite.

                <h4> Common Terms </h4>
                <ul>
                    <li> New Accrual:  Either a new transaction matches an existing agreement, or a newly created agreement matches older DSI </li>
                    <li> Updated Accrual:  Update to previously earned funding, either due to agreement terms change, or transaction attributes change </li>
                    <li> Agreement Disposal: Agreement terms are voided, so all previously earned funding needs to be reversed </li>
                    <li> Bucket: A representation of logical entity where inventory could be, e.g. WH bucket represents a warehouse (FC) location, uniquely identified by the warehouse_id  </li>
                    <li> Dangling DRI: A vendor return (DRI) that accrues for an agreement, and whose original vendor receipt happened outside the agreement period of said agreement </li>
                    <li> Accrual event: An "earning" event for Amazon, that would eventually be collected from vendor after invoicing </li>
                    <li> Tracking event: A balance sheet transfer event, that does earn any funding, but moves already earned funding from one account to another </li>
                </ul>

                <h4> Transaction Types </h4>
                <ul>
                    <li> DSI: Distributor Shipment Item, a vendor receipt </li>
                    <li> PPDSI: Price Protection DSI, follows a DSI for which there was a cost drop after initial receipt </li>
                    <li> CVDSI: Cost Variance DSI, follows a DSI for which vendor charges Amazon different amount after initial receipt, which changes the PCOGS </li>
                    <li> DRI: Distributor Return Item, return to vendor </li>
                    <li> CSI: Customer Shipment Item, a sale to customer </li>
                    <li> CRI: Customer Return Item, a return from customer </li>
                    <li> DDI: Digital Download Item, a digital item sale to customer </li>
                    <li> DDRI: Digital Download Return Item, a return of digital item from customer </li>
                    <li> VPCC: Vendor Powered Coupon Clips, coupons clipped by customer on retail website </li>
                </ul>

            </TextContent>
        </Container>
    );
}

export default function FlareBusinessActivitiesEnumeration () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());
    const [accrualBasis, setAccrualBasis] = React.useState(nullAccrualBasis);
    const [tableItems, setTableItems] = React.useState([] as BusinessActivity[]);
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        tableItems,
        {
            filtering: {
                empty: <TableEmptyState dataType="Business Activity" />,
                noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
            },
            pagination: { pageSize: DDB_PAGE_SIZE },
            sorting: { defaultState: { sortingColumn: TableColumnDefinitions[0] } },
            selection: {},
        }
    );

    // TODO - add info that some agreement types share business activities.
    // Like PP agreement would also have tracking events once inventory moves, SP agreement has CSI raw results etc.

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
                <Container header={
                    <ColumnLayout columns={2} variant='text-grid' borders='vertical'>
                        <Header
                            variant='h3'
                            description={<div>
                                Business Activities differ by how agreements accrue <br/>
                                Note that the use case numbers are same as those used in the JEM template v2 (2022 onwards)
                            </div>}
                        >
                            Agreement Accrual Basis Type
                        </Header>

                        <Link href={paths.getRelativePath(paths.FLARE_BIZ_ACTIVITIES_HOME)}>
                            Back to Business Activities Home
                        </Link>
                    </ColumnLayout>}
                >
                    <SegmentedControl
                        selectedId={accrualBasis?.id || null}
                        onChange={({detail}) => {
                            const basis: AccrualBasis | null = getAccrualBasisFromId(detail.selectedId);
                            setAccrualBasis(basis);
                            // @ts-ignore
                            setTableItems(null != basis ? basis.businessActivities : []);
                        }}
                        options={ACCRUAL_BASES.map((accrualBasis) => {
                            return {
                                text: accrualBasis.name,
                                id: accrualBasis.id
                            }
                        })}
                    />
                </Container>
            }
            {hasAccess && null != accrualBasis &&
                <SpaceBetween direction='vertical' size='xl'>
                    <Table
                        {...collectionProps}
                        columnDefinitions={TableColumnDefinitions}
                        items={items}
                        filter={<TextFilter
                            {...filterProps}
                            filteringPlaceholder="Find business activities"
                            countText={getFilterCounterText(filteredItemsCount)}
                        />}
                        pagination={<Pagination {...paginationProps} />}
                        footer={Math.ceil(tableItems.length/DDB_PAGE_SIZE) &&
                            <Box textAlign="center">
                                 Page {paginationProps.currentPageIndex} of {Math.ceil(tableItems.length/DDB_PAGE_SIZE)}
                            </Box>
                        }
                        stickyHeader
                    />
                    <BusinessActivitiesFooter/>
                </SpaceBetween>
            }
        </SpaceBetween>
    );
}