import {
    ACCRUAL_BASES,
    AccrualBasis,
    BusinessActivity, DISTRIBUTOR_SHIPMENT,
    getAccrualBasisFromId,
    getBusinessActivityByName
} from '../../flare_usl_bi/business_activities/BusinessActivity';
import {OptionDefinition} from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import React from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import * as paths from '../../../util/paths';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Select from '@amzn/awsui-components-react/polaris/select';
import {TableProps} from '@amzn/awsui-components-react/polaris/table/interfaces';
import {ALL_MARKETPLACES, getMarketplaceByCode, Marketplace, MARKETPLACE_DE} from '../repo/BusinessCountryCode';


export const nullBusinessCountry: Marketplace | null = null;
export const nullAccrualBasis: AccrualBasis | null = null;
export const nullBusinessActivity: BusinessActivity | null = null;

export const toDropdownOptionBizActivity = (bizActivity: BusinessActivity) => {
    return {
        label: bizActivity.name,
        labelTag: bizActivity.serialNumber.toString(),
    } as OptionDefinition;
}

export const toDropdownOptionMkt = (mkt: Marketplace) => {
    return {
        label: mkt.code,
        labelTag: mkt.serialNumber.toString(),
    } as OptionDefinition;
}

export const fromDropdownLabelBizActivity = (opt: OptionDefinition) => {
    const possibleVal = getBusinessActivityByName(opt.label);
    if (undefined == possibleVal) {
        return null;
    }
    return possibleVal;
}

export const fromDropdownLabelMkt = (opt: OptionDefinition) => {
    const possibleVal = getMarketplaceByCode(opt.label ?? "");
    if (undefined == possibleVal) {
        return null;
    }
    return possibleVal;
}

export function AlertPilot() {
    const [toolAlertVisible, setToolAlertVisible] = React.useState(true);

    return (
        <Alert
            onDismiss={() => setToolAlertVisible(false)}
            visible={toolAlertVisible}
            dismissAriaLabel="Close alert"
            header="Note"
            dismissible={true}
            type='warning'
        >
            This is only a proof-of-concept for simplification of JEM mapping configuration outside
            of excel files.
        </Alert>
    );
}

export function JEMBusinessCountryCode(props) {
    return (
        <Container header={
            <ColumnLayout  columns={2} variant='text-grid' borders='vertical'>
                <Header
                    variant='h3'
                    description={"Enumeration of business_country_code, which means the same as Agreement Marketplace Country Code"}
                >
                    Business Country Code
                </Header>
                <Link href={paths.getRelativePath(paths.JEM_V2)}>
                    Back to JEM Home
                </Link>
            </ColumnLayout>}
        >
            <Select
                options={ALL_MARKETPLACES.map(item => toDropdownOptionMkt(item))}
                selectedOption={props.businessCountry == null ? null : toDropdownOptionMkt(props.businessCountry)}
                onChange={event => {
                    props.setBusinessCountry(fromDropdownLabelMkt(event.detail.selectedOption));
                }}
            />
        </Container>
    );
}

export function JEMAccrualBasis(props) {
    return (
        <Container>
            <FormField
                label={"Agreement Accrual Basis Type"}
                description={"Business Activities differ by how agreements accrue"}
            >
                <SegmentedControl
                    selectedId={props.accrualBasis?.id || null}
                    onChange={({detail}) => {
                        const basis: AccrualBasis | null = getAccrualBasisFromId(detail.selectedId);
                        props.setAccrualBasis(basis);
                        props.setBusinessActivity(nullBusinessActivity);

                        // @ts-ignore
                        props.setDropdownItems(null != basis ? basis.businessActivities : []);
                    }}
                    options={ACCRUAL_BASES.map((accrualBasis) => {
                        return {
                            text: accrualBasis.name,
                            id: accrualBasis.id
                        }
                    })}
                />
            </FormField>
        </Container>
    );
}

export function JEMBusinessActivityDropdown(props) {
    return (
        <Container>
            <FormField
                label={"Business Activity"}
                description={"List of Business Activities currently supported"}
            >
                <Select
                    options={props.dropdownItems.map(item => toDropdownOptionBizActivity(item))}
                    selectedOption={props.businessActivity == null ? null : toDropdownOptionBizActivity(props.businessActivity)}
                    onChange={event => {
                        props.setBusinessActivity(fromDropdownLabelBizActivity(event.detail.selectedOption));
                    }}
                />
            </FormField>
        </Container>
    );
}

export interface SevenSegmentMapping {
    serialNumber: number,
    financialComponent: string,
    accountNumber: string,
    channel: string,
    companyCode: string,
    costCenter: string,
    location: string,
    productLine: string,
    project: string,
    debitOrCredit: string,
    functionalCurrency: string,
    enteredCurrency: string,
}

export interface JournalEntryMappingUseCase {
    useCaseName: string,
    lineEntries: SevenSegmentMapping[],
}

export const TableColumnDefinitions: TableProps.ColumnDefinition<SevenSegmentMapping>[] = [
    {
        id: "serial_number",
        header: "S.No. ",
        cell: (item: SevenSegmentMapping) => item.serialNumber,
        sortingField: "serial_number",
    },
    {
        id: "fin_comp",
        header: "Financial Component",
        cell: (item: SevenSegmentMapping) => item.financialComponent,
    },
    {
        id: "account_number",
        header: "Account Number",
        cell: (item: SevenSegmentMapping) => item.accountNumber,
    },
    {
        id: "channel",
        header: "Channel",
        cell: (item: SevenSegmentMapping) => item.channel,
    },
    {
        id: "companyCode",
        header: "Company Code",
        cell: (item: SevenSegmentMapping) => item.companyCode,
    },
    {
        id: "costCenter",
        header: "Cost Center",
        cell: (item: SevenSegmentMapping) => item.costCenter,
    },
    {
        id: "location",
        header: "Location",
        cell: (item: SevenSegmentMapping) => item.location,
    },
    {
        id: "productLine",
        header: "Product Line",
        cell: (item: SevenSegmentMapping) => item.productLine,
    },
    {
        id: "project",
        header: "Project",
        cell: (item: SevenSegmentMapping) => item.project,
    },
    {
        id: "debitOrCredit",
        header: "Debit/Credit",
        cell: (item: SevenSegmentMapping) => item.debitOrCredit,
    },
    {
        id: "functionalCurrency",
        header: "Functional Currency",
        cell: (item: SevenSegmentMapping) => item.functionalCurrency,
    },
    {
        id: "enteredCurrency",
        header: "Entered Currency",
        cell: (item: SevenSegmentMapping) => item.enteredCurrency,
    },
];

export const ATTRIBUTES_IN_JEM: string[] = [
    "Financial Component",
    "Account Number",
    "Channel",
    "Company Code",
    "Cost Center",
    "Location",
    "Product Line",
    "Project",
    "Debit or Credit",
    "Functional Currency",
    "Entered Currency",
];



/////////////////////

// Test Data

const sevenSegment_capEntry: SevenSegmentMapping = {
    serialNumber: 1,
    financialComponent: "vendor_funding_capitalization",
    accountNumber: "12907",
    channel: "LKP[agreement_marketplace_id]",
    companyCode: "LKP[original_warehouse_id]",
    costCenter: "1200",
    location: "LKP[original_warehouse_id]",
    productLine: "LKP[source_gl_product_group_id]",
    project: "0000",
    debitOrCredit: "credit",
    functionalCurrency: "LKP[original_warehouse_id]",
    enteredCurrency: "LKP[business_country_code]",
};

const sevenSegment_abnbEntry: SevenSegmentMapping = {
    serialNumber: 2,
    financialComponent: "vendor_funding_accrual",
    accountNumber: "13117",
    channel: "LKP[agreement_marketplace_id]",
    companyCode: "LKP[business_country_code]",
    costCenter: "1200",
    location: "LKP[original_warehouse_id]",
    productLine: "LKP[source_gl_product_group_id]",
    project: "0000",
    debitOrCredit: "debit",
    functionalCurrency: "LKP[business_country_code]",
    enteredCurrency: "LKP[business_country_code]",
};

const sevenSegment_ic_1: SevenSegmentMapping = {
    serialNumber: 3,
    financialComponent: "intercompany_from",
    accountNumber: "138-LKP[original_warehouse_id]",
    channel: "0000",
    companyCode: "LKP[business_country_code]",
    costCenter: "0000",
    location: "0000",
    productLine: "0000",
    project: "0000",
    debitOrCredit: "credit",
    functionalCurrency: "LKP[business_country_code]",
    enteredCurrency: "LKP[business_country_code]",
};

const sevenSegment_ic_2: SevenSegmentMapping = {
    serialNumber: 4,
    financialComponent: "intercompany_to",
    accountNumber: "138-LKP[business_country_code]",
    channel: "0000",
    companyCode: "LKP[original_warehouse_id]",
    costCenter: "0000",
    location: "0000",
    productLine: "0000",
    project: "0000",
    debitOrCredit: "debit",
    functionalCurrency: "LKP[original_warehouse_id]",
    enteredCurrency: "LKP[business_country_code]",
};


// Mocked method for testing only
export const getAllSavedUseCases = (bizCountry: Marketplace, bizActivity: BusinessActivity) => {
    if (MARKETPLACE_DE == bizCountry && DISTRIBUTOR_SHIPMENT == bizActivity) {
        const sample1: JournalEntryMappingUseCase = {
            useCaseName: "Receipt in DE warehouse",
            lineEntries: [sevenSegment_capEntry, sevenSegment_abnbEntry],
        };
        const sample2: JournalEntryMappingUseCase = {
            useCaseName: "Receipt in UK warehouse",
            lineEntries: [sevenSegment_capEntry, sevenSegment_abnbEntry, sevenSegment_ic_1, sevenSegment_ic_2],
        };
        return [sample1, sample2];
    }
    return [];
}

export const getSavedUseCaseByName = (bizCountry: Marketplace, bizActivity: BusinessActivity, useCaseName: string | null) => {
    const all = getAllSavedUseCases(bizCountry, bizActivity);

    let possibleMatch: JournalEntryMappingUseCase | null = null;

    if (null != useCaseName) {
        all.forEach(useCase => {
            if (useCaseName.localeCompare(useCase.useCaseName) == 0) {
                possibleMatch = useCase;
                return;
            }
        })
    }

    return possibleMatch;
}