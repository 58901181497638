// @ts-nocheck
import {AgreementAttributes} from '../agreement/AgreementAttributes';
import * as FundingItemState from './FundingItemState';
import {Transaction} from '../transaction/Transaction';
import * as Money from '../Money';
import {TransactionType} from '../transaction/TransactionType';
import * as AccrualBasis from '../agreement/AccrualBasis';
import {CalcTerms, CalcType} from '../agreement/CalcTerms';
import * as Utils from '../../../../util/Utils';

// New funding earned

export const newAgreementCreated: (agr: AgreementAttributes, transactions: Transaction[], existingState: FundingItemState.FundingItemState) =>
    void = (agr: AgreementAttributes, transactions: Transaction[], existingState: FundingItemState.FundingItemState) => {

    if (CalcType.LUMP_SUM != agr.calcTerm?.calcType) {
        transactions.forEach(txn => {
            if (txn.isValid()) {
                computeNewFunding(agr, txn, existingState);
            }
        });
    }
    else {
        computeForLumpsumAgreement(agr, existingState);
    }
}

export const newTransactionCreated: (agreements: AgreementAttributes[], txn: Transaction, existingState: FundingItemState.FundingItemState) =>
    void = (agreements: AgreementAttributes[], txn: Transaction, existingState: FundingItemState.FundingItemState) => {

        agreements.forEach(agr => {
            if (CalcType.LUMP_SUM != agr.calcTerm?.calcType && !agr.isDisposed) {
                computeNewFunding(agr, txn, existingState);
            }
        });
}

const computeNewFunding = (agr: AgreementAttributes, txn: Transaction, existingState: FundingItemState.FundingItemState) => {
    const listFie: FundingItemState.FundingItemEvent[] = existingState.fieList;
    const listFis: FundingItemState.FundingItemSummary[] = existingState.summaryList;
    let latestSerialNumber: number = Math.max(0, Math.max.apply(Math, existingState.fieList.map(f => f.serialNumber)));

    const fie: FundingItemState.FundingItemEvent = createNewFundingItemEvent(agr, txn, ++latestSerialNumber);
    listFie.push(fie);

    const fis: FundingItemState.FundingItemSummary = createNewFundingItemSummaryRecord(fie);
    listFis.push(fis);

    existingState.fieList = listFie;
    existingState.summaryList = listFis;
}

const createNewFundingItemEvent: (agr: AgreementAttributes, txn: Transaction, serialNumber: number) =>
    FundingItemState.FundingItemEvent = (agr: AgreementAttributes, txn: Transaction, serialNumber: number) => {

    if (null == txn.getTransactionIdentifier() || null == txn.getQuantity() || null == txn.getPCogs() || null == txn.getAsinMarketplaceId() || null == txn.getProductGroupId()
        || null == agr.agreementId || null == agr.owningProductGroupId || null == agr.owningMarketplaceId || null == agr.calcTerm
        || null == agr.accrualBasis || null == agr.fundingType) {
        throw new Error("Bad data!");
    }

    const reason = computeFundingMovementReasonForNewFunding(txn.getTransactionIdentifier()?.type, agr.accrualBasis);
    const amount = computeFundingMovedForNewFunding(txn.getPCogs(), txn.getQuantity(), agr.calcTerm, agr.currency);
    const buckets = computeBucketsForNewFunding(reason, txn, agr);

    return {
        serialNumber: serialNumber,
        agreementId: agr.agreementId,
        transactionId: FundingItemState.serializeTransactionId(txn.getTransactionIdentifier()),
        sourceBucket: FundingItemState.serializeBucketWithType(buckets[0]),
        destinationBucket: FundingItemState.serializeBucketWithType(buckets[1]),
        amountMoved: Money.serialize(amount),
        quantityMoved: txn.getQuantity(),
        reason: reason

    } as FundingItemState.FundingItemEvent;
}

const createNewFundingItemSummaryRecord: (fie: FundingItemState.FundingItemEvent) => FundingItemState.FundingItemSummary = (fie: FundingItemState.FundingItemEvent) => {

    const totalFunding: Money.Money = Money.deserialize(fie.amountMoved);
    const perUnitFunding: Money.Money = Money.divide(totalFunding, fie.quantityMoved);

    const bucketMap = new Map<string, number>([]);
    conditionallyAdd(bucketMap, fie.sourceBucket, fie.quantityMoved);
    conditionallyAdd(bucketMap, fie.destinationBucket, fie.quantityMoved);

    return {
        agreementId: fie.agreementId,
        transactionId: fie.transactionId,
        bucketMap: Utils.convertBucketMapToObject(bucketMap),
        perUnitFunding: Money.serialize(perUnitFunding),
    } as FundingItemState.FundingItemSummary;
}

const computeFundingMovedForNewFunding = (pcogs: Money.Money, quantity: number, calcTerm: CalcTerms, agmtCurrency: string) => {
    switch (calcTerm.calcType) {
        case CalcType.PERCENTAGE_CHARGE:
            return Money.multiply(pcogs, (calcTerm.calcRate * 0.01))
        case CalcType.PER_UNIT_CHARGE:
            return {
                currency: pcogs.currency,
                amount: quantity * calcTerm.calcRate,
            } as Money.Money;
        case CalcType.LUMP_SUM:
            return {
                currency: agmtCurrency,
                amount: calcTerm.calcRate,
            } as Money.Money;
    }

    throw new Error("Not Implemented!");

}

const computeFundingMovementReasonForNewFunding = (txnType: TransactionType, accrualBasis: AccrualBasis.AccrualBasis) => {
    if (accrualBasis === AccrualBasis.SALES_BASED) {
        switch (txnType) {
            case TransactionType.CSI:
                return FundingItemState.FundingMovementReason.NEW_CSI_ACCRUAL_SALES_BASED;
            case TransactionType.CRI:
                return FundingItemState.FundingMovementReason.NEW_CRI_ACCRUAL_SALES_BASED;
        }
    }

    return new Error("Not implemented!");
}

const computeBucketsForNewFunding = (reason: FundingItemState.FundingMovementReason, txn: Transaction, agr: AgreementAttributes) => {
    const abnbBucket = FundingItemState.ABNB_BUCKET(agr.owningMarketplaceId, agr.owningProductGroupId);

    if (agr.accrualBasis === AccrualBasis.SALES_BASED) {
        const pnlBucket = FundingItemState.MKT_BUCKET(txn.getAsinMarketplaceId(), txn.getProductGroupId(), "");
        switch (reason) {
            case FundingItemState.FundingMovementReason.NEW_CSI_ACCRUAL_SALES_BASED:
                return [abnbBucket, pnlBucket];
            case FundingItemState.FundingMovementReason.NEW_CRI_ACCRUAL_SALES_BASED:
                return [pnlBucket, abnbBucket];
        }
    }
    else {
        return new Error("Not implemented!");
    }
}

const isAbnbDestinationBucket = (reason: FundingItemState.FundingMovementReason) => {
    switch (reason) {
        case FundingItemState.FundingMovementReason.UPDATED_CSI_ACCRUAL_SALES_BASED:
            return false;
        case FundingItemState.FundingMovementReason.UPDATED_CRI_ACCRUAL_SALES_BASED:
            return true;

        default:
            throw new Error("Not implemented!");
    }
}



// Updated funding
// TODO - this assumes that we only have sales based agreements, that do not have any tracking to do.

export const agreementUpdated: (newAgr: AgreementAttributes, transactions: Transaction[], existingState: FundingItemState.FundingItemState) =>
    void = (newAgr: AgreementAttributes, transactions: Transaction[], existingState: FundingItemState.FundingItemState) => {

    if (CalcType.LUMP_SUM != newAgr.calcTerm?.calcType) {
        transactions.forEach(txn => {
            if (txn.isValid()) {
                computeUpdatedEarnedFunding(newAgr, txn, existingState);
            }
        });
    }
    else {
        computeForLumpsumAgreement(agr, existingState);
    }
}

export const transactionUpdated: (agreements: AgreementAttributes[], newTxn: Transaction, existingState: FundingItemState.FundingItemState) =>
    void = (agreements: AgreementAttributes[], newTxn: Transaction, existingState: FundingItemState.FundingItemState) => {

    agreements.forEach(agr => {
        if (CalcType.LUMP_SUM != agr.calcTerm?.calcType && !agr.isDisposed) {
            computeUpdatedEarnedFunding(agr, newTxn, existingState);
        }
    });
}

const computeUpdatedEarnedFunding = (agr: AgreementAttributes, txn: Transaction, existingState: FundingItemState.FundingItemState) => {
    const listFie: FundingItemState.FundingItemEvent[] = existingState.fieList;
    const listFis: FundingItemState.FundingItemSummary[] = existingState.summaryList;
    let latestSerialNumber: number = Math.max(0, Math.max.apply(Math, existingState.fieList.map(f => f.serialNumber)));

    const agreementId: number = agr.agreementId;
    const txnId: string = FundingItemState.serializeTransactionId(txn.getTransactionIdentifier());

    const summaryIndex = listFis.findIndex(summary => (txnId == summary.transactionId && agreementId == summary.agreementId));
    const previousSummary: FundingItemState.FundingItemSummary = listFis[summaryIndex];

    const fundingBucketMap: Map<string, number> = Utils.convertObjectToBucketMap(previousSummary.bucketMap);
    let previousQuantity: number = 0;
    fundingBucketMap.forEach((qty, bucket) => previousQuantity += qty);

    const reason: FundingItemState.FundingMovementReason = calculateFundingMovementReasonForUpdatedFunding(txn.getTransactionIdentifier()?.type, agr.accrualBasis);

    if (agr.isDisposed || !txn.isValid()) {
        const abnbBucket = FundingItemState.serializeBucketWithType(FundingItemState.ABNB_BUCKET(agr.owningMarketplaceId, agr.owningProductGroupId));
        const isAbnbDestination: boolean = isAbnbDestinationBucket(reason);
        const newMap = new Map<string, number>();

        fundingBucketMap.forEach((qty, bucket) => {
            newMap.set(bucket, 0);

            const newFie = {
                serialNumber: ++latestSerialNumber,
                agreementId: agreementId,
                transactionId: txnId,
                sourceBucket: isAbnbDestination ? abnbBucket : bucket,
                destinationBucket: isAbnbDestination ? bucket : abnbBucket,
                amountMoved: Money.serialize(Money.multiply(Money.deserialize(previousSummary.perUnitFunding), qty)),
                quantityMoved: qty,
                reason: reason
            } as FundingItemState.FundingItemEvent;

            listFie.push(newFie);
        });

        previousSummary.perUnitFunding = Money.serialize(Money.multiply(Money.deserialize(previousSummary.perUnitFunding), 0));
        previousSummary.bucketMap = Utils.convertBucketMapToObject(newMap);
    }
    else {
        const latestQuantity: number = txn.getQuantity();
        const diffInQuantity = latestQuantity - previousQuantity;

        const newFie = createNewFundingItemEvent(agr, txn, -1);
        newFie.reason = reason;

        const latestSummary: FundingItemState.FundingItemSummary = createUpdatedEarningFundingItemSummaryRecord(newFie, previousSummary, listFie, agr, latestSerialNumber);
        listFis[summaryIndex] = latestSummary;

        latestSerialNumber = Math.max(0, Math.max.apply(Math, listFie.map(f => f.serialNumber)));

        const latestPerUnitFunding = Money.deserialize(latestSummary.perUnitFunding);
        const previousPerUnitFunding = Money.deserialize(previousSummary.perUnitFunding);
        const diffInPerUnitFunding: Money.Money = Money.diff(latestPerUnitFunding, previousPerUnitFunding);

        if (diffInQuantity != 0) {
            const updatedFie = FundingItemState.cloneFundingItemEvent(newFie);
            updatedFie.amountMoved = Money.serialize(Money.multiply(latestPerUnitFunding, diffInQuantity));
            updatedFie.quantityMoved = diffInQuantity;
            updatedFie.serialNumber = ++latestSerialNumber;

            listFie.push(updatedFie);
        }
        if (diffInPerUnitFunding.amount != 0.00) {
            const updatedFie = FundingItemState.cloneFundingItemEvent(newFie);
            updatedFie.amountMoved = Money.serialize(Money.multiply(diffInPerUnitFunding, previousQuantity));
            updatedFie.quantityMoved = previousQuantity;
            updatedFie.serialNumber = ++latestSerialNumber;

            listFie.push(updatedFie);
        }
    }

    existingState.fieList = listFie;
    existingState.summaryList = listFis;
}

const createUpdatedEarningFundingItemSummaryRecord = (fie: FundingItemState.FundingItemEvent, previousSummary: FundingItemState.FundingItemSummary,
                                                      list: FundingItemState.FundingItemEvent[], agr: AgreementAttributes, latestSerialNumber: number) => {

    const newSummary = createNewFundingItemSummaryRecord(fie);

    const bucketMapLatest = Utils.convertObjectToBucketMap(newSummary.bucketMap);
    const bucketMapPrevious = Utils.convertObjectToBucketMap(previousSummary.bucketMap);
    let previousQuantity: number = 0;
    bucketMapPrevious.forEach((qty, bucket) => previousQuantity += qty);

    const updatedFie = FundingItemState.cloneFundingItemEvent(fie);
    updatedFie.amountMoved = Money.serialize(Money.multiply(Money.deserialize(previousSummary.perUnitFunding), previousQuantity));
    updatedFie.quantityMoved = previousQuantity;

    const abnbBucket = FundingItemState.serializeBucketWithType(FundingItemState.ABNB_BUCKET(agr.owningMarketplaceId, agr.owningProductGroupId));

    const newMap = new Map<string, number>();

    // When bucket props change, we need to reverse everything from previous bucket and then move everything to current bucket.
    // This would require two FIEs: one to credit ABNB and then one to credit new bucket
    bucketMapPrevious.forEach((val, bucket) => {
        newMap.set(bucket, 0);

        if (!bucketMapLatest.get(bucket)) {
            const newFie = FundingItemState.cloneFundingItemEvent(updatedFie);
            newFie.serialNumber = ++latestSerialNumber;
            newFie.sourceBucket = bucket;
            newFie.destinationBucket = abnbBucket;
            list.push(newFie);
        }
    });
    bucketMapLatest.forEach((val, bucket) => {
        newMap.set(bucket, val);

        if (!bucketMapPrevious.get(bucket)) {
            const newFie = FundingItemState.cloneFundingItemEvent(updatedFie);
            newFie.serialNumber = ++latestSerialNumber;
            newFie.sourceBucket = abnbBucket;
            newFie.destinationBucket = bucket;
            list.push(newFie);
        }
    });

    newSummary.bucketMap = Utils.convertBucketMapToObject(newMap);
    return newSummary;
}

const calculateFundingMovementReasonForUpdatedFunding = (txnType: TransactionType, accrualBasis: AccrualBasis.AccrualBasis) => {
    if (accrualBasis === AccrualBasis.SALES_BASED) {
        switch (txnType) {
            case TransactionType.CSI:
                return FundingItemState.FundingMovementReason.UPDATED_CSI_ACCRUAL_SALES_BASED;
            case TransactionType.CRI:
                return FundingItemState.FundingMovementReason.UPDATED_CRI_ACCRUAL_SALES_BASED;
        }
    }
    return new Error("Not implemented!");
}


// Misc/common

const conditionallyAdd = (map: Map<string, number>, bucket: string, quantity: number) => {
    if (!bucket.includes("ABNB")) {
        map.set(bucket, quantity);
    }
}

const computeForLumpsumAgreement = (agr: AgreementAttributes, existingState: FundingItemState.FundingItemState) => {
    // FIXME
    // FIXME - conditionals in if conditions from where this is called from. This should be called from new Funding method
}
