import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Select from '@amzn/awsui-components-react/polaris/select';
import {isFromAccountingTeam, isFromTechTeam} from '../../../auth/UserPermissions';
import PermissionsModal from '../../PermissionsModal';
import {
    ALL_FLARE_ATTRIBUTE_CATEGORIES,
    FlareAttribute,
    FlareAttributeCategory,
    getFlareAttributeCategoryById,
    getFlareAttributesByCategory
} from './FlareAttribute';
import {useCollection} from '@amzn/awsui-collection-hooks';
import {
    DDB_PAGE_SIZE,
    getFilterCounterText,
    renderCellWithLink,
    TableEmptyState,
    TableNoMatchState
} from '../../../util/TableUtils';
import {TableProps} from '@amzn/awsui-components-react/polaris/table/interfaces';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Box from '@amzn/awsui-components-react/polaris/box';
import Table from '@amzn/awsui-components-react/polaris/table';
import * as paths from '../../../util/paths';
import Link from '@amzn/awsui-components-react/polaris/link';

const nullAttributeCategory: FlareAttributeCategory | null = null;

const TableColumnDefinitions: TableProps.ColumnDefinition<FlareAttribute>[] = [
    {
        id: "serial_number",
        header: "S.No.",
        cell: (item: FlareAttribute) => item.id,
        sortingField: "serial_number",
        sortingComparator: (a: FlareAttribute, b:FlareAttribute) => parseFloat(a.id) - parseFloat(b.id),
    },
    {
        id: "flare_name",
        header: "Flare Attribute Name",
        cell: (item: FlareAttribute) => item.flareName,
    },
    {
        id: "usl_bi_name",
        header: "GFS_USL_BASE_V Field",
        cell: (item: FlareAttribute) => item.uslBiName,
    },
    {
        id: "cct_meaning",
        header: "ContraCoGS Meaning",
        cell: (item: FlareAttribute) => renderCellWithLink(item.cctMeaning, item.docLink, "also see"),
    },
];

export default function FlareAttributesEnumeration () {
    const [hasAccess] = React.useState(isFromTechTeam() || isFromAccountingTeam());
    const [selectedCategory, setSelectedCategory] = React.useState(nullAttributeCategory);
    const [tableItems, setTableItems] = React.useState([] as FlareAttribute[]);
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        tableItems,
        {
            filtering: {
                empty: <TableEmptyState dataType="Flare Attribute" />,
                noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
            },
            pagination: { pageSize: DDB_PAGE_SIZE },
            sorting: { defaultState: { sortingColumn: TableColumnDefinitions[0] } },
            selection: {},
        }
    );

    const convertToOption = () => {
        if (null == selectedCategory) {
            return null;
        }
        else {
            return {
                label: selectedCategory.name,
                value: selectedCategory.id
            };
        }
    }

    return (
        <SpaceBetween direction='vertical' size='xl'>
            {!hasAccess && <PermissionsModal hasPageAccess={hasAccess}/>}
            {hasAccess &&
            <SpaceBetween direction='vertical' size='xl'>
                <Container
                    header={
                        <Header
                            variant='h3'
                            description={<div>
                                Business meanings of attributes passed from ContraCoGS system into Flare and USL
                            </div>}
                        >
                            Flare and USL BI Attributes
                        </Header>}
                    footer={<div>
                        Also see USL BI data lineage <Link href={paths.USL_BI_DATA_LINEAGE} target="_blank" external> here </Link>
                    </div>}
                >
                    <Select
                        selectedOption={convertToOption()}
                        onChange={({detail}) => {
                            const cat: FlareAttributeCategory | null = getFlareAttributeCategoryById(detail.selectedOption.value);
                            setSelectedCategory(cat);
                            // @ts-ignore
                            setTableItems(null != cat ? getFlareAttributesByCategory(cat.name) : []);
                        }}
                        options={ALL_FLARE_ATTRIBUTE_CATEGORIES.map((category) => {
                            return {
                                label: category.name,
                                value: category.id
                            }
                        })}
                        selectedAriaLabel="Selected"
                    />
                </Container>
                {null != selectedCategory &&
                    <Container>
                        <Table
                            {...collectionProps}
                            columnDefinitions={TableColumnDefinitions}
                            items={items}
                            filter={<TextFilter
                                {...filterProps}
                                filteringPlaceholder="Find Flare Attribute(s)"
                                countText={getFilterCounterText(filteredItemsCount)}
                            />}
                            pagination={<Pagination {...paginationProps} />}
                            footer={Math.ceil(tableItems.length/DDB_PAGE_SIZE) > 0 &&
                                <Box textAlign="center">
                                    Page {paginationProps.currentPageIndex} of {Math.ceil(tableItems.length/DDB_PAGE_SIZE)}
                                </Box>
                            }
                            stickyHeader
                            wrapLines
                        />
                    </Container>}
                {null != selectedCategory &&
                    <Container>
                        Note - The attribute names for certain attributes in USL BI are not known at this time. As these
                        values are known to CCT, this page would be updated. If you are aware of the values, let us know
                        to update this page.
                    </Container>
                }
            </SpaceBetween>
            }
        </SpaceBetween>
    );
}