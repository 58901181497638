import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {FundingItemEvent} from './model/fundingItem/FundingItemState';

const pageSize: number = 10;

export default function FundingItemEventTable(props) {
    const [pageNumber, setPageNumber] = React.useState(1);

    return (
        <Table
            columnDefinitions={[
                {
                    id: "serial",
                    header: "S.No.",
                    cell: (item: FundingItemEvent) => item.serialNumber,
                },
                {
                    id: "agreementId",
                    header: "Agr Id",
                    cell: (item: FundingItemEvent) => item.agreementId,
                },
                {
                    id: "transactionId",
                    header: "Txn Td",
                    cell: (item: FundingItemEvent) => item.transactionId,
                },
                {
                    id: "sourceBucket",
                    header: "Src Bucket",
                    cell: (item: FundingItemEvent) => item.sourceBucket,
                },
                {
                    id: "destinationBucket",
                    header: "Dest Bucket",
                    cell: (item: FundingItemEvent) => item.destinationBucket,
                },
                {
                    id: "fundingMoved",
                    header: "Amt Moved",
                    cell: (item: FundingItemEvent) => item.amountMoved,
                },
                {
                    id: "quantityMoved",
                    header: "Qty Moved",
                    cell: (item: FundingItemEvent) => item.quantityMoved,
                },
                {
                    id: "reason",
                    header: "Reason",
                    cell: (item: FundingItemEvent) => item.reason,
                },
            ]}
            items={props.fieList.slice((pageNumber-1)*pageSize, Math.min(props.fieList.length, pageNumber*pageSize))}
            loadingText="Loading FIEs"
            resizableColumns
            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No Funding Item Events to display
                    </Box>
                </Box>
            }
            pagination={
                <Pagination
                    currentPageIndex={pageNumber}
                    pagesCount={Math.max(1, Math.ceil(props.fieList.length/pageSize))}
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                    onChange={event => setPageNumber(event.detail.currentPageIndex)}
                />
            }
            header={
                <Header counter={`(${props.fieList.length})`}>Funding Item Events</Header>
            }
        />
    );
}
