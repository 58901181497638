import {TransactionIdentifier} from '../transaction/TransactionIdentifier';

export enum FundingBucketType {
    ABNB = "ABNB",
    CAP = "CAP",
    CAP_INTRANSIT = "CAP_INTRANSIT",
    MKT = "MKT",
}

export interface FundingBucket {
    type: FundingBucketType,
    attrs: string[]
}

export const ABNB_BUCKET = (agmtOwningMkt: number, agmtOwningPg: number) => {
    return {
        type: FundingBucketType.ABNB,
        attrs: [agmtOwningMkt.toString(), agmtOwningPg.toString()]
    } as FundingBucket;
}

export const MKT_BUCKET = (saleMkt: number, salePg: number, saleMerchant: string) => {
    return {
        type: FundingBucketType.MKT,
        attrs: [saleMkt.toString(), salePg.toString(), saleMerchant]
    } as FundingBucket;
}


export interface FundingItemState {
    fieList: FundingItemEvent[],
    summaryList: FundingItemSummary[],
}

export const serializeBucketWithType: (fundingBucket: FundingBucket) => string = (fundingBucket: FundingBucket) => {
    const attrs: string[] = fundingBucket.attrs;
    switch (fundingBucket.type) {
        case FundingBucketType.ABNB:
            return `ABNB-[MKT=(${attrs[0]})+PG=(${attrs[1]})]`;
        case FundingBucketType.MKT:
            return `P&L-[MKT=(${attrs[0]})+PG=(${attrs[1]})+MERCHANT=(${attrs[2]})]`;
        default:
            throw new Error("Not Implemented");
    }
}

export const serializeTransactionId = (txnId: TransactionIdentifier) => {
    return txnId.type + "+" + txnId.id;
}

export const empty: FundingItemState = {
    fieList: [],
    summaryList: [],
}

export interface FundingItemEvent {
    serialNumber: number,
    agreementId: number,
    transactionId: string,
    sourceBucket: string,
    destinationBucket: string,
    amountMoved: string,
    quantityMoved: number,
    reason: FundingMovementReason
}

export const cloneFundingItemEvent = (fie: FundingItemEvent) => {
    return {
        serialNumber: fie.serialNumber,
        agreementId: fie.agreementId,
        transactionId: fie.transactionId,
        sourceBucket: fie.sourceBucket,
        destinationBucket: fie.destinationBucket,
        amountMoved: fie.amountMoved,
        quantityMoved: fie.quantityMoved,
        reason: fie.reason
    } as FundingItemEvent;
}


export enum FundingMovementReason {
    NEW_CSI_ACCRUAL_SALES_BASED= "NEW_CSI_ACCRUAL_SALES_BASED",
    UPDATED_CSI_ACCRUAL_SALES_BASED= "UPDATED_CSI_ACCRUAL_SALES_BASED",
    NEW_CRI_ACCRUAL_SALES_BASED= "NEW_CRI_ACCRUAL_SALES_BASED",
    UPDATED_CRI_ACCRUAL_SALES_BASED= "UPDATED_CRI_ACCRUAL_SALES_BASED",
}

export interface FundingItemSummary {
    agreementId: number,
    transactionId: string,
    bucketMap: object, // serialized Funding bucket to quantity
    perUnitFunding: string,
}