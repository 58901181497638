import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Link from '@amzn/awsui-components-react/polaris/link';


export const DDB_PAGE_SIZE: number = 10;

export const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;

export const TableNoMatchState = (props) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    No Match Found
                </Box>
            </div>
            <Button onClick={props.onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);


export const TableEmptyState = (props) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No {props.dataType} </b>
                <Box variant="p" color="inherit">
                    COMING SOON!
                </Box>
            </div>
        </SpaceBetween>
    </Box>
);

export const renderCellWithLink = (stringVal: string, url: string | undefined, urlText: string) => {
    if (undefined != url) {
        return (
            <div>
                {stringVal}  (<Link href={url}>{urlText}</Link>)
            </div>
        );
    }
    else {
        return (
            <div>
                {stringVal}
            </div>
        );
    }
}