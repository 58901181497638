import React from 'react';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import {Container} from '@amzn/awsui-components-react';
import {LogAttributes} from './model/audit/LogAttributes';

export default function AuditLog(props) {

    const printLog = (logs: LogAttributes[]) => {
        let finalString: string = "";
        logs.forEach(log => {
            const str: string =  JSON.stringify(log, null, 2);
            finalString += str.replace(/[\\"]+/g, '');
            finalString += "\n------------------------------------------\n"
        })
        return finalString;
    }

    return (
        <Container>
            <Textarea
                value={printLog(props.log)}
                placeholder="Shows the 'state' for any funding earned, after having processed all events"
                readOnly={true}
                rows={16}
            />
        </Container>

    );
}